import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MaintenanceService } from "../../../core/services/mant.service";
import { get as _get } from "lodash";
import { Utilities } from '../../../../app/shared/utilities/utilities';
import { AuthenticationService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-lm-ban-professional',
  templateUrl: './lm-ban-professional.component.html',
  styleUrls: ['./lm-ban-professional.component.scss']
})
export class LmBanProfessionalComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  form: FormGroup;
  message: string;
  isError: boolean = false;
  isDisabled: boolean = false;
  submitted: boolean = false
  isUserLeroy: boolean;
  _messages: { [key: string]: string };
  constructor(private _mant: MaintenanceService,
    private _router: Router,
    private ruta: ActivatedRoute,
    private _translateService: TranslateService,
    private _auth: AuthenticationService) {
    this._translateSubscription = this._translateService.get('lm-ban-professional.messages.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
  }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.getEmailProfessional();
    this.isUserLeroy = Utilities.isUserHogami();

    this._auth.isNewTokenExpired();
  }

  get f() { return this.form.controls; }

  async getEmailProfessional() {
    const email = await this.ruta.params['value'];
    this.form.controls['email'].setValue(email.email);
  }

  banProfesional(data) {
    this.submitted = true
    if (this.form.invalid) {
      return;
    }
    this.isDisabled = true;
    this.message = "";
    this._mant.banProfesionalV2(data.email)
      .subscribe(
        () => {
          this._mant.banProfesional(data.email)
            .subscribe(
              () => {
                this.message = this._messages['professional_successfully_banned'];
                this.isError = false;
                this.isDisabled = false;
              },
              (error) => {
                this.isDisabled = false;
                this.isError = true;
                if ((error.error.code === "SIDS-404-1") || (error.error.code === "SPRO-404-2")) {
                  this.message = this._messages['professional_not_found'];
                }
                else {
                  this.message = this._messages['error_try_again'];
                }
              }
            );
          this.message = this._messages['professional_successfully_banned'];
          this.isError = false;
          this.isDisabled = false;
        },
        (error) => {
          this.isDisabled = false;
          this.isError = true;
          if (error.error.code === "SPRO-400-20") {
            this.message = this._messages['professional_already_banned'];
          }
          else {
            this.message = this._messages['error_try_again'];
          }
        }
      );
  }

  goBack() {
    this._router.navigate(["transfers/professionals"]);
  }

}
