<div class="menu">
  <button mat-raised-button *ngIf="transferType != 'op'" [disabled]="loading" (click)="resetFilters(0)">Ver operaciones</button>
  <button mat-raised-button *ngIf="transferType != 're'" [disabled]="loading" (click)="resetFilters(1)">  Ver solicitudes </button>
  <button mat-raised-button [disabled]="loading" (click)="getPaycometOperations()"> Ver Pagos Paycomet </button>
  <button mat-raised-button [disabled]="loading" (click)="getBilling()">  Operaciones a Facturar </button>
  <button mat-raised-button [disabled]="loading" (click)="getProfessionals()">  Profesionales  </button>
  <button mat-raised-button [disabled]="loading" (click)="getToServies()" *ngIf="isUserLeroy"> Servicios </button>
  <button mat-raised-button *ngIf="datag !== undefined && transferType != 're' && !loading" [disabled]="loadExcel" (click)="exportToExcel()" style="float: right;"><mat-icon>file_open</mat-icon> Exportar a Excel</button>
  <button mat-raised-button *ngIf="dataSolic !== undefined && transferType != 'op' && !loading" [disabled]="loadExcel" (click)="exportToExcel()" style="float: right;"><mat-icon>file_open</mat-icon> Exportar a Excel</button>
</div>

<div class="content" *ngIf="transferType == 're' || transferType == 'op'">
<mat-expansion-panel  [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filtros de busqueda
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form action="" [formGroup]="form">
    <div class="filters_form_group">
    <label style="cursor: default;">Fecha de creación: </label>
    <div class="filter-dates">
      <mat-form-field>
        <input matInput [matDatepicker]="picker1" placeholder="Fecha Desde:" formControlName="dateSinceStarttime" name="dateSinceStarttime">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput [matDatepicker]="picker2" placeholder="Fecha Hasta:" formControlName="dateUntilStarttime" name="dateUntilStarttime">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <label style="cursor: default;">Fecha de finalización: </label>
    <div class="filter-dates">
      <mat-form-field>
        <input matInput [matDatepicker]="picker3" placeholder="Fecha Desde:" formControlName="dateSinceEndtime" name="dateSinceEndtime">
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput [matDatepicker]="picker4" placeholder="Fecha Hasta:" formControlName="dateUntilEndtime" name="dateUntilEndtime">
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>

    <ng-container *ngIf="showOperations">
      <label style="cursor: default;">Fecha de la Cita:</label>
      <div class="filter-dates">
        <mat-form-field>
          <input matInput [matDatepicker]="picker3" placeholder="Fecha Desde:" formControlName="operationDateStartTime" name="operationDateStartTime">
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="picker4" placeholder="Fecha Hasta:" formControlName="operationDateEndTime" name="operationDateEndTime">
          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
          <mat-datepicker #picker4></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-container>
    </div>
    <div class="filters_form_group">
    <div>
      <mat-form-field>
        <input matInput placeholder="Id {{typeId}}" type="text" formControlName="idType" name="idType">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field >
        <input matInput placeholder="Email del Profesional" type="email" formControlName="profEmail" name="profEmail">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field >
        <input matInput placeholder="Email del Cliente" type="email" formControlName="clientEmail" name="clientEmail">
      </mat-form-field>
    </div>
    </div>
    <div class="filters_form_group">
    <div>
      <mat-form-field>
        <mat-label>Servicio</mat-label>
        <mat-select formControlName="serviceSelected" name="serviceSelected">
          <mat-option>TODOS</mat-option>
          <mat-option *ngFor="let service of services" [value]="service.id">{{service.description}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="">
      <mat-form-field>
        <mat-label>Provincia</mat-label>
        <mat-select formControlName="provinceSelected" name="provinceSelected">
          <mat-option>TODAS</mat-option>
          <mat-option *ngFor="let province of provinces" [value]="province.zipCode">{{province.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="">
      <mat-form-field>
        <mat-label>Estado</mat-label>
        <mat-select formControlName="stateSelected" name="stateSelected">
          <mat-option>TODOS</mat-option>
          <mat-option *ngFor="let status of statusFilters" [value]="status.id">{{status.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    </div>
  </form>
  <div class="button-row">
    <button  mat-raised-button  color="primary" type="submit" (click)="sendFilters()"><mat-icon>search</mat-icon> Buscar</button>
    <button  mat-raised-button  (click)="resetFilters(0)">Limpiar</button>
  </div>
</mat-expansion-panel>
</div>



  
