import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImagesDialogComponent } from 'src/app/pages/lm-transfers/sections/images-dialog/images-dialog.component';
import { OperationService } from 'src/app/pages/lm-transfers/services/operation.service';

@Component({
  selector: 'app-lm-journey-work',
  templateUrl: './lm-journey-work.component.html',
  styleUrls: ['./lm-journey-work.component.scss']
})
export class LmJourneyWorkComponent implements OnInit {
  @Input() requestId: any;
  data: any = {};
  chatData: any = {};
  services: any = [];
  questions: any = [];
  imagesBefore: any[];
  imagesAfter: any[];

  constructor(private _operationService: OperationService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getWorkData(this.requestId);
  }

  getWorkData(requestId: number) {
    this._operationService.getWorkData(this.requestId).then(res => {
      if (res.data !== null && res.data !== undefined) {
        this.data = res.data;
        if (res.data.services && res.data.services.length > 1) {
          this.services = res.data.services.slice(1);
        }else{
          this.services = res.data.requestOfferedServices;
        }
        if (res.data.questions) {
          const resQuestions = res.data.questions.response.replace('questions', '"questions"')
          this.questions = JSON.parse(resQuestions)
        }
        if (res.data.images) {
          this.imagesBefore = res.data.images.filter(image => image.type == "START");
          this.imagesAfter = res.data.images.filter(image => image.type == "END");

        }
        this.chatData = {
          chatId: this.data.chatId,
          professional: this.data.professionalEmail,
          client: this.data.clientEmail
        }
      }

    }).catch(err => {
      console.error(err);
    })
  }
  getLocalNumber(number, decimals: number = 2) {
    return new Intl.NumberFormat('es-ES', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    }).format(number)
  }
      openImagesDialog(url: string, type: string): void {
        if(url != null) {
            var dialogRef = this.dialog.open(ImagesDialogComponent, {
              width: '80%',
              height: '80%',
              maxHeight: '80%',
              data: {
                url: url,
                type : type
              }
            });

            dialogRef.afterClosed().subscribe(result => {
                dialogRef = null;
            });
        }
  }

}
