import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { get as _get } from 'lodash';
import { Campaigns, Stores, TransactionTyes } from '../../models/lm-transfers.model';
import { Utilities } from '../../../../shared/utilities/utilities';
import { TransferService } from '../../services/transfers.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lm-filters-paycomet',
  templateUrl: './lm-filters-paycomet.component.html',
  styleUrls: ['../../../utils/form-search.scss', "./lm-filters-paycomet.component.scss", '../../../utils/dropdown.scss']
})
export class LmFiltersPaycometComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  form: FormGroup;
  isUserLeroy: boolean;
  transactionTypes: TransactionTyes[];
  campaigns: Campaigns[] = [];
  stores: Stores[] = [];
  dropdownTransactionTypes = {};
  dropdownSettingCampaigns = {};
  dropdownSettingStores = {};

  @Input() loading: boolean = false
  @Input() loadExcel: boolean;
  @Output() filtersApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterstoExcel: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private _transferService: TransferService, private _translateService: TranslateService) {
      this._translationSubscription = this._translateService
      .get('lm-transfers.sections.lm-filters-paycomet.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
     }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.createForm();
    this.isUserLeroy = Utilities.isUserHogami();
    this.loadDropdownTransactions();
    this.loadCampaigns();
    this.loadStores();
    this.getTransactionTypes();
    this.loadDropdownCampaigns();
    this.loadDropdownStores();
  }

  createForm() {
    this.form = new FormGroup({
      startTransaction: new FormControl(''),
      endTransaction: new FormControl(''),
      operationId: new FormControl(''),
      transactionType: new FormControl(),
      campaignSelected: new FormControl(''),
      storeSelected: new FormControl(''),
      commercialId: new  FormControl('')
    });
  }

  resetFilters() {
    this.form.reset();
  }

  onChangeOperactionId( e ) {
		const key = e.key;
		if ( key < '0' || key > '9' ) {
			e.preventDefault();
		}
	}

  getTransactionTypes() {
    this._transferService.getTransactionTypes().subscribe(res => {
      this.transactionTypes = res['data'];
      this.transactionTypes.forEach(element => {
        if(element.codStr === 'AUTH') {
          element.translate = this._messages['authorization']
        }
        if(element.codStr === 'TRANS') {
          element.translate = this._messages['transfer']
        }
        if(element.codStr === 'SEPA_TRANS') {
          element.translate = this._messages['sepa_transfer']
        }
        if(element.codStr === 'REFUND') {
          element.translate = this._messages['return']
        }
        if(element.codStr === 'SEPA_TRANS_DENIAL') {
          element.translate = this._messages['sepa_transfer_refusal']
        }
        if(element.codStr === 'WITHDRAWAL') {
          element.translate = this._messages['withdrawal_of_funds']
        }
      })
    });
  }

  loadCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data;
      this.campaigns.push({
        id:0,
        descriptionCampaign: '',
        codeCampaign: 'WEB'
      });
      this.campaigns.sort((a,b) => a.id.toString().localeCompare(b.id.toString()));
    });
  }

  loadStores() {
    this._transferService.getAllStores().subscribe(res => {
      this.stores = res.data;
      this.stores.forEach(element => {
        let fullName = element.leroyId + ' - ' + element.name;
        element.fullName = fullName;
      });
      this.stores.sort((a,b) => a.leroyId - b.leroyId);
    });
  }

  sendFilters() {
    const infoFilters = {
      startTransaction: (this.form.controls['startTransaction'].value ==  null || this.form.controls['startTransaction'].value == "" )  ? null : Utilities.getDate(this.form.controls['startTransaction'].value) ,
      endTransaction: (this.form.controls['endTransaction'].value == null || this.form.controls['endTransaction'].value == "" ) ? null: Utilities.getDateEnd(this.form.controls['endTransaction'].value),
      operationId: this.form.controls['operationId'].value ? parseInt(this.form.controls['operationId'].value):0,
      listOperationName: ( this.form.controls[ 'transactionType' ].value == null || this.form.controls[ 'transactionType' ].value == "") ? [] : this.proccessServices(this.form.controls[ 'transactionType' ].value),

      campaigns: ( this.form.controls[ 'campaignSelected' ].value == null || this.form.controls[ 'campaignSelected' ].value == "") ? [] : this.proccessCampaigns(this.form.controls[ 'campaignSelected' ].value),
      stores: ( this.form.controls[ 'storeSelected' ].value == null || this.form.controls[ 'storeSelected' ].value == "") ? [] : this.proccessStores(this.form.controls[ 'storeSelected' ].value),
      commercialId: ( this.form.controls[ 'commercialId' ].value ==  null || this.form.controls[ 'commercialId' ].value == "") ? null : this.form.controls[ 'commercialId' ].value ,
    }
    this.filtersApply.emit(infoFilters);
  }

  exportToExcel() {
    const infoFilters = {
      startTransaction: (this.form.controls['startTransaction'].value ==  null || this.form.controls['startTransaction'].value == "" )  ? null : Utilities.getDate(this.form.controls['startTransaction'].value) ,
      endTransaction: (this.form.controls['endTransaction'].value == null || this.form.controls['endTransaction'].value == "" ) ? null: Utilities.getDateEnd(this.form.controls['endTransaction'].value),
      operationId: this.form.controls['operationId'].value ? parseInt(this.form.controls['operationId'].value):0,
      listOperationName: ( this.form.controls[ 'transactionType' ].value == null || this.form.controls[ 'transactionType' ].value == "") ? [] : this.proccessServices(this.form.controls[ 'transactionType' ].value),

      campaigns: ( this.form.controls[ 'campaignSelected' ].value == null || this.form.controls[ 'campaignSelected' ].value == "") ? [] : this.proccessCampaigns(this.form.controls[ 'campaignSelected' ].value),
      stores: ( this.form.controls[ 'storeSelected' ].value == null || this.form.controls[ 'storeSelected' ].value == "") ? [] : this.proccessStores(this.form.controls[ 'storeSelected' ].value),
      commercialId: ( this.form.controls[ 'commercialId' ].value ==  null || this.form.controls[ 'commercialId' ].value == "") ? null : this.form.controls[ 'commercialId' ].value ,
    }
    this.loadExcel = false;
    this.filterstoExcel.emit(infoFilters);
  }

  /**
   * This function processes the array of services to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessServices(arrayTransaction: any) {
    let finalService = [];
    arrayTransaction.forEach(element => {
      finalService.push(element.translate)
    });
    return finalService;
  }

  /**
   * This function processes the array of campaigns to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessCampaigns(arrayCampaign: any) {
    let finalCampaigns = [];
    arrayCampaign.forEach(element => {
      finalCampaigns.push(element.id);
    });
    return finalCampaigns;
  }

  /**
   * This function processes the array of stores to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessStores(arrayStores: any) {
    let finalStores = [];
    arrayStores.forEach(element => {
      finalStores.push(element.leroyId);
    });
    return finalStores;
  }

  /**
   * This function validate string in input phone
   * @param e 
   */
   onChangePhoneClent(e: any) {
    const key = e.key;
    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  }

  /**
   * This function loads the transactions types of paycomet.
   */
   loadDropdownTransactions() {
		this.dropdownTransactionTypes = {
			singleSelection: false,
			idField: 'name',
			textField: 'translate',
			selectAllText: this._messages['select_all'],
			unSelectAllText: this._messages['deselect_all'],
			itemsShowLimit: 3,
			allowSearchFilter: true
		};
	}

  loadDropdownCampaigns() {
    this.dropdownSettingCampaigns = {
			singleSelection: false,
			idField: 'id',
			textField: 'codeCampaign',
			selectAllText: this._messages['select_all'],
			unSelectAllText: this._messages['deselect_all'],
			itemsShowLimit: 3,
			allowSearchFilter: true
    }
  }

  loadDropdownStores() {
    this.dropdownSettingStores = {
			singleSelection: false,
			idField: 'leroyId',
			textField: 'fullName',
			selectAllText: this._messages['select_all'],
			unSelectAllText: this._messages['deselect_all'],
			itemsShowLimit: 3,
			allowSearchFilter: true
    }
  }

}
