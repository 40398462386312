import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IClient, IClientFilterData } from '../models/lm-clients.model';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ClientService } from '../services/client.service';
import { ELmSharedPaths } from 'src/app/shared/models/shared.models';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['../../utils/mat-table.scss', './clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clientFilterData: IClientFilterData = {
    nameAndLastNameClient: null,
    countryCode: null,
    phoneClient: null,
    email: null,
    documentNumber: null,
    clientTypeId: null,
    createDateStart: null,
    createDateEnd: null
  };
  clients: IClient[];
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;

  isUserLeroy: boolean;
  displayedColumns: string[] = ['id', 'create_date', 'clientDetails', 'document',
    'email', 'phone', 'estado', 'fill'];

  constructor(
    private _clientService: ClientService,
    private _router: Router,
    private _translateService: TranslateService) { }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserHogami();
  }

  getFiltersToClients(filtersApply) {
    this.clientFilterData = filtersApply;
    this.getClients();
  }

  /**
   * Retrieve client information
   */
  getClients() {
    const alertMessage = this._translateService.instant('lm-client.clients.alertMessage');
    this.loading = true;
    this.clients = []
    this.totalElements = 0;
    this._clientService.getClients(this.page, this.pageSize, this.clientFilterData)
      .subscribe(res => {
        if (res.data.totalElements === 0) {
          alert(alertMessage);
          this.loading = false;
          this.clientFilterData = {};
          this.clients = [];
        } else {
          this.loading = false;
          this.clients = res.data.content;
          this.totalElements = res.data.totalElements
        }
      }, error => {
        this.loading = false;
        this.clientFilterData = {};
        this.clients = [];
      });
  }

  /**
   * Format status client
   * @param is_active     - Status of the client
   */
  formatStatusClient(is_active: boolean): string {
    let status = 'INACTIVO';
    if (is_active) {
      status = 'ACTIVO';
    }
    return status;
  }

  /**
   * Format Date
   * @param d     - Date
   */
  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString()
    }
    return d
  }

  /**
   * Go to page number
   * @param event   - Page number
   */
  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getClients();
  }

  /**
   * Show client information
   * @param id   - ID of client
   */
  goToClientDetail(id) {
    this._router.navigate([`${ELmSharedPaths.CLIENTS}/${id}`]);
  }

}
