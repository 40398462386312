import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ProfessionalsComponent } from './professionals/professionals.component';
import { ProfessionalDetailsComponent } from './professional-details/professional-details.component';
import { LmProfessionalServiceCoverageDetailsComponent } from './sections/lm-professional-service-coverage-details/lm-professional-service-coverage-details.component';

const routes: Routes = [
  {
    path: "",
    component: ProfessionalsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: ":professionalId",
    component: ProfessionalDetailsComponent,
    canActivate: [ AuthGuard ]
  }
  ,
  {
    path: ":professionalId/service/:serviceId",
    component: LmProfessionalServiceCoverageDetailsComponent,
    canActivate: [ AuthGuard ]
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'professionals'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LmProfessionalsRoutingModule { }
