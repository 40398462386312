<div class="content" *ngIf="clientFilterForm">
  <mat-expansion-panel  [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'lm-client.lm-filters-client.title' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form action="" [formGroup]="clientFilterForm" (ngSubmit)="submitForm()">
      <div class="filters_form_group">
        <br>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client.placeholders.date_form' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="{{'lm-client.lm-filters-client.placeholders.date_form' | translate}}" formControlName="createDateStart"
              name="createDateStart">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker2" placeholder="{{'lm-client.lm-filters-client.placeholders.date_to' | translate}}" formControlName="createDateEnd"
              name="createDateEnd">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="filters_form_group">
        <br>
        <mat-form-field class="field-client-name">
          <mat-label>{{'lm-client.lm-filters-client.placeholders.nameAndLastNameClient' | translate}}</mat-label>
          <input matInput type="text" formControlName="nameAndLastNameClient" name="nameAndLastNameClient" placeholder="{{'lm-client.lm-filters-client.placeholders.nameAndLastNameClient' | translate}}">
        </mat-form-field>
        <div class="phone_group">
          <span *ngIf="countrySelected" class="flag-icon flag-icon-{{countrySelected.flag}}"></span>
          <mat-form-field class="phone_group--field-country">
            <mat-label>{{'lm-client.lm-filters-client.placeholders.country' | translate}}</mat-label>
            <input
            type="text"
            matInput
            formControlName="countryCode"
            [matAutocomplete]="auto"
            size="2"
            maxlength="5"
            name="countryCode"
            placeholder="{{'lm-client.lm-filters-client.placeholders.country' | translate}}"
            (click)="clickEvent()"
            (blur)="blurCountry($event)"
          >
          </mat-form-field>
          <mat-autocomplete #auto="matAutocomplete" [panelWidth]="'auto'" [displayWith]="displayFn" (optionSelected)='getOption($event.option.value)'>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.countryCode">
              <span class="flag-icon flag-icon-{{option.flag}}" style="margin-right: 5px;"></span>
              {{option.name}}
              </mat-option>
          </mat-autocomplete>
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client.placeholders.phoneClient' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-client.lm-filters-client.placeholders.phoneClient' | translate}}" type="text" formControlName="phoneClient" name="phoneClient" id="phoneClient" maxlength="9">
          </mat-form-field>
        </div>
      </div>
      <div class="filters_form_group">
        <br>
        <div>
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client.placeholders.documentNumber' | translate}}</mat-label>
            <input matInput type="text" formControlName="documentNumber" name="documentNumber" placeholder="{{'lm-client.lm-filters-client.placeholders.documentNumber' | translate}}">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client.placeholders.email' | translate}}</mat-label>
            <input matInput type="text" formControlName="email" name="email" placeholder="{{'lm-client.lm-filters-client.placeholders.email' | translate}}">
          </mat-form-field>
        </div>
      </div>
      <div class="filters_form_group">
        <br>
        <div>
          <mat-form-field>
            <mat-label>{{'lm-client.lm-filters-client.placeholders.clientType' | translate}}</mat-label>
            <mat-select formControlName="clientTypeId" name="clientTypeId">
              <mat-option *ngFor="let clientType of clientTypes" [value]="clientType.id">{{clientType.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="button-row">
        <button  mat-raised-button  [disabled]="loading"  color="primary" type="submit" class="button_submit"><mat-icon>search</mat-icon> {{'lm-client.lm-filters-client.search' | translate}}</button>
        <button  mat-raised-button  [disabled]="loading"  type="reset" (click)="resetFilters()">{{'lm-client.lm-filters-client.clean' | translate}}</button>
      </div>
    </form>
  </mat-expansion-panel>
</div>