import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LmLoginComponent } from './component/lm-login.component';
import { LmLoginRoutingModule } from './lm-login-routing.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
	declarations: [
		LmLoginComponent
	],
	imports: [
		LmLoginRoutingModule,
		FormsModule,
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatInputModule,
        MatCardModule,
        MatIconModule,
		TranslateModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule
	]
})

export class LmLoginModule { }
