import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isLoggedIn()) {
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.authenticationService.setSessionExpire("true");
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
/*
	canActivate(): boolean {
		if ( !this.localStorageService.get('token') ) {
			this.router.navigate( ['/login'] );
			return false;
		} else {
			return true;
		}
	} */

}
