

<div *ngIf="!showServiceCoverage" style="display:inline !important;">
    <app-lm-filters-professional-details [loading]="loading" [filterType]="filterType"
        (filtersApply)="getFiltersToServices($event)">
    </app-lm-filters-professional-details>
</div>
<div class="content">
    <div *ngIf="!showServiceCoverage" class="example-container ">
        <div *ngIf="dataSource && !showSortSection">
            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
              <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  <label *ngIf="column.field === 'code'">{{'lm-professional.lm-professional-services.cells.code' | translate}}</label>
                  <label *ngIf="column.field === 'description'">{{'lm-professional.lm-professional-services.cells.description' | translate}}</label>
                  <label *ngIf="column.field === 'is_active'">{{'lm-professional.lm-professional-services.cells.is_active' | translate}}</label>
                  <label *ngIf="column.field === 'valuations'">{{'lm-professional.lm-professional-services.cells.valuations' | translate}}</label>
                  <label *ngIf="column.field === 'no_comments'">{{'lm-professional.lm-professional-services.cells.no_comments' | translate}}</label>
                  <label *ngIf="column.field === 'typeName'">{{'lm-professional.lm-professional-services.cells.typeName' | translate}}</label>
                  <label *ngIf="column.field === 'actions'">{{'lm-professional.lm-professional-services.cells.actions' | translate}}</label>
                </mat-header-cell>
            
                <mat-cell *matCellDef="let row">
                  <div *ngIf="column.field === 'code'">
                    {{ row[column.field] }}
                  </div>
                  <div *ngIf="column.field === 'description'">
                    {{ row[column.field] }}
                  </div>        
                  <div *ngIf="column.field === 'typeName'">
                    {{ row[column.field] }}
                  </div>
                  <div *ngIf="column.field === 'is_active'">
                    <label *ngIf="row.is_active === true">{{'lm-offered-services.lm-offered-service-list.active.yes' | translate}}</label>
                    <label *ngIf="row.is_active === false">{{'lm-offered-services.lm-offered-service-list.active.no' | translate}}</label>
                  </div>
                  <div *ngIf="column.field === 'valuations'">
                    {{ row[column.field] }}
                  </div>        
                  <div *ngIf="column.field === 'no_comments'">
                    {{ row[column.field] }}
                  </div>        
                  <div *ngIf="column.field === 'actions'">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="getCoverageData(row)">
                            <span>Ver</span>
                        </button>
                        <button mat-menu-item *ngIf="!row['is_active']" (click)="activateOfferedService(row)">
                            <span>Activar</span>
                        </button>
                        <button mat-menu-item *ngIf="row['is_active']" (click)="desactivateOfferedService(row)">
                            <span>Desactivar</span>
                        </button>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
        
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              
              <!-- Group header -->
              <ng-container matColumnDef="groupHeader">
                <mat-cell class="headerCell" colspan="999" *matCellDef="let group">
                  <div class="headerCell--column">
                    <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
                    <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
                    {{ group.code }}
                  </div>
                  <div class="headerCell--column">
                    <strong>{{ group.parentName }}</strong>
                  </div>        
                  <div class="headerCell--column">
                    <strong>{{ group.type }}</strong>
                  </div>
                  <div class="headerCell--column">
                    <strong></strong>
                  </div>                  
                  <div class="headerCell--column">
                    <strong></strong>
                  </div>        
                  <div class="headerCell--column">
                    <strong></strong>
                  </div>        
                  <div class="headerCell--column">
                    <strong></strong>
                  </div>
                </mat-cell>
              </ng-container>
        
              <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"></mat-row>
            </mat-table>
        </div>
    </div>

    <div *ngIf="showServiceCoverage" class="example-container ">
        <button mat-icon-button color="primary" (click)="showServiceCoverage = false;">
            <mat-icon aria-label="Back Button">arrow_back</mat-icon>
        </button>
        <app-lm-professional-services-hierarchy
        [coverageData]="coverageData">
        </app-lm-professional-services-hierarchy>
    </div>
</div>