import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { OperationService } from 'src/app/pages/lm-transfers/services/operation.service';

@Component({
  selector: 'app-lm-journey-transactions',
  templateUrl: './lm-journey-transactions.component.html',
  styleUrls: ['./lm-journey-transactions.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmJourneyTransactionsComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: any = []
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  displayedColumns: string[] = ['id', 'date', 'concept', 'operationName', 'value', 'comission', 'reference'];
  @Input() operationId: any;

  constructor(private _operationService:  OperationService, private _translateService: TranslateService) {
    this._translationSubscription = this._translateService
    .get('lm-journey-details.sections.lm-journey-transactions.logic')
    .subscribe((res: { [key: string]: string }) => {
      this._messages = res;
    });
   }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    this.getTransactions();
  }
  getTransactions() {
    this.loading = true;
    this.data = []
    this.totalElements = 0;
    if (this.operationId) {
      this._operationService.getTransactions(this.page, this.pageSize, this.operationId).subscribe(response => {
        this.data = response.data;
        if (this.data.totalElements === 0) {
          alert(this._messages['no_result_found']);
          this.loading = false;
          this.data = []
        } else {
          this.loading = false;
          this.data = response.data.content;
          this.totalElements = response.data.totalElements
        }
      },
        err => {
          console.error(err);
          alert(this._messages['no_result_found']);
          this.loading = false;
          this.data = []
        });
    }
  }
  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getTransactions();
  }

  getDateWithTimeZone(d) {
		if(d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
			return new Date(time - offset * 60000).toLocaleString()
		}
		return d
	}
}
