import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ICoverageData } from '../../lm-professionals/models/lm-professional.model';
import { IRatingUpdate } from '../../journey-details/models/lm-journey.model';

@Injectable({
	providedIn: 'root'
})
export class TransferService {

	constructor(
		private _http: HttpClient
	) { }

	getOperations(page: number): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/operations?page=${page}&sort=createDate,desc`,
			{ headers: head });
	}

	getOperationsStatus(): Observable<any> {
		const head = new HttpHeaders({});
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/operations/status`,
			{ headers: head });
	}

	getOperationsFilters(payload: any, pageNumber: number): Observable<any> {
		const body = {
			client: payload.clientEmail,
			endDateStartTime: payload.dateUntilStarttime,
			endDateEndTime: payload.dateUntilEndtime,
			jobStatus: payload.stateSelected,
			professional: payload.profEmail,
			province: ((Number(payload.provinceSelected) || !payload.provinceSelected.includes(',')) ? payload.provinceSelected : "0"),
			concatenatedProvince: ((payload.provinceSelected != 0 && payload.provinceSelected.includes(',')) ? payload.provinceSelected : null),
			service: payload.serviceSelected,
			startDateStartTime: payload.dateSinceStarttime,
			startDateEndTime: payload.dateSinceEndtime,
			id: payload.idType,
			operationDateStartTime: payload.operationDateStartTime,
			operationDateEndTime: payload.operationDateEndTime,
		};
		const head = new HttpHeaders({});
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/operations?page=${pageNumber}`,
			body, { headers: head });
	}

	getRequest(page: number): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/requests?page=${page}&sort=createDate,desc`,
			{ headers: head });
	}

	getRequestStatus(): Observable<any> {
		const head = new HttpHeaders({});
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/requests/status`,
			{ headers: head });
	}

	getRequestFilters(payload: any, pageNumber: number): Observable<any> {
		const body = {
			client: payload.clientEmail,
			endDateStartTime: payload.dateUntilStarttime,
			endDateEndTime: payload.dateUntilEndtime,
			jobStatus: payload.stateSelected,
			professional: payload.profEmail,
			province: ((Number(payload.provinceSelected) || !payload.provinceSelected.includes(',')) ? payload.provinceSelected : "0"),
			concatenatedProvince: ((payload.provinceSelected != 0 && payload.provinceSelected.includes(',')) ? payload.provinceSelected : null),
			service: payload.serviceSelected,
			startDateStartTime: payload.dateSinceStarttime,
			startDateEndTime: payload.dateSinceEndtime,
			id: payload.idType,
			operationDateStartTime: payload.operationDateStartTime,
			operationDateEndTime: payload.operationDateEndTime,
		};
		const head = new HttpHeaders({});
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/requests?page=${pageNumber}`,
			body, { headers: head });
	}

	getAdditionalProfessionalData(professionalId: number): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/professionals/${professionalId}`,
			{ headers: head });
	}

	getSearchServices(): Observable<any> {
		const head = new HttpHeaders(
			{
				'Accept-Language': 'es-ES'
			}
		);
		return this._http.get(`${environment.apiWebSearch}/search/v1/services?level=2&level=3`,
			{ headers: head });
	}

	getProvinces(): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiWebSearch}/search/v1/province`,
			{ headers: head });
	}

	exportOperationToExcel(payload: any): Observable<any> {
		const body = {
			client: payload.clientEmail,
			endDateStartTime: payload.dateUntilStarttime,
			endDateEndTime: payload.dateUntilEndtime,
			jobStatus: payload.stateSelected,
			professional: payload.profEmail,
			province: ((Number(payload.provinceSelected) || !payload.provinceSelected.includes(',')) ? payload.provinceSelected : "0"),
			concatenatedProvince: ((payload.provinceSelected != 0 && payload.provinceSelected.includes(',')) ? payload.provinceSelected : null),
			service: payload.serviceSelected,
			startDateStartTime: payload.dateSinceStarttime,
			startDateEndTime: payload.dateSinceEndtime,
			startDate: payload.dateSince,
			id: payload.idType,
		};
		const params = {
			timeZone: Utilities.getTimeZone()
		}
		const head = new HttpHeaders({});
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/operations/excel`,
			body, { headers: head, params });
	}

	exportRequestToExcel(payload: any): Observable<any> {
		const body = {
			client: payload.clientEmail,
			endDateStartTime: payload.dateUntilStarttime,
			endDateEndTime: payload.dateUntilEndtime,
			jobStatus: payload.stateSelected,
			professional: payload.profEmail,
			province: ((Number(payload.provinceSelected) || !payload.provinceSelected.includes(',')) ? payload.provinceSelected : "0"),
			concatenatedProvince: ((payload.provinceSelected != 0 && payload.provinceSelected.includes(',')) ? payload.provinceSelected : null),
			service: payload.serviceSelected,
			startDateStartTime: payload.dateSinceStarttime,
			startDateEndTime: payload.dateSinceEndtime,
			id: payload.idType,
			operationDateStartTime: payload.operationDateStartTime,
			operationDateEndTime: payload.operationDateEndTime,
		};
		const params = {
			timeZone: Utilities.getTimeZone()
		}
		const head = new HttpHeaders({});
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/requests/excel`,
			body, { headers: head, params });
	}


	getAllPaycometOperation(page: number, size: number) {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/backofficeOperations?page=${page}&size=${size}`,
			{ headers: head });
	}

	getTransactionTypes() {
		const head = new HttpHeaders({});
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/payments/transactions`,
			{ headers: head });
	}

	getFiltersPaycometOperation(page: number, payload: any, size: number): Observable<any> {
		const body = {
			startTransaction: payload.startTransaction,
			endTransaction: payload.endTransaction,
			operationId: payload.operationId,
			listOperationName: payload.listOperationName,
			campaigns: payload.campaigns,
			stores: payload.stores,
			commercialId: payload.commercialId
		};
		const head = new HttpHeaders(
			{}
		);

		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/backofficeOperationsFilters?page=${page}&size=${size}`,
			body, { headers: head });
	}

	updateStatusOperationPaycomet(paycometId: number): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/updateStatusOperationsPaycomet/${paycometId}`,
			{ headers: head });
	}

	exportPaycometOperationToExcel(payload: any): Observable<any> {
		const body = {
			endTransaction: payload.endTransaction,
			startTransaction: payload.startTransaction,
			operationId: payload.operationId,
			listOperationName: payload.listOperationName,
			campaigns: payload.campaigns,
			stores: payload.stores,
			commercialId: payload.commercialId
		};
		const params = {
			timeZone: Utilities.getTimeZone()
		}
		const head = new HttpHeaders({});
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/paycomet/excel`,
			body, { headers: head, params });
	}

	//ChatMessages
	findAllMessagesByChatId(chatId: string): Observable<any> {
		const head = new HttpHeaders({});
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/chat/${chatId}`,
			{ headers: head });
	}

	getAllCampaigns(): Observable<any> {
		const head = new HttpHeaders({});
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/services/campaigns`,
			{ headers: head });
	}

	getAllStores(): Observable<any> {
		const head = new HttpHeaders({});
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/direct/stores`,
			{ headers: head });
	}

	_getTimeZone() {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	getServiceTypes(): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/services/types`,
			{ headers: head });
	}


	getServices(professionalId: any, payload: any = {}): Observable<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.post<any>(
			`${environment.apiHosts}/backoffice-server/api/v1/services/professional/${professionalId}`,
			payload,
			{ headers: head },
		);
	}

	getServiceCoverageDetails(coverageData: ICoverageData, page: number, pageSize: number, payload: any) {
		const head = new HttpHeaders(
			{}
		);
		return this._http.post<any>(
			`${environment.apiHosts}/backoffice-server/api/v3/details/service/${coverageData.offeredServiceId}/professional/${coverageData.professionalId}?page=${page}&size=${pageSize}`,
			payload,
			{ headers: head },
		);
	}

	getCouponVersion(couponVersionId): Observable<any> {
		const head = new HttpHeaders(
			{ 'Accept-Language': 'es-ES' }
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/coupons-versions/${couponVersionId}`,
			{ headers: head });
	}

	changeStatusOfferedService(professionalId: number, offeredServiceId: number, statusService: string): Observable<any> {
		const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
		return this._http.put(`${environment.apiHosts}/backoffice-server/api/v1/professionals/${professionalId}/services/${offeredServiceId}?statusService=${statusService}`,
			{ headers: head });
	}

	retrieveMasterAreaByZipCode(zipCode: string): Observable<any> {
		const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/master-area/zip-code/${zipCode}`,
			{ headers: head });
	}

	updateRatingOperation(payload: IRatingUpdate, operationId: number, professionalId: number, ratingId: number): Observable<any> {
		const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
		return this._http.put(`${environment.apiHosts}/backoffice-server/api/v1/professionals/${professionalId}/operations/${operationId}/ratings/${ratingId}`,
			payload,
			{ headers: head });
	}

	getCountries(): Observable<any> {
		const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/country-phone-prefix?countryLanguageCode=es-ES`,
			{ headers: head });
	}

}
