<div class="content" *ngIf="data">
  <mat-card class="card">
     <mat-card-header>
      <div *ngIf="data.serviceImage" mat-card-avatar class="service_image"><img mat-card-image src="{{data.serviceImage}}" alt=""></div>
      <mat-card-title>{{data.friendlyCode}}</mat-card-title>
      <mat-card-subtitle><strong>{{'lm-journey-details.titles.services' | translate}} </strong>{{data.serviceName}}</mat-card-subtitle>
      <mat-card-subtitle><strong>{{'lm-journey-details.titles.status' | translate}} </strong>{{data.serviceStatus ? _status.active: _status.inactive}}</mat-card-subtitle>
      <mat-card-subtitle><strong>{{'lm-journey-details.titles.date_reservation' | translate}} </strong>{{data.reservationDate | date: "yyyy-MM-dd HH:mm:ss"}}</mat-card-subtitle>
    </mat-card-header>
   <mat-card-content> 

      <mat-tab-group animationDuration="0ms" #tabGroup>
        <mat-tab label="{{'lm-journey-details.labels.basic_data' | translate}}">
          <ng-template matTabContent>
            <app-lm-journey-basic-data [requestId]="id"
            (editRatingOperationEvent)="openEditRating($event)">
            </app-lm-journey-basic-data>
          </ng-template>

        </mat-tab>
        <mat-tab label="{{'lm-journey-details.labels.work' | translate}}">
          <ng-template matTabContent>
            <app-lm-journey-work [requestId]="id">
            </app-lm-journey-work>
          </ng-template>

        </mat-tab>
        <mat-tab label="{{'lm-journey-details.labels.transactions' | translate}}">
          <ng-template matTabContent>
            <app-lm-journey-transactions [operationId]="operationId">
            </app-lm-journey-transactions>
          </ng-template>


        </mat-tab>
        <mat-tab label="{{'lm-journey-details.labels.status_historiques' | translate}}">
          <ng-template matTabContent>
            <app-lm-journey-timeline [requestId]="id">
            </app-lm-journey-timeline>
          </ng-template>


        </mat-tab>

        <mat-tab *ngIf="editRatingOperation">
          <ng-template mat-tab-label>
            <label>{{'lm-journey-details.labels.rating_update' | translate}}</label>
            <button mat-icon-button (click)="closeEditRating(null)">
              <mat-icon>close</mat-icon>
            </button>
          </ng-template>
          <ng-template matTabContent>
            <app-lm-update-rating [rating]="rating"
            [operationId]="operationId"
            (editRatingOperationEvent)="closeEditRating($event)">
            </app-lm-update-rating>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </mat-card-content> 


  </mat-card>
</div>