  <div class="content" *ngIf="form">
  <mat-expansion-panel  [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'lm-professional.lm-filters-professional.title' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
      <form action="" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="filters_form_group">
          <br>
          <div class="filter-dates">
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-filters-professional.placeholders.date_form' | translate}}</mat-label>
              <input matInput [matDatepicker]="picker1" placeholder="{{'lm-professional.lm-filters-professional.placeholders.date_form' | translate}}" formControlName="createDateStart"
                name="createDateStart">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="filter-dates">
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-filters-professional.placeholders.date_to' | translate}}</mat-label>
              <input matInput [matDatepicker]="picker2" placeholder="{{'lm-professional.lm-filters-professional.placeholders.date_to' | translate}}" formControlName="createDateEnd"
                name="createDateEnd">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="filters_form_group">
          <br>
          <mat-form-field class="field-client-name">
            <mat-label>{{'lm-professional.lm-filters-professional.placeholders.nombres' | translate}}</mat-label>
            <input matInput type="text" formControlName="nameAndLastNameProfessional" name="nameAndLastNameProfessional" placeholder="{{'lm-professional.lm-filters-professional.placeholders.nombres' | translate}}">
          </mat-form-field>
          <mat-form-field class="field-client-name">
            <mat-label>{{'lm-professional.lm-filters-professional.placeholders.email' | translate}}</mat-label>
            <input matInput type="text" formControlName="email" name="email" placeholder="{{'lm-professional.lm-filters-professional.placeholders.email' | translate}}">
          </mat-form-field>
        </div>

        <div class="filters_form_group">
          <br>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-filters-professional.placeholders.doc_number' | translate}}</mat-label>
              <input matInput type="text" formControlName="documentNumber" name="documentNumber" placeholder="{{'lm-professional.lm-filters-professional.placeholders.doc_number' | translate}}">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-filters-professional.placeholders.phone_professional' | translate}}</mat-label>
              <input matInput type="text" formControlName="phoneProfessional" name="phoneProfessional" placeholder="{{'lm-professional.lm-filters-professional.placeholders.phone_professional' | translate}}">
            </mat-form-field>
          </div>
        </div>

        <div class="filters_form_group">
          <br>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-filters-professional.type_person' | translate}}</mat-label>
              <mat-select matNativeControl formControlName="personType" name="personType">
                <mat-option value="FREE">{{'lm-professional.lm-filters-professional.person.freelance' | translate}}</mat-option>
                <mat-option value="COMP">{{'lm-professional.lm-filters-professional.person.company' | translate}}</mat-option>
                <mat-option value="PART">{{'lm-professional.lm-filters-professional.person.particular' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-filters-professional.type_doc' | translate}}</mat-label>
              <mat-select matNativeControl formControlName="documentType" name="documentType">
                <mat-option value="DNI">{{'lm-professional.lm-filters-professional.doc.dni' | translate}}</mat-option>
                <mat-option value="NIF">{{'lm-professional.lm-filters-professional.doc.nif' | translate}}</mat-option>
                <mat-option value="CIF">{{'lm-professional.lm-filters-professional.doc.cif' | translate}}</mat-option>
                <mat-option value="NIE">{{'lm-professional.lm-filters-professional.doc.nie' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="filters_form_group">
          <br>
          <div class="dropdown-div">
            <ng-multiselect-dropdown class="lm-input" [placeholder]="'lm-professional.lm-filters-professional.placeholders.campaign' | translate" [data]="campaigns"
              formControlName="campaignSelected" name="campaignSelected" [settings]="dropdownSettingCampaigns">
            </ng-multiselect-dropdown>
          </div>

          <div>
            <mat-form-field class="field-client-name">
              <mat-label>{{'lm-professional.lm-filters-professional.placeholders.ibanNumber' | translate}}</mat-label>
              <input matInput type="text" formControlName="ibanNumber" name="ibanNumber" placeholder="{{'lm-professional.lm-filters-professional.placeholders.ibanNumber' | translate}}">
            </mat-form-field>
          </div>
        </div>
        
        <div class="button-row">
          <button  mat-raised-button  [disabled]="loading"  color="primary" type="submit" class="button_submit"><mat-icon>search</mat-icon> {{'lm-professional.lm-filters-professional.search' | translate}}</button>
          <button  mat-raised-button  [disabled]="loading"  type="reset" (click)="resetFilters()">{{'lm-professional.lm-filters-professional.clean' | translate}}</button>
        </div>
      </form>
  </mat-expansion-panel>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
</div>