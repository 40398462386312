import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CouponVersion } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { CouponDialogComponent } from 'src/app/pages/lm-transfers/sections/coupon-dialog/coupon-dialog.component';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { RequestService } from '../../../lm-transfers/services/request.service';
import { IJourneyData } from '../../models/lm-journey.model';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-journey-basic-data',
  templateUrl: './lm-journey-basic-data.component.html',
  styleUrls: ['./lm-journey-basic-data.component.scss']
})
export class LmJourneyBasicDataComponent implements OnInit {

  @Output() editRatingOperationEvent = new EventEmitter<any>();
  

  data: IJourneyData = {};
  couponData;
  @Input() requestId: any;
  rejectObject: any;
  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;
  isUserLeroy: boolean;

  arrayGeneralRating = [];
  arrayPunctualRating = [];
  arrayBehaviorRating = [];
  arraySatisfiedRating = [];
  arrayCleanRating = [];
  starOn: string = "";
  starHalf: string = "";
  starOff: string = "";

  constructor(private _requestService: RequestService,
    private _transferService: TransferService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserLeroy();
    this.getBasicData(this.requestId);
    this.starOn = "/assets/img/icons/i-star-on--v2.svg";
    this.starHalf = "/assets/img/icons/i-star-half--v2.svg";
    this.starOff = "/assets/img/icons/i-star-off--v2.svg";
  }

  /**
   * Retreive Operation Basic Data
   * @param requestId 
   */
  getBasicData(requestId: number) {
    this._requestService.getBasicData(requestId).then(res => {
      this.data = res.data;
      if (res.data.operationCoupons) {
        this.couponData = res.data.operationCoupons.at(0);
      } else if (res.data.requestCoupons) {
        this.couponData = res.data.requestCoupons.at(0);
      }
      let rejectRequest = res.data.requestRejectionRest;
      let rejectOperation = res.data.operationRejectionRest;

      if (rejectRequest) {
        this.rejectObject = rejectRequest;
      }

      if (rejectOperation) {
        this.rejectObject = rejectOperation;
      }

      if (rejectOperation && rejectRequest) {
        this.rejectObject = rejectOperation;
      }

      if (this.data.ratingRest) {
        this.createStarsGeneral(this.data.ratingRest.generalRating);
        this.createStarPunctual(this.data.ratingRest.punctual);
        this.createStarBehavior(this.data.ratingRest.behavior);
        this.createStarSatisfied(this.data.ratingRest.satisfied);
        this.createStarClean(this.data.ratingRest.clean);
      }

    }).catch(err => {
      console.error(err);
    })
  }

  getLocalNumber(number, decimals: number = 2) {
    return new Intl.NumberFormat('es-ES', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    }).format(number)
  }

  getIsCanceled(isCanceledLessTwentyFourHours: boolean): string {
    let isCanceledLessTwentyFourHoursText = '';
    if (isCanceledLessTwentyFourHours) {
      isCanceledLessTwentyFourHoursText = '< 24 horas';
    }
    return isCanceledLessTwentyFourHoursText;
  }

  /**
   * Show coupon version
   * @param couponInfo Coupon information
   */
  showCouponVersion(couponInfo): void {
    this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
      this.couponVersion = res['data'];
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(CouponDialogComponent, {
          width: '400px',
          height: 'auto',
          data: {
            coupon: this.couponVersion
          }
        });

        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      }
    });
  }

  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString()
    }
    return d
  }

  /**
   * Ratings
   */

  createStarsGeneral(value: number) {
    this.loopCreateObject(this.arrayGeneralRating, this.starOn, value);
    if (value % 1 !== 0) {
      const halfStar: object = {
        img: this.starHalf,
      };
      this.arrayGeneralRating.push(halfStar);
    }
    const restPush = 5 - value;
    this.loopCreateObject(this.arrayGeneralRating, this.starOff, restPush);
  }

  createStarPunctual(value: number) {
    this.loopCreateObject(this.arrayPunctualRating, this.starOn, value);
    if (value % 1 !== 0) {
      const halfStar: object = {
        img: this.starHalf,
      };
      this.arrayPunctualRating.push(halfStar);
    }
    const restPush = 5 - value;
    this.loopCreateObject(this.arrayPunctualRating, this.starOff, restPush);
  }

  createStarBehavior(value: number) {
    this.loopCreateObject(this.arrayBehaviorRating, this.starOn, value);
    if (value % 1 !== 0) {
      const halfStar: object = {
        img: this.starHalf,
      };
      this.arrayBehaviorRating.push(halfStar);
    }
    const restPush = 5 - value;
    this.loopCreateObject(this.arrayBehaviorRating, this.starOff, restPush);
  }

  createStarSatisfied(value: number) {
    this.loopCreateObject(this.arraySatisfiedRating, this.starOn, value);
    if (value % 1 !== 0) {
      const halfStar: object = {
        img: this.starHalf,
      };
      this.arraySatisfiedRating.push(halfStar);
    }
    const restPush = 5 - value;
    this.loopCreateObject(this.arraySatisfiedRating, this.starOff, restPush);
  }

  createStarClean(value: number) {
    this.loopCreateObject(this.arrayCleanRating, this.starOn, value);
    if (value % 1 !== 0) {
      const halfStar: object = {
        img: this.starHalf,
      };
      this.arrayCleanRating.push(halfStar);
    }
    const restPush = 5 - value;
    this.loopCreateObject(this.arrayCleanRating, this.starOff, restPush);
  }

  loopCreateObject(array, text: string, value: number) {
    for (let i = 0; i < Math.trunc(value); i++) {
      const star: object = {
        img: text,
      };
      array.push(star);
    }
  }

  openEditRating() {
    let professionalId = this.data.professionalDetails.id;
    this.editRatingOperationEvent.emit({...this.data.ratingRest, professionalId});
  }
}
