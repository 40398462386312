import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-payby',
  templateUrl: './modal-payby.component.html',
  styleUrls: ['./modal-payby.component.scss']
})
export class ModalPaybyComponent implements OnInit {

  operation: any;
  reference: string;
  importe: string;
  offeredService: string;
  client: string;
  inProgress: boolean = false;
  transferDiv: boolean = false;
  transferMsg: string;

  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<ModalPaybyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.importe = this.data['importe'];
    this.reference = this.data['reference'].toUpperCase();
    this.operation = this.data['operation'];
    this.offeredService = this.operation['operationOfferedServices'][0]['title'];
    this.client = this.operation['clientDetails']['clientName'] + ' ' + this.operation['clientDetails']['clientLastName'];
    this.inProgress = this.data['progress'];
    this.transferDiv = this.data['transferDiv'];
    this.transferMsg = this.data['transferMsg'];
  }

  dismiss() {
    this.inProgress = false;
    this.dialogRef.close();
  }

  submit() {
    this.inProgress = true;
    this.submitClicked.emit('submit');
  }

  goContinue() {
    this.submitClicked.emit('continue');
  }

  myf(d, f, m) {
    this.inProgress = d['progress'];
    this.transferDiv = f['transferDiv'];
    this.transferMsg = m['transferMsg'];
  }

}
