import { SepaDocumentTypeHint } from './../../models/lm-professional.model';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProfessionalService } from '../../services/professional.service';
import { SepaDocumentType } from '../../models/lm-professional.model';
import { ImageCropperComponent } from '../../../../../app/components/image-cropper/component/image-cropper.component';
import { ImageCroppedEvent } from '../../../../../app/components/image-cropper/interfaces';
import { retrieveSizeFile } from '../../../../../app/components/image-cropper/utils/size.util';
import { readBase64 } from '../../../../../app/components/image-cropper/utils/transform.util';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-lm-professional-documents',
    templateUrl: './lm-professional-documents.component.html',
    styleUrls: ['./lm-professional-documents.component.scss']
})

export class LmProfessionalDocumentsComponent implements OnInit, OnDestroy {

    paycometForm: FormGroup;
    professionalId;
    loading: boolean;

    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    @Input() editPaycometDocuments: boolean;
    @Output() editPaycometDocumentsChange = new EventEmitter<boolean>();
    @Output() editPaycometDocumentsEvent = new EventEmitter<boolean>();

    pdfChangedEvent: boolean = false
    @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;
    pdfSource = '';

    imageChangedEvent: any = '';
    file: any;
    fileName: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    showCropper = false;
    containWithinAspectRatio = false;
    percentDone: number;
    size: any = '';
    width: any = '';
    height: any = '';
    finalWidth: any = '';
    finalHeight: any = '';
    sizeFinal: any = '';
    sizeUpload: number;
    fileSize: any;
    pages: any = '';
    @ViewChild('f_input') imgType: ElementRef;

    sepaDocumentType: SepaDocumentType[];
    sepaDocumentTypeHints: SepaDocumentTypeHint[];
    sepaDocumentTypeDropdown: any[] = [];
    dropdownSettingDocumentTypes = {};

    constructor(private _professionalService: ProfessionalService,
        private _route: ActivatedRoute,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar) { }

    ngOnInit() {
        this.professionalId = this._route.snapshot.paramMap.get("professionalId");
        this.retrieveAllSepaDocumentTypes();
        this.createPaycomeForm();
    }

    ngOnDestroy(): void {
    }

    retrieveAllSepaDocumentTypes() {
        this._professionalService.retrieveSepaDocumentTypes().subscribe(res => {
            this.sepaDocumentType = res['data'];
            this.sepaDocumentType.forEach(item => {
                let documentType: any = {};
                documentType['id'] = item.documentTypeId;
                if (item.documentTypeDescriptionRests) {
                    documentType['name'] = item.documentTypeDescriptionRests[0].descritpion;
                }
                this.sepaDocumentTypeDropdown.push(documentType);
            });
        })
    }

    retrieveHintSepaDocumentType(sepaDocumentId) {
        this.sepaDocumentTypeHints = null;
        if (sepaDocumentId != '') {
            let data = this.sepaDocumentType.filter(x => x.documentTypeId === sepaDocumentId);
            this.sepaDocumentTypeHints = data[0]['sepaDocumentTypeHintRests'];
        }
    }

    fileChangeEvent(event: any): void {
        let image = event.target.files[0];
        this.file = event.target.files[0];
        this.fileName = image.name;
        this.fileSize = this.file.size;
        this.paycometForm.controls['fileSelected'].setValue(this.fileName);
        if (image.type === 'application/pdf') {
            this.pdfChangedEvent = true;
            const blob = window.URL.createObjectURL(this.file);
            const link = document.createElement('a');
            link.href = blob;
            this.pdfSource = link.href;
            this.size = retrieveSizeFile(this.file.size);
            this.sizeUpload = this.file.size;
            readBase64(this.file).then(res => {
                if(res != null) {
                    this.paycometForm.controls['fileContent'].setValue(res.split(',')[1]);
                }
            });
            this.imageChangedEvent = null;
        } else {
            this.pdfChangedEvent = false;
            this.percentDone = 100;
            this.imageChangedEvent = event;
            this.size = retrieveSizeFile(image.size);
            this.sizeUpload = image.size;
            setTimeout(() => {
                this.obtainImageDimension(image);
            }, 1000);
        }
    }

    callBackFn(pdf: PDFDocumentProxy) {
        this.pages = pdf.numPages;
     }

    obtainImageDimension(image) {
        var fr = new FileReader;
        var img = new Image();
        fr.onload = () => {
            img.onload = () => {
                this.width = img.width;
                this.height = img.height;
            };
            img.src = fr.result.toString();
        };
        fr.readAsDataURL(image);
        this.imgType.nativeElement.value = "";
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        let im = new Image;
        im.src = this.croppedImage;
        im.onload = () => {
            this.finalWidth = im.width;
            this.finalHeight = im.height;
        };
        const base64str = this.croppedImage.split('base64,')[1];
        const decoded = atob(base64str);
        this.sizeFinal = retrieveSizeFile(decoded.length);
        this.sizeUpload = decoded.length;
        this.paycometForm.controls['fileContent'].setValue(base64str);
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady() {
        console.debug('Cropper ready')
    }

    loadImageFailed() {
        console.debug('Load failed');
    }
    rotateLeft() {
        this.imageCropper.rotateLeft();
    }
    rotateRight() {
        this.imageCropper.rotateRight();
    }
    flipHorizontal() {
        this.imageCropper.flipHorizontal();
    }
    flipVertical() {
        this.imageCropper.flipVertical();
    }

    closeTab() {
        this.editPaycometDocuments = false;
        this.editPaycometDocumentsEvent.emit(false);
    }

    createPaycomeForm(): void{
        this.paycometForm = new FormGroup({
            documentType: new FormControl('', Validators.required),
            fileContent: new FormControl('', Validators.required),
            fileSelected: new FormControl('', Validators.required)
        });
    }

    get f(){
        return this.paycometForm.controls;
      }

    submitForm(): void{
        if(!this.paycometForm.valid) {
            return;
        }
        
        if(this.sizeUpload > 5000000) {
            this._snackBar.open(this._translateService.instant('lm-professional.lm-paycomet-documents.error.file_long_size'),
            this._translateService.instant('lm-professional.lm-paycomet-documents.upload.ok'), { duration: 1500 });
            return;
        }

        this.loading = true;
        let body = {
            documentType: this.paycometForm['controls']['documentType'].value,
            fileContent: this.paycometForm['controls']['fileContent'].value
        }
        this._professionalService.savePaycometDocument(body, this.professionalId).subscribe(res => {
            if(res['code'] == 200) {
                this.loading = false;
                this._snackBar.open(this._translateService.instant('lm-professional.lm-paycomet-documents.upload.success'),
                this._translateService.instant('lm-professional.lm-paycomet-documents.upload.ok'), { duration: 1500 });
                this.closeTab();
            }
        });
    }
}