<mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
<div *ngIf="form">
  <form action="" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="basic-data-content">
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-client.lm-client-update-basic-data.titles.client_data' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.name' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.name' | translate}}" 
              type="text" formControlName="name" name="name" maxlength="60">
              <mat-error *ngIf="f.name.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.name' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.lastname' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.lastname' | translate}}" 
              type="text" formControlName="lastName" name="lastName" maxlength="60">
              <mat-error *ngIf="f.lastName.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.lastname' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.email' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.email' | translate}}" 
              type="text" formControlName="email" name="email" maxlength="255">
              <mat-error *ngIf="!f.email.hasError('email') && !f.email.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.email' | translate}}
              </mat-error>
              <mat-error *ngIf="!f.email.hasError('email') && f.email.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.email' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field style="width: 30%;">
              <mat-label>Tipo de documento</mat-label>
              <mat-select formControlName="documentType" name="documentType" (selectionChange)="captionRegex($event.value)">
                <mat-option *ngFor="let regex of documentsRegex" [value]="regex.id">{{regex.documentType}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 40%;">
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.documentNumber' | translate}}</mat-label>
              <input id="documentNumber" matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.documentNumber' | translate}}" 
              type="text" formControlName="documentNumber" name="documentNumber" maxlength="50">
              <mat-error *ngIf="f.documentNumber.hasError('required') || f.documentNumber.invalid">
                {{'lm-client.lm-client-update-basic-data.form.error.documentNumber' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <span *ngIf="countrySelected" class="flag-icon flag-icon-{{countrySelected.flag}}"></span>
            <mat-form-field class="field--field-country">
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.phoneCountry' | translate}}</mat-label>
              <input
              type="text"
              matInput
              formControlName="phoneCountryCode"
              [matAutocomplete]="auto"
              size="2"
              maxlength="5"
              name="phoneCountryCode"
              placeholder="{{'lm-client.lm-client-update-basic-data.form.label.phoneCountry' | translate}}"
              (click)="clickEvent()"
              (blur)="blurCountry($event)"
            >
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete" [panelWidth]="'auto'" [displayWith]="displayFn" (optionSelected)='getOption($event.option.value)'>
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.countryCode">
                <span class="flag-icon flag-icon-{{option.flag}}" style="margin-right: 5px;"></span>
                {{option.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-form-field class="field--field-number">
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.phoneNumber' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.phoneNumber' | translate}}" type="text" formControlName="phoneNumber" name="phoneNumber" id="phoneNumber" minlength="{{phoneMinLenght}}" maxlength="{{phoneMaxLenght}}">
              <mat-error *ngIf="f.phoneNumber.errors?.pattern">
                {{'lm-client.lm-client-update-basic-data.form.error.phone' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-client.lm-client-update-basic-data.titles.addresses' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.address' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.address' | translate}}" 
              type="text" formControlName="address" name="address" maxlength="255">
              <mat-error *ngIf="f.address.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.address' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.complementAddress' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.name' | translate}}" 
              type="text" formControlName="complementAddress" name="complementAddress" maxlength="255">
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.zipCode' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.zipCode' | translate}}" 
              type="text" formControlName="zipCode" name="zipCode" maxlength="5" (blur)="blurFindProvinceCity($event)">
              <mat-error *ngIf="f.zipCode.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.zipCode' | translate}}
              </mat-error>
              <mat-error *ngIf="f.zipCode.hasError('required') || f.zipCode.invalid">
                ingrese un codigo postal valido
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.city' | translate}}</mat-label>
              <span matTooltipPosition="after"
              matTooltip="{{'lm-client.lm-client-update-basic-data.form.tooltip.zipcode_enter' | translate}}">
                <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.city' | translate}}" 
                type="text" formControlName="city" name="city" maxlength="50" readonly>
              </span>
              <mat-error *ngIf="f.city.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.city' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.province' | translate}}</mat-label>
              <span matTooltipPosition="after" 
              matTooltip="{{'lm-client.lm-client-update-basic-data.form.tooltip.zipcode_enter' | translate}}">
                <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.province' | translate}}" 
                type="text" formControlName="province" name="province" maxlength="50" readonly>
              </span>
              <mat-error *ngIf="f.province.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.province' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.addressType' | translate}}</mat-label>
              <mat-select formControlName="addressTypeId" name="addressTypeId">
                <mat-option *ngFor="let adresseType of adresseTypes" [value]="adresseType.id">{{adresseType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-client.lm-client-update-basic-data.titles.billing_information' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.billName' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.billName' | translate}}" 
              type="text" formControlName="billName" name="billName" maxlength="60">
              <mat-error *ngIf="f.billName.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.billName' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.billLastName' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.billLastName' | translate}}" 
              type="text" formControlName="billLastName" name="billLastName" maxlength="60">
              <mat-error *ngIf="f.billLastName.hasError('required')">
                {{'lm-client.lm-client-update-basic-data.form.error.billLastName' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field style="width: 30%;">
              <mat-label>Tipo de documento</mat-label>
              <mat-select name="documentType" formControlName="billDocumentType" (selectionChange)="billCaptionRegex($event.value)">
                <mat-option *ngFor="let regex of documentsRegex" [value]="regex.id">{{regex.documentType}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 40%;">
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.billDocumentNumber' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.billDocumentNumber' | translate}}" 
              type="text" id="billDocumentNumber" formControlName="billDocumentNumber" name="billDocumentNumber" maxlength="50">
              <mat-error *ngIf="f.billDocumentNumber.hasError('required') || f.billDocumentNumber.invalid">
                {{'lm-client.lm-client-update-basic-data.form.error.billDocumentNumber' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-client.lm-filters-client.placeholders.clientType' | translate}}</mat-label>
              <mat-select formControlName="billClientTypeId" name="billClientTypeId">
                <mat-option *ngFor="let clientType of clientTypes" [value]="clientType.id">{{clientType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field">
            <span *ngIf="billCountrySelected" class="flag-icon flag-icon-{{billCountrySelected.flag}}"></span>
            <mat-form-field class="field--field-country">
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.phoneCountry' | translate}}</mat-label>
              <input
              type="text"
              matInput
              formControlName="billCountryCode"
              [matAutocomplete]="autoBill"
              size="2"
              maxlength="5"
              name="billCountryCode"
              placeholder="{{'lm-client.lm-client-update-basic-data.form.label.phoneCountry' | translate}}"
              (click)="clickEventBill()"
              (blur)="blurCountryBill($event)"
            >
            </mat-form-field>
            <mat-autocomplete #autoBill="matAutocomplete" [panelWidth]="'auto'" [displayWith]="displayFn" (optionSelected)='getBillOption($event.option.value)'>
              <mat-option *ngFor="let option of filteredOptionsBill | async" [value]="option.countryCode">
                <span class="flag-icon flag-icon-{{option.flag}}" style="margin-right: 5px;"></span>
                {{option.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-form-field class="field--field-number">
              <mat-label>{{'lm-client.lm-client-update-basic-data.form.label.phoneNumber' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-client.lm-client-update-basic-data.form.label.phoneNumber' | translate}}" type="text" formControlName="billPhone" name="billPhone" id="billPhone" minlength="{{billPhoneMinLenght}}" maxlength="{{billPhoneMaxLenght}}">
              <mat-error *ngIf="f.billPhone.errors?.pattern">
                {{'lm-client.lm-client-update-basic-data.form.error.billPhone' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-data">
      <div class="section">
      </div>
      <div class="section">
      </div>
      <div class="section">
        <button mat-raised-button color="primary" type="submit" [disabled]="loading" class="button_submit">
          <mat-icon>save</mat-icon> {{'lm-client.lm-client-update-basic-data.form.buttons.submit' | translate}}
        </button>
        <button mat-raised-button (click)="closeTab()">
          <mat-icon>cancel</mat-icon> {{'lm-client.lm-client-update-basic-data.form.buttons.cancel' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>