import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ChatsDialogComponent } from 'src/app/pages/lm-transfers/sections/chats-dialog/chats-dialog.component';
import { ImagesDialogComponent } from 'src/app/pages/lm-transfers/sections/images-dialog/images-dialog.component';
import { ProfessionalModalComponent } from 'src/app/pages/lm-transfers/sections/professional-dialog/professional-dialog';
import { OperationService } from 'src/app/pages/lm-transfers/services/operation.service';
import { IFilters, IFiltersData, IOperationsRequestsFilters } from '../../models/lm-professional.model';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { CouponVersion } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { CouponDialogComponent } from 'src/app/pages/lm-transfers/sections/coupon-dialog/coupon-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-professional-operations',
  templateUrl: './lm-professional-operations.component.html',
  styleUrls: ['./lm-professional-operations.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmProfessionalOperationsComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: any = []
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  filterType: string="professionalOperations";
  filters: IFilters = {};
  operationsRequestsFilters: IOperationsRequestsFilters;
  buttonShowMore: boolean;
  stringShowMore: string;
  displayedColumns: string[] = ['id', 'operationStatus', 'operationOfferedServices', 'clientDetails', 'startTime', 'endTime', 'professionalDetails', 'totalCost',
    'totalPaid', 'operationAddress', 'products', 'unit', 'rejectionMessage', 'campaigns', 'storeName', 'comercialId', 'fill', 'actions'];
  @Input() filtersData: IFiltersData;

  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;

  constructor(
    private _operationService: OperationService, 
    public dialog: MatDialog, 
    private _translateService: TranslateService,
    private _transferService: TransferService) {
    this.buttonShowMore = false;
    this.stringShowMore = 'Ver todos';
    this._translationSubscription = this._translateService
    .get('lm-professional.lm-professional-operations.logic')
    .subscribe((res: { [key: string]: string }) => {
      this._messages = res;
    });
    this.stringShowMore = this._messages['see_all'];
  }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    if(this.filters!==null && this.filters.isFilterBuilt==true){
      this.getOperations();
    }
    
  }

  getOperations() {
    this.operationsRequestsFilters = this.filters.operationsRequestsFilters;
    this.loading = true;
    this.data = [];
    this.totalElements = 0;
  
    this._operationService.getOperations(this.page, this.pageSize, this.operationsRequestsFilters).subscribe(
      response => {
        const responseData = response.data;
        if (responseData.totalElements === 0) {
          alert(this._messages['no_result_found']);
          this.data = [];
        } else {
          this.data = responseData.content;
          this.totalElements = responseData.totalElements;
        }
      },
      err => {
        alert(this._messages['no_result_found']);
        this.data = [];
      }
    ).add(() => {
      this.loading = false;
    });
  }

    getOperationsFilters() {
        this.getOperations();
    }

    getFiltersToOperations(filtersApply) {
        this.filters = filtersApply;
        this.page = 0;
        this.getOperations();
    }

    getPaginatorData(event) {
        this.page = event['pageIndex'];
        this.pageSize = event['pageSize'];
        this.getOperations();
    }


    getDateWithTimeZone(d) {
        if (d) {
          const time = new Date(d).getTime()
          const offset = new Date().getTimezoneOffset();
          return new Date(time - offset * 60000).toLocaleString()
        }
        return d
    }


    isEmpty(str) {
        return (!str || str.length === 0 );
    }

	showMore() {
		this.buttonShowMore ? this.stringShowMore = this._messages['see_all'] : this.stringShowMore = this._messages['hide_all'];
		this.buttonShowMore = !this.buttonShowMore;
	}


    //Chats
    openDialog(chatId: string, professional: string, clientDetails: string): void {
        if(professional != null ) {
            if(clientDetails != null) {
                var dialogRef = this.dialog.open(ChatsDialogComponent, {
                  width: '1200px',
                  height: 'auto',
                  maxHeight: '600px',
                  data: {
                    chatId: chatId,
                    professional: professional['email'],
                    client: clientDetails['email']
                  }
                });

                dialogRef.afterClosed().subscribe(result => {
                  dialogRef = null;
                });
            } else {
                alert(this._messages['customer_not_found']);
            }

        } else {
            alert(this._messages['professional_not_found']);
        }
    }

    //Images
    openImagesDialog(url: string, type: string): void {
            if(url != null) {
                var dialogRef = this.dialog.open(ImagesDialogComponent, {
                  width: '80%',
                  height: '80%',
                  maxHeight: '80%',
                  data: {
                    url: url,
                    type : type
                  }
                });

                dialogRef.afterClosed().subscribe(result => {
                    dialogRef = null;
                });
            }
      }

    //Professional detail
    openProfessionalDialog(professionalId: number): void {
    //this._router.navigate(['/professionals/'+professionalId]);
            if(professionalId != null) {
                var dialogRef = this.dialog.open(ProfessionalModalComponent, {
                  width: '80%',
                  height: '80%',
                  maxHeight: '80%',
                  data: {
                    professionalId: professionalId
                  }
                });
                dialogRef.afterClosed().subscribe(result => {
                    dialogRef = null;
                });
            }
      }

      showCouponVersion(couponInfo): void {
        this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
          this.couponVersion = res['data'];
          if (this.dialogRef == null) {
            this.dialogRef = this.dialog.open(CouponDialogComponent, {
              width: '400px',
              height: 'auto',
              data: {
                coupon: this.couponVersion
              }
            });
      
            this.dialogRef.afterClosed().subscribe(result => {
              this.dialogRef = null;
            });
          }
        });
      }

  getSum(list) : number {
    let priceOriginal = 0;
    let price = 0;
    for(let i = 0; i < list.length; i++) {
      priceOriginal += list[i]['priceOriginal'];
      price += list[i]['price'];
    }
    return priceOriginal - price;
  }

}
