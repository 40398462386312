import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ProfessionalService } from '../services/professional.service';
import { RatingDialogComponent } from '../sections/rating-dialog/rating-dialog.component';
import { Utilities } from "src/app/shared/utilities/utilities";
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-professionals',
  templateUrl: './professionals.component.html',
  styleUrls: ['../../utils/mat-table.scss', './professionals.component.scss']
})
export class ProfessionalsComponent implements OnInit {

  data: any = []
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  filters: any = {};
  isUserLeroy: boolean;
  displayedColumns: string[] = ['id', 'create_date', 'professionalDetails', 'document',
    'contact_email', 'contact_phone', 'address', 'campaigns', 'fill'];
  constructor(private _professionalService: ProfessionalService, public dialog: MatDialog, private _translateService: TranslateService) {
  }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserHogami();
  }

  getProfessionals() {
    const alertMessage = this._translateService.instant('lm-professional.professionals.alertMessage');
    this.loading = true;
    this.data = []
    this.totalElements = 0;
    this._professionalService.getProfessionals(this.page, this.pageSize, this.filters).subscribe(response => {
      this.data = response.data;
      this.data.content.forEach(element => {
        if (element.professionalCampaigns != null) {
          element.professionalCampaigns.push({
            campaignId: 0,
            campaignCode: 'WEB',
            professionalId: element.id
          });
        } else {
          let professionalCampaigns = [{
            campaignId: 0,
            campaignCode: 'WEB',
            professionalId: element.id
          }]
          element['professionalCampaigns'] = professionalCampaigns;
        }
      });
      if (this.data.totalElements === 0) {
        this.loading = false;
        alert(alertMessage);
        this.filters = {};
        this.data = [];
      } else {
        this.loading = false;
        this.data = response.data.content;
        this.totalElements = response.data.totalElements
      }
    },
      err => {
        if(err.status == 400) {
          this.loading = false;
          alert(err.error.message);
          this.filters = {};
          this.data = [];
        } else {
          localStorage.removeItem('token');
        }
      });
  }

  getProfessionalsFilters() {
    this.getProfessionals();
  }

  getFiltersToProfessionals(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
    this.getProfessionals();
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getProfessionals();
  }

  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString()
    }
    return d
  }

  openRating(element) {
    let avatar = 'http://qa-hogami.leroymerlin.es/assets/img/sello-calidad.svg';
    if (!this.isEmpty(element.avatar)) {
      avatar = element.avatar;
    }
    this.dialog.open(RatingDialogComponent, {
      width: '700px',
      height: 'auto',
      maxHeight: '100vh',
      data: {
        profesionalId: element.id,
        professionaName: element.name,
        professionalLastName: element.lastName,
        totalRating: element.totalRating,
        avatar: avatar
      }
    });
  }

  isEmpty(str) {
    return (!str || str.length === 0);
  }



}
