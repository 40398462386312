import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OfferedServiceTypePipe } from './pipes/offered-service-type.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
	imports: [
		MatToolbarModule,
		MatButtonModule,
		TranslateModule
	],
	declarations: [OfferedServiceTypePipe],
	exports: [OfferedServiceTypePipe, TranslateModule]
})

export class SharedModule { }
