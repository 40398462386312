<div class="content">
<mat-card class="small-card"   *ngIf="isUserLeroy">
  <mat-card-header>
    <div></div>
    <mat-card-title>{{'lm-unban-professional.title' | translate}}</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <form (ngSubmit)="unbanProfesional(form.value)" [formGroup]="form" class="form">
    <mat-card-content>
      <div>
        <mat-form-field class="full-width">
          <input matInput formControlName="email" name="email" placeholder="{{'lm-unban-professional.placeholders.email' | translate}}" type="text"/>
        </mat-form-field>
        <div *ngIf="submitted && f.email.errors" class="redTextLeft">
          <div *ngIf="f.email.errors.required">{{'lm-unban-professional.messagesError.email_required' | translate}}</div>
          <div *ngIf="f.email.errors.email">{{'lm-unban-professional.messagesError.email_invalid' | translate}}</div>
        </div>
        <mat-form-field class="full-width">
          <input matInput formControlName="password" name="password" placeholder="{{'lm-unban-professional.placeholders.new_password' | translate}}" type="password"/>
        </mat-form-field>
        <div *ngIf="submitted && f.password.errors" class="redTextLeft">
          <div *ngIf="f.password.errors.required">{{'lm-unban-professional.messagesError.password_required' | translate}}</div>
        </div>
        <mat-form-field class="full-width">
          <mat-label>{{'lm-unban-professional.placeholders.new_status' | translate}}</mat-label>
          <mat-select formControlName="status" name="status">
            <mat-option value="ACTIVE">{{'lm-unban-professional.toggle-buttons.active' | translate}}</mat-option>
            <mat-option value="INACTIVE">{{'lm-unban-professional.toggle-buttons.inactive' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="submitted && f.status.errors" class="redTextLeft">
          <div *ngIf="f.status.errors.required">{{'lm-unban-professional.messagesError.status_required' | translate}}</div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" color="primary" [disabled]="isDisabled"><mat-icon>person</mat-icon> {{'lm-unban-professional.buttons.unban' | translate}}</button>
      <p *ngIf="isError"class="redText">{{ message }}</p>
      <p *ngIf="!isError"class="greenText">{{ message }}</p>
    </mat-card-actions>
  </form>
</mat-card>


  <mat-card class="small-card" *ngIf="!isUserLeroy">
    <mat-card-header>
      <div></div>
      <mat-card-title>{{'lm-unban-professional.title' | translate}}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="divLeroy" >
        <div class="divCenter">
          <h2>{{'lm-unban-professional.messagesError.not_authorize_perform_action' | translate}}</h2>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button type="button" (click)="goBack()" class="button_back"> {{'lm-unban-professional.buttons.accepter' | translate}} </button>
    </mat-card-actions>
  </mat-card>
</div>