<div style="display:inline !important;">

    <app-lm-filters-professional-details [filterType]="filterType" [loading]="loading"
        (filtersApply)="getFiltersToServiceCoverage($event)">
    </app-lm-filters-professional-details>
</div>

<div class="content">
    <div class="example-container ">
        <table mat-table [dataSource]='data' class="mat-elevation-z8">

            <ng-container matColumnDef="country" sticky>
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.country' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.country}}</td>
            </ng-container>

            <ng-container matColumnDef="comunity">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.community' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.community  }}</td>
            </ng-container>
            
            <ng-container matColumnDef="selectedProvince">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.provinces' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.province}}</td>
            </ng-container>

            <ng-container matColumnDef="zone">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.zone' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.zone}}</td>
            </ng-container>

            <ng-container matColumnDef="zipCode">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.zip_code' | translate}}</th>
                <td mat-cell *matCellDef="let element">{{element.zipCode}}</td>
            </ng-container>

            <ng-container matColumnDef="firstPrice" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.cost_1_und' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.firstPrice}}</td>
            </ng-container>

            <ng-container matColumnDef="additionalPrice" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.cost_additional_unit' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.additionalPrice}}</td>
            </ng-container>

            <ng-container matColumnDef="tax" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.tax' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.tax ? element.tax + ' %' : ''}}</td>
            </ng-container>

            <ng-container matColumnDef="currency" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.currency' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.currency}}</td>
            </ng-container>

            <ng-container matColumnDef="actions" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-service-coverage-details.actions' | translate}}</th>
                <td mat-cell *matCellDef="let element"> </td>
            </ng-container>




            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="getPaginatorData($event)">
        </mat-paginator>
    </div>
</div>