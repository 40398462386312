<div *ngIf="isEditInfo">
  <form action="" [formGroup]="form" enctype="multipart/form-data" (ngSubmit)="submitForm()">

    <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
    <div class="basic-data-content">
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-professional.lm-professional-basic-data.titles.professional_data' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div class="field">
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.professional_info.label_form.name' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.professional_info.label_form.name' | translate}}" 
              type="text" formControlName="name" name="name" maxlength="50">
              <mat-error *ngIf="f.name.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.professional_info.errors.name' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.professional_info.label_form.lastname' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.professional_info.label_form.lastname' | translate}}" 
              type="text" formControlName="lastName" name="lastName" maxlength="50">
              <mat-error *ngIf="f.lastName.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.professional_info.errors.lastname' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.professional_info.label_form.email' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.professional_info.label_form.email' | translate}}" 
              type="text" formControlName="email" name="email" maxlength="255">
              <mat-error *ngIf="!f.email.hasError('email') && !f.email.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.professional_info.errors.email' | translate}}
              </mat-error>
              <mat-error *ngIf="!f.email.hasError('email') && f.email.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.professional_info.errors.email' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="dropdown-div">
              <ng-multiselect-dropdown class="lm-input" [settings]="dropdownSettingLanguages"
              [placeholder]="'lm-professional.lm-professional-basic-data.professional_info.label_form.languages' | translate" [data]="professionalLanguages"
              formControlName="language" name="language">
            </ng-multiselect-dropdown>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.professional_info.label_form.slogan' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.professional_info.label_form.slogan' | translate}}" 
              type="text" formControlName="slogan" name="slogan" maxlength="255">
              <mat-error *ngIf="f.slogan.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.professional_info.errors.slogan' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.professional_info.label_form.avatar' | translate}}" 
              type="text" readonly formControlName="avatarName" name="avatarName">
              <button mat-icon-button matPrefix (click)="f_input.click()" type="button">
                <mat-icon>attach_file</mat-icon>
              </button>
              <input type="file" id="avatar" hidden placeholder="avatar" #f_input name="avatar"
                (change)="onFileSelect($event)"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-professional.lm-professional-basic-data.titles.about_company' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.about_company.label_form.prof_person_type' | translate}}</mat-label>
              <mat-select formControlName="personType" name="personType">
                <mat-option *ngFor="let personType of personTypes" [value]="personType.id">{{personType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.about_company.label_form.prof_nif_cif' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.about_company.label_form.prof_nif_cif' | translate}}" type="text" formControlName="nifcif" name="nifcif" maxlength="50">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.about_company.label_form.prof_iban' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.about_company.label_form.prof_iban' | translate}}" type="text" formControlName="iban" name="iban" maxlength="45">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-professional.lm-professional-basic-data.titles.description' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.titles.description' | translate}}</mat-label>
              <textarea matInput placeholder="{{'lm-professional.lm-professional-basic-data.titles.description' | translate}}" 
              type="text" formControlName="professionalDescription" name="professionalDescription" rows="4" maxlength="5000"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-professional.lm-professional-basic-data.titles.contact_information' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.contact_information.label_form.contact_phone' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.contact_information.label_form.contact_phone' | translate}}" 
              type="text" formControlName="phoneContact" name="phoneContact" (keypress)="onChangeOperationNumber($event)" maxlength="9">
              <mat-error *ngIf="f.phoneContact.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.contact_information.errors.contact_phone' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.contact_information.label_form.contact_email' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.contact_information.label_form.contact_email' | translate}}" 
              type="text" formControlName="emailContact" name="emailContact" maxlength="255">
              <mat-error *ngIf="!f.emailContact.hasError('email') && !f.email.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.contact_information.errors.contact_email' | translate}}
              </mat-error>
              <mat-error *ngIf="!f.emailContact.hasError('email') && f.email.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.contact_information.errors.contact_email' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4><strong>{{'lm-professional.lm-professional-basic-data.titles.addresses' | translate}}</strong></h4>
        </div>
        <div class="card-content">
          <div>
            <strong>{{'lm-professional.lm-professional-basic-data.addresses.labels.address_postal' | translate}}</strong>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.zip_code' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.zip_code' | translate}}" 
              type="text" formControlName="postalCode" name="postalCode" (blur)="blurInputPostal($event)" maxlength="5">
              <mat-error *ngIf="f.postalCode.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.zip_code' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.addressPostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.addressPostal' | translate}}" 
              type="text" formControlName="addressPostal" name="addressPostal" (blur)="blurInputPostal($event)" maxlength="255">
              <mat-error *ngIf="f.addressPostal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.addressPostal' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.complementAddressPostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.complementAddressPostal' | translate}}" 
              type="text" formControlName="complementAddressPostal" name="complementAddressPostal" maxlength="255">
              <mat-error *ngIf="f.complementAddressPostal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.complementAddressPostal' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.cityPostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.cityPostal' | translate}}" 
              type="text" formControlName="cityPostal" name="cityPostal" (blur)="blurInputPostal($event)"  maxlength="45">
              <mat-error *ngIf="f.cityPostal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.cityPostal' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.provincePostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.provincePostal' | translate}}" 
              type="text" formControlName="provincePostal" name="provincePostal" (blur)="blurInputPostal($event)" maxlength="45">
              <mat-error *ngIf="f.provincePostal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.provincePostal' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <strong>{{'lm-professional.lm-professional-basic-data.addresses.labels.address_fiscal' | translate}}</strong>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.zip_code' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.zip_code' | translate}}" 
              type="text" formControlName="fiscalCode" name="fiscalCode" (blur)="blurInputFiscal($event)" maxlength="5">
              <mat-error *ngIf="f.fiscalCode.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.zip_code' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.addressPostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.addressFiscal' | translate}}" 
              type="text" formControlName="addressFiscal" name="addressFiscal" (blur)="blurInputFiscal($event)" maxlength="255">
              <mat-error *ngIf="f.addressFiscal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.addressPostal' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.complementAddressPostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.complementAddressFiscal' | translate}}" 
              type="text" formControlName="complementAddressFiscal" name="complementAddressFiscal" maxlength="255">
              <mat-error *ngIf="f.complementAddressFiscal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.complementAddressPostal' | translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.cityPostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.cityFiscal' | translate}}" 
              type="text" formControlName="cityFiscal" name="cityFiscal" (blur)="blurInputFiscal($event)" maxlength="45">
              <mat-error *ngIf="f.cityFiscal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.cityPostal' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label>{{'lm-professional.lm-professional-basic-data.addresses.label_form.provincePostal' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-professional.lm-professional-basic-data.addresses.label_form.provinceFiscal' | translate}}" 
              type="text" formControlName="provinceFiscal" name="provinceFiscal" (blur)="blurInputFiscal($event)" maxlength="45">
              <mat-error *ngIf="f.provinceFiscal.hasError('required')">
                {{'lm-professional.lm-professional-basic-data.addresses.errors.provincePostal' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      
    </div>

    <div class="footer-data">
      <div class="section">
      </div>
      <div class="section">
      </div>
      <div class="section">
        <div *ngIf="isEditInfo">
          <button mat-raised-button color="primary" type="submit" [disabled]="loading" class="button_submit">
            <mat-icon>save</mat-icon> {{'lm-professional.lm-professional-basic-data.buttons.save' | translate}}
          </button>
          <button mat-raised-button (click)="cancelEditInfo()">
            <mat-icon>cancel</mat-icon> {{'lm-professional.lm-professional-basic-data.buttons.cancel' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="!isEditInfo && data">
  <div class="basic-data-content">

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.professional_data' | translate}}</h4>
      </div>
      <div class="card-content">
        <div><strong>{{'lm-professional.lm-professional-basic-data.professional_info.labels.name' |
            translate}}</strong>&nbsp; {{data.name}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.professional_info.labels.lastname' |
            translate}}</strong>&nbsp; {{data.lastName}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.professional_info.labels.email' |
            translate}}</strong>&nbsp; {{data.email}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.professional_info.labels.languages' |
          translate}}</strong>&nbsp;
            <div class="basic-data-content__languages">
              <div *ngFor="let item of data.professionalLanguages">
                <img title="{{item.languageName}}" class="basic-data-content__languages__icon" [src]="'data:image/png;base64,' + item.languageIconKey" >
              </div>
            </div>
          </div>
        <div *ngIf="data.professionalInformation"><strong>{{'lm-professional.lm-professional-basic-data.professional_info.labels.slogan' |
          translate}}</strong>&nbsp; {{data.professionalInformation.title}}</div>
      </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.states_campaigns' | translate}}</h4>
      </div>
      <div class="card-content">
        <div><strong>{{'lm-professional.lm-professional-basic-data.states_campaigns.labels.date_creation' |
            translate}}</strong>&nbsp; {{data.createDate | date: "yyyy-MM-dd HH:mm:ss"}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.states_campaigns.labels.general_condition.text' |
            translate}}</strong>&nbsp; {{data.available==true? _states.active : _states.inactive}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.states_campaigns.labels.campaigns' |
            translate}}</strong></div>
        <div *ngFor="let element of campaigns">
          <ng-container matColumnDef="actions">
            <div class="card-content__campaign">
              <mat-checkbox (click)="changeActive(element)" [checked]="element.active" [disabled]="element.codeCampaign == 'WEB'">
                {{element.codeCampaign}}</mat-checkbox>
                <p *ngIf="element.isHogamiDisabled"> 
                {{'lm-professional.lm-professional-basic-data.states_campaigns.labels.general_condition.disable_bo' | translate}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.about_company' | translate}}</h4>
      </div>
      <div class="card-content">
        <div><strong>{{'lm-professional.lm-professional-basic-data.about_company.labels.type' | translate}}</strong>&nbsp;
          {{data.personType?.description}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.about_company.labels.nif_cif' | translate}}</strong>&nbsp;
          {{data.additionalInfo?.document}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.about_company.labels.trade_name' |
            translate}}</strong>&nbsp;
          {{data.additionalInfo?.socialReasonName}}</div>
        <div><strong>{{'lm-professional.lm-professional-basic-data.about_company.labels.iban' | translate}}</strong>&nbsp;
          {{data.additionalInfo?.iban}}</div>
      </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.description' | translate}}</h4>
      </div>
      <div class="card-content">
        <div>{{data.professionalInformation?.description}}</div>
      </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.contact_information' | translate}}</h4>
      </div>
      <div class="card-content">
        <div><strong>{{'lm-professional.lm-professional-basic-data.contact_information.labels.tel' | translate}}
          </strong>&nbsp;
          {{data.additionalInfo?.phoneNumber}}</div>
          <div  *ngIf="data.contactEmails" ><strong>{{'lm-professional.lm-professional-basic-data.contact_information.labels.email' | translate}}
          </strong>&nbsp;{{data.contactEmails[0].email}}</div>
      </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.addresses' | translate}}</h4>
      </div>
      <div class="card-content">

          <div *ngFor="let item of data.addressRests">
            <div *ngIf="item.addressType && item.addressType.type === 'POSTAL'" style="display: flow-root;">
              <strong>{{'lm-professional.lm-professional-basic-data.addresses.labels.address_postal' | translate}}</strong>&nbsp;
              {{item.address}} {{item.complementAddress}} {{item.city}} {{item.province}} ES
            </div>
            <div *ngIf="item.addressType && item.addressType.type === 'FISCAL'" style="display: flow-root;">
              <strong>{{'lm-professional.lm-professional-basic-data.addresses.labels.address_fiscal' | translate}}</strong>&nbsp;
              {{item.address}} {{item.complementAddress}} {{item.city}} {{item.province}} ES
            </div>
          </div>
          <!-- <div><strong>{{'lm-professional.lm-professional-basic-data.addresses.labels.address_postal' | translate}}</strong>
          {{data.additionalInfo?.addressPostal}}
          {{data.additionalInfo?.complementAddressPostal}} {{data.additionalInfo?.cityPostal}}
          {{data.additionalInfo?.provincePostal}} {{data.additionalInfo?.country}}</div>
          <div><strong>{{'lm-professional.lm-professional-basic-data.addresses.labels.address_fiscal' |
              translate}}</strong>
            {{data.additionalInfo?.addressFiscal}}
            {{data.additionalInfo?.complementAddressFiscal}} {{data.additionalInfo?.zipCodeFiscal}}
            {{data.additionalInfo?.cityFiscal}} {{data.additionalInfo?.provinceFiscal}} {{data.additionalInfo?.country}}
          </div> -->
        
        </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.options' | translate}}</h4>
      </div>
      <div class="card-content">
        <mat-checkbox [checked]="data.acceptTermsData?.acceptTermsAndConditions"
          [disabled]="true">{{'lm-professional.lm-professional-basic-data.options.labels.accept_terms_conditions' |
          translate}}</mat-checkbox><br>
        <mat-checkbox [checked]="data.acceptTermsData?.acceptPrivacyPolicy"
          [disabled]="true">{{'lm-professional.lm-professional-basic-data.options.labels.accept_privacy_policy' |
          translate}}
        </mat-checkbox><br>
        <mat-checkbox [checked]="data.acceptTermsData?.acceptCommunications"
          [disabled]="true">{{'lm-professional.lm-professional-basic-data.options.labels.accept_communication' |
          translate}}
        </mat-checkbox>
      </div>
    </div>

    <div class="basic-data-content__card">
      <div class="card-title">
        <h4>{{'lm-professional.lm-professional-basic-data.titles.documentation' | translate}}</h4>
        <mat-icon aria-hidden="false" class="edit" *ngIf="isUserLeroy && isProfessionalInfo && hasDocuments" 
        (click)="openEditPaycometDocuments()" title="{{'lm-professional.lm-professional-basic-data.titles.paycomet_upload' | translate}}">upload</mat-icon>
      </div>
      <div class="card-content">
        <div *ngFor="let element of documents">
          <ng-container matColumnDef="actions">
            <mat-checkbox [checked]="element.status" [disabled]="true">
              {{element.documentName}}</mat-checkbox><br>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!hasDocuments && !dataIsLodading" class="card-content">
        <div>{{'lm-professional.lm-professional-basic-data.docs.labels.no_doc' | translate}}</div>
      </div>
    </div>

  </div>

  <div class="footer-data">
    <div class="section">
    </div>
    <div class="section">
    </div>
    <div class="section">
      <div *ngIf="!isEditInfo">
        <button mat-raised-button (click)="editInfo(data)" [disabled]="!isProfessionalInfo && !hasDocuments">
          <mat-icon>edit</mat-icon> Editar
        </button>
      </div>
    </div>
  </div>

</div>