import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service, StatusTransfer, Provinces } from '../../models/lm-transfers.model';
import { TransferService } from '../../services/transfers.service';
import { get as _get } from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { Utilities } from '../../../../shared/utilities/utilities';
import { Router } from '@angular/router';
import { ELmSharedPaths } from 'src/app/shared/models/shared.models';
export class DatepickerCustomIconExample {}
@Component({
  selector: 'app-lm-filters',
  templateUrl: './lm-filters.component.html',
  styleUrls: ['../../../utils/form-search.scss']
})
export class LmFiltersComponent implements OnInit {

  form: FormGroup;
  services: Service[] = [];
  statusFilters: StatusTransfer[] =[];
  serviceSelected: number = null;
  provinces: Provinces[] = [];
  typeId: string;
  showOperations: boolean = false;

  @Input() transferType: string;
  isUserLeroy: boolean;
  @Input() loadExcel: boolean;
  @Input() solicitudes: boolean;
  @Input() operaciones: boolean;
  @Input() dataSolic: any;
  @Input() datag: any;
  @Input() loading: boolean;
  @Output() filtersApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterstoExcel: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private _router: Router,
    private _transfer: TransferService
  ) { }

  ngOnInit() {
    this.loadServices( () => {  });
    this.loadProvinces();
    
    if(this.transferType === 'op'){
      this.typeId = ' Operacion:';
      this.showOperations = false
      this.loadOperationStatus();
    }else{
      this.typeId = ' Solicitud:';
      this.showOperations = true
      this.loadRequestStatus();
    }
    this.isUserLeroy = Utilities.isUserHogami();
    this.createForm();
  }

  OnChanges(changes) {

  }

  createForm(){
    this.form = new FormGroup( {
      dateSinceStarttime: new FormControl( '' ),
      dateUntilStarttime: new FormControl( '' ),
      operationDateStartTime: new FormControl(''),
      operationDateEndTime: new FormControl(''),
      dateSinceEndtime: new FormControl( '' ),
      dateUntilEndtime: new FormControl( '' ),
      profEmail: new FormControl( '' ),
      clientEmail: new FormControl( '' ),
      serviceSelected: new FormControl( '' ),
      provinceSelected: new FormControl( '' ),
      stateSelected: new FormControl( '' ),
      idType: new FormControl(''),
		} );
  }

  // getServiceId(event) {
	// 	this.communities.emit(event);
	// }

  resetFilters(reset: number){
    this.createForm();
    this.transferType = reset == 0 ? "op":"re";
    if(this.transferType === 'op'){
      this.typeId = ' Operacion:';
      this.showOperations = false
      this.loadOperationStatus();
    }else{
      this.typeId = ' Solicitud:';
      this.showOperations = true
      this.loadRequestStatus();
    }
    this.sendFilters();
  }

  
  sendFilters(){
    const infoFilters = {
      transferType: this.transferType,
      // operation date
      operationDateStartTime: this.form.controls[ 'operationDateStartTime' ].value !== "" ? Utilities.getDate(this.form.controls[ 'operationDateStartTime' ].value) : null,
      operationDateEndTime: this.form.controls[ 'operationDateEndTime' ].value !== "" ? Utilities.getDateEnd(this.form.controls[ 'operationDateEndTime' ].value) : null,
      // start time 
      dateSinceStarttime: this.form.controls[ 'dateSinceStarttime' ].value !== "" ? Utilities.getDate(this.form.controls[ 'dateSinceStarttime' ].value) : null,
      dateUntilStarttime: this.form.controls[ 'dateUntilStarttime' ].value !== "" ? Utilities.getDateEnd(this.form.controls[ 'dateUntilStarttime' ].value) : null,
      // end time 
      dateSinceEndtime: this.form.controls[ 'dateSinceEndtime' ].value !== "" ? Utilities.getDate(this.form.controls[ 'dateSinceEndtime' ].value) : null,
      dateUntilEndtime: this.form.controls[ 'dateUntilEndtime' ].value !== "" ? Utilities.getDateEnd(this.form.controls[ 'dateUntilEndtime' ].value) : null,
      profEmail: this.form.controls[ 'profEmail' ].value.trim() || null,
      clientEmail: this.form.controls[ 'clientEmail' ].value.trim() || null,
      serviceSelected: this.form.controls[ 'serviceSelected' ].value || 0,
      provinceSelected: this.form.controls[ 'provinceSelected' ].value || 0,
      stateSelected: this.form.controls[ 'stateSelected' ].value || 0,
      idType: this.form.controls['idType'].value || 0
    };
    this.filtersApply.emit(infoFilters);
  }

  exportToExcel(){
    const infoFilters = {
      transferType: this.transferType,
      // operation date
      operationDateStartTime: this.form.controls[ 'operationDateStartTime' ].value !== "" ? Utilities.getDate(this.form.controls[ 'operationDateStartTime' ].value) : null,
      operationDateEndTime: this.form.controls[ 'operationDateEndTime' ].value !== "" ? Utilities.getDateEnd(this.form.controls[ 'operationDateEndTime' ].value) : null,
      //start time
      dateSinceStarttime: this.form.controls[ 'dateSinceStarttime' ].value !== "" ? Utilities.getDate(this.form.controls[ 'dateSinceStarttime' ].value) : null,
      dateUntilStarttime: this.form.controls[ 'dateUntilStarttime' ].value !== "" ? Utilities.getDateEnd(this.form.controls[ 'dateUntilStarttime' ].value) : null,
      //end time
      dateSinceEndtime: this.form.controls[ 'dateSinceEndtime' ].value !== "" ? Utilities.getDate(this.form.controls[ 'dateSinceEndtime' ].value) : null,
      dateUntilEndtime: this.form.controls[ 'dateUntilEndtime' ].value !== "" ? Utilities.getDateEnd(this.form.controls[ 'dateUntilEndtime' ].value) : null,
      profEmail: this.form.controls[ 'profEmail' ].value.trim() || null,
      clientEmail: this.form.controls[ 'clientEmail' ].value.trim() || null,
      serviceSelected: this.form.controls[ 'serviceSelected' ].value || 0,
      provinceSelected: this.form.controls[ 'provinceSelected' ].value || 0,
      stateSelected: this.form.controls[ 'stateSelected' ].value || 0,
      idType: this.form.controls['idType'].value || 0
    };
    this.loadExcel = false;
    this.filterstoExcel.emit(infoFilters);
  }

  loadProvinces(){
    this._transfer.getProvinces().subscribe(
      res => {
       this.provinces = this.mergeZipCodes(res.data);
      }
    )
  }

  loadServices(callback){
    this._transfer.getSearchServices().subscribe(
			async res => {
        this.services = await res.data;
        if(callback){  callback();  }
      }
    );
  }

  loadRequestStatus(){
    this._transfer.getRequestStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    );
  }

  loadOperationStatus(){
    this._transfer.getOperationsStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    )
  }
   mergeZipCodes(d = []) {
    let arr = []
    d.map(item => {
      const index = arr.findIndex(a => a.name === item.name)
      if(index === -1){
        arr.push({name: item.name, zipCode: "" + item.zipCode})
      }else {
        arr[index] = {name: item.name, zipCode: arr[index].zipCode+"," + item.zipCode}
      }
    })
    return arr;
  }

  getPaycometOperations() {
		this._router.navigate([ 'transfers/paycomet-operations' ]);
	}

	getBilling() {
		this._router.navigate([ 'transfers/billing' ]);
	}

	getProfessionals() {
		this._router.navigate([ 'transfers/professionals' ]);
	}

	getToServies() {
		this._router.navigate([ ELmSharedPaths.OFFERED_SERVICES ]);
	}

}
