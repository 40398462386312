<div class="content">
<mat-card class="small-card">
  <mat-card-header>
    <div></div>
    <mat-card-title>{{'lm-resetpassword.title' | translate}}</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <form (ngSubmit)="reset(form.value)"  [formGroup]="form" class="form">
    <mat-card-content>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'lm-resetpassword.placeholders.email' | translate}}</mat-label>
          <input matInput formControlName="username" name="username" placeholder="{{'lm-resetpassword.placeholders.email' | translate}}" type="text"/>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{'lm-resetpassword.placeholders.new_password' | translate}}</mat-label>
          <input matInput formControlName="password" name="password" placeholder="{{'lm-resetpassword.placeholders.new_password' | translate}}" type="password"/>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" color="primary" class="button_submit" [disabled]="isDisabled"><mat-icon>password</mat-icon> {{'lm-resetpassword.button_change_password' | translate}}</button>
      <p *ngIf="isError"class="redText">{{ message }}</p>
      <p *ngIf="!isError"class="greenText">{{ message }}</p>
    </mat-card-actions>
  </form>
</mat-card>


</div>

