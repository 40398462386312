import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ProfessionalService } from '../../services/professional.service';
import { ICoverageData, IFiltersData, LevelRest, ProfessionalHierarchy, UpdateLevelCost } from '../../models/lm-professional.model';
import { LmOfferedServiceService } from './../../../../pages/lm-offered-services/services/lm-offered-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'app-lm-professional-services-hierarchy',
  templateUrl: './lm-professional-services-hierarchy.component.html',
  styleUrls: ['./lm-professional-services-hierarchy.component.scss']
})
export class LmProfessionalServicesHierarchyComponent implements OnInit, OnDestroy {

  @Input() filtersData: IFiltersData;
  professionalHierarchy: ProfessionalHierarchy[];
  levelRest: LevelRest[] = [];
  updateLevelCost: UpdateLevelCost;
  loading: boolean;

  treeControl = new NestedTreeControl<LevelRest>(node => node.levelRests);
  dataSource = new MatTreeNestedDataSource<LevelRest>();
  @Input() coverageData: ICoverageData;
  data: any = {}
  disableEdit: boolean = false;
  hierarchyNotExist: boolean; 

  constructor(
    private offeredSerService: LmOfferedServiceService,
    private _translateService: TranslateService,
    private _professionalService: ProfessionalService,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.loading = true;
    this.retrieveOfferedServiceInfo();
  }

  ngOnDestroy(): void {
  }

  retrieveOfferedServiceInfo(): void {
    this.offeredSerService.getServiceByLandingPage(this.coverageData.landingPage).then(res => {
      this.data = res.data;
      this.retrieveProfessionalHierarychies();
    });
  }

  retrieveProfessionalHierarychies(): void {
    this._professionalService.getProfessionalHierarchyPrice(this.coverageData.professionalId, Number(this.coverageData.offeredServiceId)).then(res => {
      this.professionalHierarchy = res.data.content;
      this.professionalHierarchy.forEach(item => {
        if(!item.levelRest) {
          throw new Error("no price hierarchy to display");
        }
      });

      this.levelRest.push(this.professionalHierarchy[0].levelRest);
      this.dataSource.data = this.levelRest;
      this.loading = false;
    }).catch(error => {
      this.hierarchyNotExist = true;
      this.loading = false;
    });
  }

  updateCost(event) {
    if (event == true) {
      this._snackBar.open(this._translateService.instant('lm-professional.lm-professional-services-hierarchy.updated_price'),
        this._translateService.instant("lm-professional.lm-professional-services-hierarchy.updated_price_ok"), { duration: 1500 });
    }
  }

  refreshData(): void {
    const data = this.dataSource.data;
    this.dataSource.data = null;
    this.dataSource.data = data
  }

  hasChild = (_: number, node: LevelRest) => !!node.levelRests && node.levelRests.length > 0;

}