import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ProfessionalService } from '../../services/professional.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-rating-dialog',
	templateUrl: './rating-dialog.component.html',
	styleUrls: ['./rating-dialog.component.scss']
})
export class RatingDialogComponent implements OnInit, OnDestroy {
	_translateSubscription :Subscription;
	_opinions: {[key: string]: string};
	//Left
	arrayRating = [];
	arrayOpinion = [];
	arrayRatingPuntualLeft = [];
	arrayRatingTreatmentLeft = [];
	arrayRatingCleanLeft = [];
	arrayRatingQualityLeft = [];

	//Right
	arrayRatingRight = [];
	arrayOpinionRight = [];

	starOn: string = "";
	starHalf: string = "";
	starOff: string = "";

	profesionalId: number;
	professionaName: string = '';
	professionalLastName: string;
	totalRating: number;
	avatar: string;

	averageComplete: boolean = false;
	page: number;
	totalPages: number;
	currentPage: number;

	open: boolean = true;
	mainOpinionOne: boolean = false;
	mainOpinionTwo: boolean = false;
	mainOpinionThree: boolean = false;
	mainOpinionFour: boolean = false;
	mainOpinionFive: boolean = false;

	constructor(public dialogRef: MatDialogRef<RatingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
		private _professionalService: ProfessionalService, private _translateService: TranslateService) {
			this._translateSubscription = this._translateService.get('opinions').subscribe((result: {[key: string]: string}) => {
				this._opinions = result;
			});
		 }
		ngOnDestroy(): void {
			this._translateSubscription.unsubscribe();
		}
	ngOnInit() {
		this.page = 0;
		this.totalPages = 0;
		this.currentPage = 0;
		this.profesionalId = this.data['profesionalId'];
		this.professionaName = this.data['professionaName'];
		this.professionalLastName = this.data['professionalLastName'];
		this.totalRating = this.data['totalRating'];
		this.avatar = this.data['avatar'];

		this.starOn = "/assets/img/icons/i-star-on--v2.svg";
		this.starHalf = "/assets/img/icons/i-star-half--v2.svg";
		this.starOff = "/assets/img/icons/i-star-off--v2.svg";

		if(this.totalRating > 0) {
			this.getRatingAverage(this.profesionalId);
			this.getProfessionalRatings(this.profesionalId);
		}

	}

	/**
	 * Rating General
	 */
	createStarsGeneral(value: number) {
		this.loopCreateObject(this.arrayRating, this.starOn, value);
		if (value % 1 !== 0) {
			const halfStar: object = {
				img: this.starHalf,
			};
			this.arrayRating.push(halfStar);
		}
		const restPush = 5 - value;
		this.loopCreateObject(this.arrayRating, this.starOff, restPush);
	}

	loopCreateObject(array, text: string, value: number) {
		for (let i = 0; i < Math.trunc(value); i++) {
			const star: object = {
				img: text,
			};
			array.push(star);
		}
	}

	createObject(array, value: number, text: string) {
	   this.loopCreateObject(array, this.starOn, value);
	   if (value % 1 !== 0) {
		   const halfStar: object = {
			   img: this.starHalf,
		   };
		   array.push(halfStar);
	   }
	   const restPush = 5 - value;
	   this.loopCreateObject(array, this.starOff, restPush);
   }

	openClose() {
		this.open = !this.open;
	}

	openCloseOpinionOne() {
		this.mainOpinionOne = !this.mainOpinionOne;
	}

	openCloseOpinionTwo() {
		this.mainOpinionTwo = !this.mainOpinionTwo;
	}

	openCloseOpinionThree() {
		this.mainOpinionThree = !this.mainOpinionThree;
	}

	openCloseOpinionFour() {
		this.mainOpinionFour = !this.mainOpinionFour;
	}

	openCloseOpinionFive() {
		this.mainOpinionFive = !this.mainOpinionFive;
	}

	/**
	 *Average Left
	 */

	 selectArrayLeft(value) {
		switch (value.title) {
			case this._opinions['punctuality']:
				const repeatPuntual = value.value;
				const titlePuntual = value.title;
				this.createObject(this.arrayRatingPuntualLeft, repeatPuntual, titlePuntual);
				break;
			case this._opinions['treatment']:
				const repeatTreatment = value.value;
				const titleTreatment = value.title;

				this.createObject(this.arrayRatingTreatmentLeft, repeatTreatment, titleTreatment);
				break;
			case this._opinions['cleaning']:
				const repeatClean = value.value;
				const titleClean = value.title;

				this.createObject(this.arrayRatingCleanLeft, repeatClean, titleClean);
				break;
			case this._opinions['quality']:
				const repeatQuality = value.value;
				const titleQuality = value.title;

				this.createObject(this.arrayRatingQualityLeft, repeatQuality, titleQuality);
				break;
			default:
				break;
		}
	}

	 createStarsLeft(value: number) {
		this.loopCreateObject(this.arrayRating, this.starOn, value);
		if (value % 1 !== 0) {
			const halfStar: object = {
				img: this.starHalf,
			};
			this.arrayRating.push(halfStar);
		}
		const restPush = 5 - value;
		this.loopCreateObject(this.arrayRating, this.starOff, restPush);
	}

	/**
	 * Ratings Right
	 */
	 createStarsRight(value: number) {
		 let arrayTemp = [];
		this.loopCreateObject(arrayTemp, this.starOn, value);
		if (value % 1 !== 0) {
			const halfStar: object = {
				img: this.starHalf,
			};
			arrayTemp.push(halfStar);
		}
		const restPush = 5 - value;
		this.loopCreateObject(arrayTemp, this.starOff, restPush);
		return arrayTemp;
	}

	 getDate(elem) {
		const months = this._translateService.instant('months');
		const date = new Date(elem);
		const month = date.getMonth();
		const year = date.getFullYear();
		const meses = months;

		return `${meses[month]}, ${year}`;
	}

	selectArrayRight(value) {
		let arrayRatings = [];

		switch (value.title) {
			case this._opinions['punctuality']:
				const repeatPuntual = value.value;
				const titlePuntual = value.title;
				this.createObject(arrayRatings, repeatPuntual, titlePuntual);
				break;
			case this._opinions['treatment']:
				const repeatTreatment = value.value;
				const titleTreatment = value.title;
				this.createObject(arrayRatings, repeatTreatment, titleTreatment);
				break;
			case this._opinions['cleaning']:
				const repeatClean = value.value;
				const titleClean = value.title;
				this.createObject(arrayRatings, repeatClean, titleClean);
				break;
			case this._opinions['quality']:
				const repeatQuality = value.value;
				const titleQuality = value.title;
				this.createObject(arrayRatings, repeatQuality, titleQuality);
				break;
			default:
				break;
		}
		return arrayRatings;
	}

	/**
	 * Get Average Ratings
	 * @param profesionalId 
	 */

	getRatingAverage(profesionalId: number) {
		this._professionalService.getRatingAverage(profesionalId).subscribe(data => {
			let value = data['data']['averageGeneralRating'];
			let datac = data['data'];
			this.arrayOpinion = [
				{
					title: this._opinions['punctuality'],
					value: datac['averagePunctual']
				},
				{
					title: this._opinions['treatment'],
					value: datac['averageBehavior']
				},
				{
					title: this._opinions['cleaning'],
					value: datac['averageClean']
				},
				{
					title: this._opinions['quality'],
					value: datac['averageSatisfied']
				}
			]

			this.createStarsGeneral(value);
			for (let i = 0; i < this.arrayOpinion.length; i++) {
				this.selectArrayLeft(this.arrayOpinion[i]);
			}
			this.averageComplete = true;
		},
		err => {
		  localStorage.removeItem('token');
		  this.dialogRef.close();
		});
	}

	getProfessionalRatings(profesionalId: number) {
		this._professionalService.getRatings(profesionalId, this.page).subscribe(res => {
			const response = res['data']['content'];
			this.totalPages = res['data']['totalPages'];
			this.currentPage = res['data']['number'];
			let arrayTemp = [];
			arrayTemp = [response.map((val: any) => ({
				opinion: val.opinion,
				title: val.clientName,
				date: this.getDate(val.createDate),
				generalOpinion: val.generalRating,
				arrayOpinion: [
					{
						title: this._opinions['punctuality'],
						value: val.punctual
					},
					{
						title: this._opinions['treatment'],
						value: val.behavior
					},
					{
						title: this._opinions['cleaning'],
						value: val.clean
					},
					{
						title: this._opinions['quality'],
						value: val.satisfied
					}
				]
			}))];
			arrayTemp[0].forEach(item => {
				this.arrayOpinionRight.push(item);
			})
			
			let opinions = [];
			this.arrayOpinionRight.forEach(x => {
				x['ratingStars'] = []
				x['ratingStars'] = this.createStarsRight( x['generalOpinion']);
				opinions = x['arrayOpinion'];
				let  stars = [];
				opinions.forEach(element => {
					stars.push(this.selectArrayRight(element));
				});
				x['stars'] = stars;
			});
		},
		err => {
		  localStorage.removeItem('token');
		  this.dialogRef.close();
		});
	}

	/**
	 * Show next paginate
	 */
	showMore() {
		this.page++;
		this._professionalService.getRatings(this.profesionalId, this.page).subscribe(res => {
			const response = res['data']['content'];
			this.totalPages = res['data']['totalPages'];
			this.currentPage = res['data']['number'];
			let arrayTemp = [];
			arrayTemp = [response.map((val: any) => ({
				opinion: val.opinion,
				title: val.clientName,
				date: this.getDate(val.createDate),
				generalOpinion: val.generalRating,
				arrayOpinion: [
					{
						title: this._opinions['punctuality'],
						value: val.punctual
					},
					{
						title: this._opinions['treatment'],
						value: val.behavior
					},
					{
						title: this._opinions['cleaning'],
						value: val.clean
					},
					{
						title: this._opinions['quality'],
						value: val.satisfied
					}
				]
			}))];
			this.arrayOpinionRight = [];
			arrayTemp[0].forEach(item => {
				this.arrayOpinionRight.push(item);
			})
			
			let opinions = [];
			this.arrayOpinionRight.forEach(x => {
				x['ratingStars'] = []
				x['ratingStars'] = this.createStarsRight( x['generalOpinion']);
				opinions = x['arrayOpinion'];
				let  stars = [];
				opinions.forEach(element => {
					stars.push(this.selectArrayRight(element));
				});
				x['stars'] = stars;
			});

		},
		err => {
		  localStorage.removeItem('token');
		  this.dialogRef.close();
		});
	}

	showLess() {
		this.page--;
		this._professionalService.getRatings(this.profesionalId, this.page).subscribe(res => {
			const response = res['data']['content'];
			this.totalPages = res['data']['totalPages'];
			this.currentPage = res['data']['number'];
			let arrayTemp = [];
			arrayTemp = [response.map((val: any) => ({
				opinion: val.opinion,
				title: val.clientName,
				date: this.getDate(val.createDate),
				generalOpinion: val.generalRating,
				arrayOpinion: [
					{
						title: this._opinions['punctuality'],
						value: val.punctual
					},
					{
						title: this._opinions['treatment'],
						value: val.behavior
					},
					{
						title: this._opinions['cleaning'],
						value: val.clean
					},
					{
						title: this._opinions['quality'],
						value: val.satisfied
					}
				]
			}))];
			this.arrayOpinionRight = [];
			arrayTemp[0].forEach(item => {
				this.arrayOpinionRight.push(item);
			})
			
			let opinions = [];
			this.arrayOpinionRight.forEach(x => {
				x['ratingStars'] = []
				x['ratingStars'] = this.createStarsRight( x['generalOpinion']);
				opinions = x['arrayOpinion'];
				let  stars = [];
				opinions.forEach(element => {
					stars.push(this.selectArrayRight(element));
				});
				x['stars'] = stars;
			});
		},
		err => {
		  localStorage.removeItem('token');
		  this.dialogRef.close();
		});
	}

}
