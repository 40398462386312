<div style="display:inline !important;">
  <app-lm-filters-clients 
  [loading]="loading"
  (filtersApply)="getFiltersToClients($event)">    
  </app-lm-filters-clients>
</div>

<div class="content">
  <div class="example-container ">
    <mat-table  [dataSource]="clients">
      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.id' | translate}}</th>
        <td mat-cell *matCellDef="let element"><a [routerLink]="['/clients/', element.id]"><strong>{{element.id}}</strong><br /></a></td>
      </ng-container>
      <ng-container matColumnDef="create_date">
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.date_release' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{getDateWithTimeZone(element.createDate)}} </td>
      </ng-container>
      <ng-container matColumnDef="clientDetails">
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.client' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.name }} {{element.lastName}}</td>
      </ng-container>
      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.document' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.documentNumber }} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.email' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.email }} </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.phone' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.phones">
            <p>{{p?.countryCode}} {{p?.number}}</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef>{{'lm-client.clients.cells.status' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{ formatStatusClient(element.isActive) }} </td>
      </ng-container>
      <ng-container matColumnDef="fill">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
    <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="getPaginatorData($event)">
    </mat-paginator>
  </div>
</div>
