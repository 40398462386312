import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransferService } from '../../services/transfers.service';

@Component({
  selector: 'app-chats-dialog',
  templateUrl: './chats-dialog.component.html',
  styleUrls: ['./chats-dialog.component.scss', '../../../utils/mat-table.scss']
})
export class ChatsDialogComponent implements OnInit, OnDestroy {
  
  _translationSubscription: Subscription;
  _messages: string;
  _client_professional: { [key: string]: string };
  chats: any;
  chatId: string;
  professional: string;
  client: string;
  loadData: boolean;

  displayedColumns: string[] = ['message'];

  constructor(
    private _transfer: TransferService,
    public dialogRef: MatDialogRef<ChatsDialogComponent>,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: string) {
      this._translationSubscription = this._translateService
      .get('lm-transfers.sections.chats-dialog.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res['conversation_not_found'];
        this._client_professional = {"client": res['client'], "professional": res['professional']};
      });
     }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    this.loadData = true;
    this.chatId = this.data['chatId'];
    this.professional = this.data['professional'];
    this.client = this.data['client'];
    this.showChats();
  }

  showChats() {
    this._transfer.findAllMessagesByChatId(this.data['chatId']).subscribe(res => {
      if (res['data']['messages']) {
        this.chats = res['data']['messages'].filter(message => message.type != "CONFIRM_WORK");
      }
      this.loadData = false;
    }, error => {
      this.dismiss();
      alert(this._messages);
    });
  }

  refreshChat() {
    this.loadData = true;
    this.chats = [];
    this.showChats();
  }

  dismiss() {
    this.dialogRef.close();
  }

}
