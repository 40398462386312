<div style="display:inline !important;">
  <app-lm-filters-professionals
          [loading]="loading"
          (filtersApply)="getFiltersToProfessionals($event)"
  ></app-lm-filters-professionals>
</div>

<div class="content">
  <div class="example-container ">
    <mat-table  [dataSource]="data">
      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.id' | translate}}</th>
        <td mat-cell *matCellDef="let element"><a [routerLink]="['/professionals/', element.id]"><strong>{{element.id}}</strong><br /></a></td>
      </ng-container>

      <ng-container matColumnDef="professionalDetails">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.professional' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="professional_name">{{element.name}} {{element.lastName}}</span>
            <span *ngIf="element.available"  matTooltipPosition="right" matTooltip="{{'lm-professional.professionals.icons.active' | translate}}" >
              <i  class="material-icons">visibility</i>
            </span>
            <span *ngIf="!element.available" matTooltipPosition="right" matTooltip="{{'lm-professional.professionals.icons.inactive' | translate}}" >
              <i  class="material-icons">visibility_off</i>
            </span>
          </div>
          <div class="professional_email">{{element.email}}</div>
          <div class="professional_type"><strong>{{'lm-professional.professionals.cells.tipo' | translate}}</strong> {{element.personType}}</div>
          <div class="professional_iban"><strong>{{'lm-professional.professionals.cells.ban' | translate}}</strong> {{element.accountNumber}}</div>

        </td>
      </ng-container>

      <ng-container matColumnDef="create_date">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.date_release' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{getDateWithTimeZone(element.createDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.document' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.documentTypes">
            <p>{{p?.documentType}} {{p?.documentNumber}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_email">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.email_contact' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.contactEmails">
            <p>{{p?.email}}</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="contact_phone">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.tel_contact' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.contactphones">
            <p>{{p?.telephone}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.address' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.addresses">
            <div>{{p.address}}</div>
            <div>{{p.zipCode}}</div>
            <div *ngIf="p?.city">{{p?.city}}</div>
            <div *ngIf="p?.province">{{p?.province}}</div>
            <div>&nbsp;</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="campaigns" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-professional.professionals.cells.campaign' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.professionalCampaigns">
            <p>{{p?.campaignCode}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="fill">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
    <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="getPaginatorData($event)">
    </mat-paginator>
  </div>
</div>