<div>
  <button class="close" mat-raised-button style="float:right" (click)="dismiss()"><mat-icon>close</mat-icon></button>
  <button mat-raised-button style="float:right" (click)="refreshChat()" title="{{'lm-transfers.sections.chats-dialog.btn_refresh' | translate}}"><mat-icon>refresh</mat-icon></button>
</div>

<form class="">
  <mat-form-field class="middle_width">
    <input matInput placeholder="{{'lm-transfers.sections.chats-dialog.palceholders.chat_id' | translate}}" type="text" id="chatId" name="chatId" readonly="true" value="{{chatId}}">
  </mat-form-field>
  <mat-form-field class="middle_width">
    <input matInput placeholder="{{'lm-transfers.sections.chats-dialog.palceholders.client_email' | translate}}" type="text" id="chatIdProfessional" name="chatId" readonly="true" value="{{client}}">
  </mat-form-field>
  <mat-form-field class="middle_width">
    <input matInput placeholder="{{'lm-transfers.sections.chats-dialog.palceholders.professional_email' | translate}}" type="text" id="chatIdClient" name="chatId" readonly="true" value="{{professional}}">
  </mat-form-field>
</form>
<mat-progress-bar *ngIf="loadData" mode="indeterminate" value="40"></mat-progress-bar>

<table mat-table [dataSource]='chats' *ngIf="!loadData" class="chat-history mat-elevation-z8">

  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef  class="chat_header">
     <div class="chat_client_text">{{'lm-transfers.sections.chats-dialog.client' | translate}}</div>
     <div class="chat_pro_text">{{'lm-transfers.sections.chats-dialog.professional' | translate}}</div>
    </th>
    <td mat-cell *matCellDef="let element" class="chat_{{element.type| lowercase}}">
      <div class="sender_{{element.sender === professional ? 'pro' : 'cli'}}">
        <div class="message-data">
          <span class="message-data-time" >{{element.sent | date :'short' }}</span> &nbsp; &nbsp;
          <span class="message-data-name" >{{element.sender === professional ? _client_professional.professional : _client_professional.client}}</span>
        </div>

        <div [ngSwitch]="element.type">
          <div class="message  message_{{element.sender === professional ? 'pro' : 'cli'}}">
          <ng-container *ngSwitchCase="'TEXT'">
              <span class="message_text">{{element.message === 'null' ? '' : element.message}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'PHOTO_CLIENT'">
              <img class="chat_picture" src="{{element.message}}" alt="Foto" />
          </ng-container>
          <ng-container *ngSwitchCase="'MEDIA_GALLERY'">
            <img class="chat_picture" src="{{element.message}}" alt="Foto" />
          </ng-container>
          <ng-container *ngSwitchCase="'DATE'">
              <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.date_time_of_job' | translate}}</div>
              <div class="message_text">{{element.message === 'null' ? '' : element.message | date: 'yyyy/MM/dd hh:mm a'}}</div>
          </ng-container>
            <ng-container *ngSwitchCase="'COMPANY_ADVISE'">
              <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.hogami_informs' | translate}}</div>
              <div class="message_text" [innerHTML]="element.message === 'null' ? '' : element.message"></div>
            </ng-container>
            <ng-container *ngSwitchCase="'COMPANY_WARNING'">
              <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.hogami_informs' | translate}}</div>
              <div class="message_text" [innerHTML]="element.message === 'null' ? '' : element.message"></div>
            </ng-container>
            <ng-container *ngSwitchCase="'COMPANY_CAUTION'">
              <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.hogami_caution' | translate}}</div>
              <div *ngFor="let value of element.messages" >
                <div class="message_text" [innerHTML]="value"></div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'COMPANY_ADVICE'">
              <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.hogami_informs' | translate}}</div>
              <div *ngFor="let value of element.messages" >
                <div class="message_text" [innerHTML]="value"></div>
              </div>
            </ng-container>
          <ng-container *ngSwitchCase="'ADDRESS'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.address_work' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'DONE'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.confirm_work_done' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'BUDGET'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.budget' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'FINAL_BUDGET'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.budget_final' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'PAID'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.payment_of_work' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
            <div class="message_text">{{'lm-transfers.sections.chats-dialog.messages_title.iva_igic_inclu' | translate}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'ADD_SERVICE'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.extra_added_services' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
            <div class="message_text">{{'lm-transfers.sections.chats-dialog.messages_title.iva_igic_inclu' | translate}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'ADD_PRODUCT'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.extra_product_added' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
            <div class="message_text">{{'lm-transfers.sections.chats-dialog.messages_title.iva_igic_inclu' | translate}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'PAID_WITH_DELAY'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.delayed_payement' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
          </ng-container>
          <ng-container *ngSwitchCase="'CANCEL'">
            <div class="title">{{'lm-transfers.sections.chats-dialog.messages_title.service_canceled' | translate}}</div>
            <div class="message_text">{{element.message === 'null' ? '' : element.message}}</div>
          </ng-container>
          <ng-container *ngSwitchDefault>
              <span class="message_text">{{element.message === 'null' ? '' : element.message}}</span>
          </ng-container>
          </div>

        </div>
<!--

        <div *ngIf="element.message.startsWith('data:image'); else text">
          <div class="message  message_{{element.sender === professional ? 'pro' : 'cli'}}">
            <img class="chat_picture" src="{{element.message}}" alt="Foto" />
          </div>
        </div>
        <ng-template #text class="message">
          <div class="message message_{{element.sender === professional ? 'pro' : 'cli'}}">
            <span class="message_text">{{element.message === 'null' ? '' : element.message}}</span>
          </div>
        </ng-template>
-->



      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>