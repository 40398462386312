import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LmTransferSepaComponent } from './component/lm-transfer-sepa.component';

const routes: Routes = [
    {
        path: '',
        component: LmTransferSepaComponent
    }
];

@NgModule( {
    imports: [ RouterModule.forChild( routes ) ],
    exports: [ RouterModule ]
} )

export class LmTransferSepaRoutingModule {}
