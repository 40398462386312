<div class="content">
  <div class="grid-container">
    <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">
      <!--Mini Cards-->
      <mat-grid-tile  [colspan]="( cardLayout | async )?.miniCard.cols"  [rowspan]="( cardLayout | async )?.miniCard.rows">
        <app-card title="{{'home.titles.appointments_x_service_n' | translate}}">
          <div>
            <mat-progress-bar *ngIf="loadingApp" mode="indeterminate"  class="home-progress-bar"  value="40"></mat-progress-bar>
            <mat-table  mat-table [dataSource]='appointmentsByService' class=" mini-card-table small-table" >
              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef>{{'home.appointments_x_service_n.services' | translate}}</th>
                <td mat-cell *matCellDef="let element"><strong>{{element.service}}</strong></td>
              </ng-container>
              <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef>{{'home.appointments_x_service_n.quantity' | translate}}</th>
                <td mat-cell *matCellDef="let element"><strong>{{element.count}}</strong></td>
              </ng-container>

              <ng-container  matColumnDef="value" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'home.appointments_x_service_n.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"  class="currency">{{getLocalNumber(element.value)}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsAppSer; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsAppSer;"></tr>
            </mat-table>
          </div>
        </app-card>

      </mat-grid-tile>
      <mat-grid-tile  [colspan]="( cardLayout | async )?.miniCard.cols"  [rowspan]="( cardLayout | async )?.miniCard.rows">
        <app-card title="{{'home.titles.quotes_x_states_n' | translate}}" >
          <div>
            <mat-progress-bar *ngIf="loadingApp" mode="indeterminate"  class="home-progress-bar"  value="40"></mat-progress-bar>
            <mat-table  mat-table [dataSource]='appointmentsByStatus' class=" mini-card-table small-table" >
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>{{'home.quotes_x_states_n.states' | translate}}</th>
                <td mat-cell *matCellDef="let element"><strong>{{element.status}}</strong></td>
              </ng-container>
              <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef>{{'home.quotes_x_states_n.quantity' | translate}}</th>
                <td mat-cell *matCellDef="let element"><strong>{{element.count}}</strong></td>
              </ng-container>

              <ng-container matColumnDef="value" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'home.quotes_x_states_n.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"  class="currency">{{getLocalNumber(element.value)}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsAppS; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsAppS;"></tr>
            </mat-table>
          </div>
        </app-card>

      </mat-grid-tile>
      <mat-grid-tile  [colspan]="( cardLayout | async )?.miniCard.cols"  [rowspan]="( cardLayout | async )?.miniCard.rows">
        <app-card title="{{'home.titles.op_facto_x_service_n-1' | translate}}">
          <div>
            <mat-progress-bar *ngIf="loadingOp" mode="indeterminate"  class="home-progress-bar"  value="40"></mat-progress-bar>
            <mat-table  mat-table [dataSource]='billingOperationsByService' class=" mini-card-table small-table" >
              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef>{{'home.op_facto_x_service_n-1.services' | translate}}</th>
                <td mat-cell *matCellDef="let element"><strong>{{element.service}}</strong></td>
              </ng-container>
              <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef>{{'home.op_facto_x_service_n-1.quantity' | translate}}</th>
                <td mat-cell *matCellDef="let element"><strong>{{element.count}}</strong></td>
              </ng-container>

              <ng-container matColumnDef="value" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'home.op_facto_x_service_n-1.value' | translate}}</th>
                <td mat-cell *matCellDef="let element"  class="currency">{{getLocalNumber(element.value)}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsBOSer; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsBOSer;"></tr>
            </mat-table>
          </div>
        </app-card>

      </mat-grid-tile>
      <mat-grid-tile  [colspan]="( cardLayout | async )?.miniCard.cols"  [rowspan]="( cardLayout | async )?.miniCard.rows">
        <app-card title="{{'home.titles.op_facto_x_states_n-1' | translate}}" >
          <div>
          <mat-progress-bar *ngIf="loadingOp" mode="indeterminate"  class="home-progress-bar"  value="40"></mat-progress-bar>
          <mat-table  mat-table [dataSource]='billingOperationsByStatus' class=" mini-card-table small-table" >
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>{{'home.op_facto_x_states_n-1.states' | translate}}</th>
              <td mat-cell *matCellDef="let element"><strong>{{element.status}}</strong></td>
            </ng-container>
            <ng-container matColumnDef="count">
              <th mat-header-cell *matHeaderCellDef>{{'home.op_facto_x_states_n-1.quantity' | translate}}</th>
              <td mat-cell *matCellDef="let element"><strong>{{element.count}}</strong></td>
            </ng-container>

            <ng-container matColumnDef="value" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'home.op_facto_x_states_n-1.value' | translate}}</th>
              <td mat-cell *matCellDef="let element"  class="currency">{{getLocalNumber(element.value)}}</td>
            </ng-container>

             <tr mat-header-row *matHeaderRowDef="displayedColumnsBOS; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsBOS;"></tr>
          </mat-table>
          </div>
        </app-card>

      </mat-grid-tile>
      <!--Tables-->
      <mat-grid-tile [colspan]="( cardLayout | async )?.chart.cols" [rowspan]="( cardLayout | async )?.chart.rows">
        <app-card title="{{'home.titles.quotes_of_the_day_n' | translate}}">
          <mat-progress-bar *ngIf="loadingApp" mode="indeterminate"  class="home-progress-bar" value="40"></mat-progress-bar>
          <mat-table  mat-table [dataSource]='appointmentsData' class=" small-table" >
            <ng-container matColumnDef="id" sticky>
              <th mat-header-cell *matHeaderCellDef>{{'home.quotes_of_the_day_n.id' | translate}}</th>
              <nav>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/work-details', element.requestId]"><strong>{{'home.quotes_of_the_day_n.op' | translate}} {{element.id}}</strong><br /></a>{{'home.quotes_of_the_day_n.sol' | translate}} {{element.requestId}}
                </td>
              </nav>
              <td mat-footer-cell *matFooterCellDef> {{'home.quotes_of_the_day_n.total' | translate}} {{getCountApp()}} </td>
            </ng-container>

            <ng-container matColumnDef="operationStatus" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'home.quotes_of_the_day_n.status' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div class="status_name status_{{ element.operationStatus?.name | lowercase}}">{{element.operationStatus?.name}} </div>
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>

            <ng-container matColumnDef="totalCost" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'home.quotes_of_the_day_n.total_cost' | translate}}</th>
              <td mat-cell *matCellDef="let element"> {{getLocalNumber(element.totalCost)}} </td>
              <td mat-footer-cell *matFooterCellDef> {{getLocalNumber(getTotalCostApp())}} </td>
            </ng-container>


            <ng-container matColumnDef="operationOfferedServices" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'home.quotes_of_the_day_n.services' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div *ngFor="let p of element.operationOfferedServices ; first as isFirst">
                  <span *ngIf="isFirst">
                  <div><span class="service_code">{{p?.code}}</span> </div>
                  <div><span class="service_name">{{p?.title}}</span></div>
                </span>
                </div>
              </td>
              <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsAppointmentsTable; sticky: true" ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsAppointmentsTable;"></tr>
            <tr mat-footer-row class="sticky-footer" *matFooterRowDef="displayedColumnsAppointmentsTable sticky: true"></tr>
          </mat-table>

        </app-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.chart.cols" [rowspan]="( cardLayout | async )?.chart.rows">
        <app-card title="{{'home.titles.op_facturables_n-1' | translate}}">
          <mat-progress-bar *ngIf="loadingOp" mode="indeterminate"  class="home-progress-bar"  value="40"></mat-progress-bar>
          <mat-table  mat-table [dataSource]='data' class=" small-table" >
            <ng-container matColumnDef="id" sticky>
              <th mat-header-cell *matHeaderCellDef>{{'home.op_facturables_n-1.id' | translate}}</th>
              <nav>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/work-details', element.requestId]"><strong>Op: {{element.operationId}}</strong><br /></a>
                </td>
              </nav>
            <td mat-cell *matCellDef="let element"><strong>{{'home.op_facturables_n-1.op' | translate}} {{element.operationId}}</strong></td>
            <td mat-footer-cell *matFooterCellDef> {{'home.op_facturables_n-1.total' | translate}} {{getCountBOp()}} </td>
            </ng-container>

            <ng-container matColumnDef="totalPaid" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'home.op_facturables_n-1.total_paid' | translate}}</th>
              <td mat-cell *matCellDef="let element"  class="currency">{{getLocalNumber(element.paymentClient)}}</td>
              <td mat-footer-cell *matFooterCellDef> {{getLocalNumber(getTotalCostBOp())}} </td>

            </ng-container>

            <ng-container matColumnDef="operationOfferedServices">
              <th mat-header-cell *matHeaderCellDef>{{'home.op_facturables_n-1.services' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div *ngFor="let p of element.offeredServiceOperationRest ; first as isFirst">
                  <span *ngIf="isFirst">
                      <div><span class="service_code">{{p?.code}}</span></div>
                      <div><span class="service_name">{{p?.title}}</span></div>
                  </span>
                </div>
              </td>
              <td mat-footer-cell *matFooterCellDef>  </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsOperationsTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsOperationsTable;"></tr>
            <tr mat-footer-row class="sticky-footer" *matFooterRowDef="displayedColumnsOperationsTable sticky: true"></tr>
          </mat-table>

        </app-card>
      </mat-grid-tile>

      <!--News Iframe-->
      <mat-grid-tile [colspan]="( cardLayout | async )?.table.cols" [rowspan]="( cardLayout | async )?.table.rows">
        <app-card title="{{'home.titles.nogami_news' | translate}}">
          <div class="iframe_container">
            <iframe class="responsive-iframe" src="https://storage.googleapis.com/proimagesingapur/backoffice/news_feed.html" frameBorder="0" scrolling="yes"></iframe>
          </div>
        </app-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

</div>