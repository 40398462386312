import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { TransferService } from '../../services/transfers.service';
import * as FileSaver from 'file-saver';
import { Utilities } from '../../../../shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-operations',
  templateUrl: './paycomet-operations.component.html',
  styleUrls: ['../../../utils/mat-table.scss']
})

export class PaycometOperationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  page: number;
  totalElements: number;
  pageSize: number;
  loading: boolean = false;
  loadExcel: boolean = false;
  filters: string;
  type: string;
  stringShowMore: string;
  buttonShowMore: boolean;
  data;
  displayedColumns: string[] = ['operacionInterna', 'id', 'operationStatus',
    'startTime', 'endTime', 'totalCost', 'totalPaid',
    'emailClient', 'emailProfesional','offeredService', 'productOffered', 'paycometId', 'paycometTimeStamp',
    'paycomentReference', 'paycometOperationName',
    'paycometAmmountDisplay', 'paycometCommission', 'paycometConcept', 'campaigns', 'storeName', 'comercialId', 'fill'
  ];

  constructor(
    private _transfer: TransferService, private _translateService: TranslateService) {
      this._translationSubscription = this._translateService
      .get('lm-transfers.paycomet-operations.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
     }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.pageSize = 10;
    this.page = 0;
    this.totalElements = 0;
    this.stringShowMore = this._messages['see_all'];
    this.buttonShowMore = false;
  }

  getAllPaymentsOperation() {
    this.loading = true;
    this.type = 'all';
    this._transfer.getAllPaycometOperation(this.page, this.pageSize).pipe(first()).subscribe(res => {
      this.data = undefined;
      if (this.data === undefined) {
        this.loading = false;
        this.data = res['data']['content'];
        this.totalElements = res['data']['totalElements'];
      } else {
        this.loading = false;
        alert(this._messages['no_result_found']);
      }
    },
      err => {
        localStorage.removeItem('token');
      });
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    if (this.type === 'all') {
      this.getAllPaymentsOperation();
    } else {
      this.filtersToPaymentsOperation();
    }
  }

  getFiltersToPaymentsOperation(filtersApply) {
    this.type = 'filter';
    this.filters = filtersApply;
    this.loading = true;
    this.paginator.firstPage();
    this.filtersToPaymentsOperation();
  }

  filtersToPaymentsOperation() {
    this.loading = true;
    this.data = [];
    this.totalElements = 0;
    this._transfer.getFiltersPaycometOperation(this.page, this.filters, this.pageSize).subscribe(async res => {
      if (res.data.totalElements === 0) {
        this.loading = false;
        alert(this._messages['no_result_found']);
        this.data = []
      } else {
        this.loading = false;
        this.data = res['data']['content'];
        this.totalElements = res['data']['totalElements'];
      }
    },
      err => {
        localStorage.removeItem('token');
      });
  }

  exportToExcel(filters) {
    this.loadExcel = true;
    this._transfer.exportPaycometOperationToExcel(filters).subscribe(async res => {
      const blob = new Blob([Utilities.base64toBlob(res.excel, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
      FileSaver.saveAs(blob, ('ReportBackoffice_'.concat(Utilities.getDateActual().concat('.xls'))));
      this.loadExcel = false;
    },
      error => {
        localStorage.removeItem('token');
      }
    );
  }

  showMore() {
    this.buttonShowMore ? this.stringShowMore = this._messages['see_all'] : this.stringShowMore = this._messages['hide_all'];
    this.buttonShowMore = !this.buttonShowMore;
  }
  changeStatus(element: any) {
    this._transfer.updateStatusOperationPaycomet(element).subscribe(res => {
      if (res['status'] === 'Error') {
        this.loading = true;
        this.getAllPaymentsOperation();
        alert(this._messages['the_status_could_not_be_changed']);
      } else if(res['status'] === "Success"){
        this.type === "all" ? this.getAllPaymentsOperation():this.filtersToPaymentsOperation()
      }
    },
      err => {
        this.loading = true;
        this.getAllPaymentsOperation();
        alert(this._messages['the_status_could_not_be_changed']);
      });
  }

  getDateWithTimeZone(d) {
		if(d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
			return new Date(time - offset * 60000).toLocaleString()
		}
		return d
	}
}
