<div class="content">
  <mat-expansion-panel  [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'lm-transfers.sections.lm-filters-paycomet.title' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form action="" [formGroup]="form">
      <div class="filters_form_group">
        <label style="cursor: default;">{{'lm-transfers.sections.lm-filters-paycomet.transaction_date' | translate}} </label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-paycomet.placeholders.date_from' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.date_from' | translate}}" formControlName="startTransaction" name="startTransaction">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-paycomet.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker2" placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.date_to' | translate}}" formControlName="endTransaction" name="endTransaction">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="filters_form_group">
        <br>
        <div>
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-paycomet.placeholders.operation_id' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.operation_id' | translate}}" (keypress)="onChangeOperactionId($event)" type="text" formControlName="operationId" name="operationId">
          </mat-form-field>
        </div>
        <br>        
        <div>
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-paycomet.placeholders.commercial_id_ldap' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.commercial_id_ldap' | translate}}" type="text" formControlName="commercialId" name="commercialId" maxlength="10" (keypress)="onChangePhoneClent($event)">
          </mat-form-field>
        </div>
      </div>

      <div class="filters_form_group">
        <br>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.transaction_type' | translate}}" [data]="transactionTypes"
              formControlName="transactionType" name="transactionType" [settings]="dropdownTransactionTypes">
            </ng-multiselect-dropdown>
        </div>
        <br>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.campaign' | translate}}" [data]="campaigns"
            formControlName="campaignSelected" name="campaignSelected" [settings]="dropdownSettingCampaigns">
          </ng-multiselect-dropdown>
        </div>
        <br>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-paycomet.placeholders.stores' | translate}}" [data]="stores"
            formControlName="storeSelected" name="storeSelected" [settings]="dropdownSettingStores">
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="button-row">
        <button mat-raised-button [disabled]="loadExcel || loading" color="primary" type="submit" (click)="sendFilters()" class="button_submit"><mat-icon>search</mat-icon> {{'lm-transfers.sections.lm-filters-paycomet.buttons.search' | translate}}</button>
        <button mat-raised-button [disabled]="loadExcel || loading" type="reset"  (click)="resetFilters()">{{'lm-transfers.sections.lm-filters-paycomet.buttons.clean' | translate}}</button>
        <button [disabled]="loadExcel || loading" mat-raised-button (click)="exportToExcel()" style="float:right"><mat-icon>file_open</mat-icon> {{'lm-transfers.sections.lm-filters-paycomet.buttons.export_excel' | translate}}</button>
      </div>
    </form>
  </mat-expansion-panel>
  <mat-progress-bar *ngIf="loadExcel || loading" mode="indeterminate" value="40"></mat-progress-bar>
</div>