import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonType, ProfessionalCampaigns } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { ConfirmDialogComponent } from 'src/app/pages/modals/confirm-dialog/confirm-dialog.component';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { IRatingData, LanguageProfessional, ProfessionalBasicData } from '../../models/lm-professional.model';
import { ProfessionalService } from '../../services/professional.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-professional-basic-data',
  templateUrl: './lm-professional-basic-data.component.html',
  styleUrls: ['./lm-professional-basic-data.component.scss', '../../../utils/dropdown.scss']
})
export class LmProfessionalBasicDataComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  professionalId;
  loading: boolean = false;
  data: ProfessionalBasicData;
  documents: any[];
  campaigns: ProfessionalCampaigns[] = [];
  displayedColumns: string[] = ['nombre', 'fill', 'actions'];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  professionalName: string = '';
  isUserLeroy: boolean;
  isEnabled: boolean = false;
  hasDocuments: boolean = false;
  dataIsLodading: boolean = false;
  ratingData: IRatingData;
  _states: { [key: string]: string };
  isEditInfo: boolean;
  isProfessionalInfo: boolean;
  isDisbledEditInfo: boolean;
  personTypes: PersonType[] = [];
  professionalLanguages: LanguageProfessional[] = [];
  dropdownSettingLanguages = {};

  @Output() editPaycometDocumentsEvent = new EventEmitter<boolean>();

  file_store: FileList;
  display: FormControl = new FormControl("", Validators.required);
  form: FormGroup

  constructor(
    private _route: ActivatedRoute,
    private _professionalService: ProfessionalService,
    private _transferService: TransferService,
    public dialog: MatDialog,
    private _translateService: TranslateService,
  ) {
    this._translateSubscription = this._translateService.get('lm-professional.lm-professional-basic-data.states_campaigns.labels.general_condition').subscribe((result: { [key: string]: string }) => {
      this._states = result;
    });
  }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }
  ngOnInit() {
    this.isUserLeroy = Utilities.isUserLeroy();
    this.professionalId = this._route.snapshot.paramMap.get("professionalId");
    this.getProfessionalById(this.professionalId);
    this.getProfessionalDocuments(this.professionalId);

  }

  openEditPaycometDocuments() {
    this.editPaycometDocumentsEvent.emit(true);
  }

  /**
   * Get all person types
   */
  getPersonTypes() {
    this._professionalService.retrievePersonTypes().subscribe(res => {
      this.personTypes = res['data'];
    })
  }

  /**
   * Get all Languages
   */
  getLanguages() {
    this._professionalService.retrieveLanguages(this.professionalId).subscribe(res => {
      this.professionalLanguages = res['data'];
    })
  }

  /**
   * Get Professional Information by ID
   * @param professionalId 
   */
  getProfessionalById(professionalId: number) {
    this.loading = true
    this._professionalService.getProfessionalById(professionalId).then(res => {
      this.data = res.data;
      this.professionalName = `${this.data.name} ${this.data.lastName}`;
      this.loading = false;
      if (this.data.professionalCampaigns != null) {
        this.getCampaigns();
        this.isProfessionalInfo = true;
      }

    }).catch(err => {
      this.loading = false
    })
  }

  /**
   * Get All Campaigns
   */
  getCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data.map(element => {
        return {
          ...element,
          active: false,
          enabled: false,
          isHogamiDisabled: false
        };
      });
      this.campaigns.push({
        id: 0,
        descriptionCampaign: '',
        codeCampaign: 'WEB',
        active: true,
        enabled: true,
        isHogamiDisabled: false
      });
      this.campaigns.sort((a, b) => a.id - b.id);
      this.matchCampaignsWithProfessionals();
    });
  }

  /**
   * Get All Professional Documents by ProfessionalId
   * @param professionalId 
   */
  getProfessionalDocuments(professionalId: number) {
    this.dataIsLodading = true;
    this._professionalService.getProfessionalDocuments(professionalId).then(res => {
      this.documents = res.data;
      this.hasDocuments = true;
      this.dataIsLodading = false;
    }).catch(err => {
      this.hasDocuments = false;
      this.dataIsLodading = false;
    })
  }

  /**
   * Match professionalCampaigns with campaigns
   */
  matchCampaignsWithProfessionals() {
    const professionalCampaigns = this.data.professionalCampaigns;
    this.campaigns.forEach(element => {
      if (!element.enabled && professionalCampaigns) {
        const matchingCampaign = professionalCampaigns.find(dat => dat.campaignId === element.id);
        if (matchingCampaign) {
          if (matchingCampaign.professionalCampaignStatus.code === 'ACTIVE') {
            element.active = true;
            element.enabled = true;
            element.isHogamiDisabled = false;
          } else if (matchingCampaign.professionalCampaignStatus.code === 'DISABLED_BO') {
            element.active = false;
            element.enabled = false;
            element.isHogamiDisabled = true;
          } else {
            element.active = false;
            element.enabled = false;
            element.isHogamiDisabled = false;
          }
        }
      }
    });
  }

  /**
   * Activate campaign for the professional
   * @param element 
   */
  changeActive(element: any) {
    if (this.isUserLeroy && element.id !== 0) {
      const payload = {
        campaignCode: element.codeCampaign,
        professionalIds: [this.professionalId],
        statusCampaign: element.active ? 'DISABLED_BO' : undefined
      };

      if (element.active) {
        this.confirmDialogDelete(payload);
      } else {
        this.confirmDialogRegister(payload);
      }
    }
  }

  /**
   * Confirm delete professional campaign
   * @param payload 
   */
  confirmDialogDelete(payload: any) {
    const dialogMessage = this._translateService.instant('lm-professional.lm-professional-basic-data.dialog_text');
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        value: dialogMessage['delete_campaign'] + payload['campaignCode'] + dialogMessage['profesional'] + this.professionalName
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this._professionalService.deleteProfessionalCampaig(payload).subscribe(res => {
          this.getProfessionalById(this.professionalId);
          this.dialogRef.close();
        });
      } else {
        this.getProfessionalById(this.professionalId);
        this.dialogRef.close();
      }
    });
  }

  /**
   * Confirm activation campaign for the professional
   * @param payload 
   */
  confirmDialogRegister(payload: any) {
    const dialogMessage = this._translateService.instant('lm-professional.lm-professional-basic-data.dialog_text');
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        value: dialogMessage['register_campaign'] + payload['campaignCode'] + dialogMessage['profesional'] + this.professionalName
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this._professionalService.addProfessionalCampaig(payload).subscribe(res => {
          this.getProfessionalById(this.professionalId);
          this.dialogRef.close();
        });
      } else {
        this.getProfessionalById(this.professionalId);
        this.dialogRef.close();
      }
    });
  }

  /**
   * Setting dropwdown languages
   */
  loadDropdownLanguages(): void {
    const dialogText = this._translateService.instant('lm-professional.lm-filters-professional.text');
    this.dropdownSettingLanguages = {
      singleSelection: false,
      idField: 'id',
      textField: 'languageName',
      selectAllText: dialogText.selectText,
      unSelectAllText: dialogText.unSelectAllText,
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
  }

  /**
   * Activate professional editing form
   * @param data 
   */
  editInfo(data) {
    this.isEditInfo = true;
    this.getPersonTypes();
    this.getLanguages();
    this.loadDropdownLanguages();
    this.createEditFormInfo();
    this.setFormInfo(data);
  }

  /**
   * Cancel professional editing
   */
  cancelEditInfo() {
    this.isEditInfo = false;
  }

  /**
   * Input only numbers
   * @param e 
   */
  onChangeOperationNumber(e) {
    const key = e.key;
    if (key < '0' || key > '9') {
      e.preventDefault();
    }
  }

  /**
   * Create Reactive form for the professional
   */
  createEditFormInfo() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      lastName: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern(Utilities.patterns.email)]),
      idSlogan: new FormControl('', Validators.required),
      slogan: new FormControl('', Validators.required),
      avatarName: new FormControl(''),
      avatar: new FormControl(''),
      personType: new FormControl('', Validators.required),
      documentId: new FormControl('', Validators.required),
      nifcif: new FormControl('', Validators.required),
      iban: new FormControl('', Validators.required),
      professionalDescription: new FormControl('', Validators.required),
      phoneId: new FormControl('', Validators.required),
      phoneContact: new FormControl('', Validators.required),
      emailId: new FormControl('', Validators.required),
      emailContact: new FormControl('', [Validators.required, Validators.pattern(Utilities.patterns.email)]),
      language: new FormControl('', Validators.required),
      addresPostalId: new FormControl(''),
      addresPostalType: new FormControl(''),
      postalCode: new FormControl(''),
      addressPostal: new FormControl(''),
      complementAddressPostal: new FormControl(''),
      cityPostal: new FormControl(''),
      provincePostal: new FormControl(''),
      addresFiscalId: new FormControl(''),
      addresFiscalType: new FormControl(''),
      fiscalCode: new FormControl(''),
      addressFiscal: new FormControl(''),
      complementAddressFiscal: new FormControl(''),
      cityFiscal: new FormControl(''),
      provinceFiscal: new FormControl('')
    });
  }

  /**
   * Set values in the professional edit form
   * @param data 
   */
  setFormInfo(data) {
    this.form.controls['name'].setValue(data['name']);
    this.form.controls['lastName'].setValue(data['lastName']);
    this.form.controls['email'].setValue(data['email']);
    this.form.controls['slogan'].setValue(data['professionalInformation']['title'] ? data['professionalInformation']['title'] : '');
    this.form.controls['idSlogan'].setValue(data['professionalInformation']['id'] ? data['professionalInformation']['id'] : '');
    this.form.controls['professionalDescription'].setValue(data['professionalInformation']['description'] ? data['professionalInformation']['description'] : '');
    this.form.controls['personType'].setValue(data['personType']['id']);
    this.form.controls['nifcif'].setValue(data['documents'] ? data['documents'][0]['documentNumber'] : '');
    this.form.controls['iban'].setValue(data['accountNumber']);
    this.form.controls['documentId'].setValue(data['documents'][0]['id']);
    this.form.controls['phoneId'].setValue(data['contactphones'] ? data['contactphones'][0]['id'] : '');
    this.form.controls['phoneContact'].setValue(data['contactphones'] ? data['contactphones'][0]['telephone'] : '');
    this.form.controls['emailId'].setValue(data['contactEmails'] ? data['contactEmails'][0]['id'] : '');
    this.form.controls['emailContact'].setValue(data['contactEmails'] ? data['contactEmails'][0]['email'] : '');
    this.setLanguages(data['professionalLanguages']);

    let addressPostalId;
    let addressPostalType;
    let addressPostal;
    let addressZipCode;
    let complementAddressPostal;
    let cityPostal;
    let provincePostal;

    let addressFiscalId;
    let addressFiscalType;
    let addressFiscal;
    let addressZipCodeFiscal;
    let complementAddressFiscal;
    let cityFiscal;
    let provinceFiscal;

    if (data['addressRests'] && data['addressRests'][0] && data['addressRests'][0]['addressType'] && data['addressRests'][0]['addressType']['type'] === 'POSTAL') {
      addressPostalId = data['addressRests'][0]['id'];
      addressPostalType = data['addressRests'][0]['addressType']['type'];
      addressPostal = data['addressRests'][0]['address'];
      addressZipCode = data['addressRests'][0]['zipCode'];
      complementAddressPostal = data['addressRests'][0]['complementAddress'];
      cityPostal = data['addressRests'][0]['city'];
      provincePostal = data['addressRests'][0]['province'];
    } else {
      addressFiscalId = data['addressRests'][0]['id'];
      addressFiscalType = data['addressRests'][0]['addressType']['type'];
      addressFiscal = data['addressRests'][0]['address'];
      addressZipCodeFiscal = data['addressRests'][0]['zipCode'];
      complementAddressFiscal = data['addressRests'][0]['complementAddress'];
      cityFiscal = data['addressRests'][0]['city'];
      provinceFiscal = data['addressRests'][0]['province'];
    }

    if (data['addressRests'] && data['addressRests'][1] && data['addressRests'][1]['addressType'] && data['addressRests'][1]['addressType']['type'] === 'FISCAL') {
      addressFiscalId = data['addressRests'][1]['id'];
      addressFiscalType = data['addressRests'][1]['addressType']['type'];
      addressFiscal = data['addressRests'][1]['address'];
      addressZipCodeFiscal = data['addressRests'][1]['zipCode'];
      complementAddressFiscal = data['addressRests'][1]['complementAddress'];
      cityFiscal = data['addressRests'][1]['city'];
      provinceFiscal = data['addressRests'][1]['province'];
    } else {
      if (data['addressRests'].length > 1) {
        addressPostalId = data['addressRests'][1]['id'];
        addressPostalType = data['addressRests'][1]['addressType']['type'];
        addressPostal = data['addressRests'][1]['address'];
        addressZipCode = data['addressRests'][1]['zipCode'];
        complementAddressPostal = data['addressRests'][1]['complementAddress'];
        cityPostal = data['addressRests'][1]['city'];
        provincePostal = data['addressRests'][1]['province'];
      }
    }

    this.form.controls['addresPostalId'].setValue(addressPostalId);
    this.form.controls['addresPostalType'].setValue(addressPostalType);
    this.form.controls['postalCode'].setValue(addressZipCode);
    this.form.controls['addressPostal'].setValue(addressPostal);
    this.form.controls['complementAddressPostal'].setValue(complementAddressPostal);
    this.form.controls['cityPostal'].setValue(cityPostal);
    this.form.controls['provincePostal'].setValue(provincePostal);

    this.form.controls['addresFiscalId'].setValue(addressFiscalId);
    this.form.controls['addresFiscalType'].setValue(addressFiscalType);
    this.form.controls['fiscalCode'].setValue(addressZipCodeFiscal);
    this.form.controls['addressFiscal'].setValue(addressFiscal);
    this.form.controls['complementAddressFiscal'].setValue(complementAddressFiscal);
    this.form.controls['cityFiscal'].setValue(cityFiscal);
    this.form.controls['provinceFiscal'].setValue(provinceFiscal);
  }

  setLanguages(professionalLanguages: LanguageProfessional[]): void {
    const selectedItems = professionalLanguages.map(item => ({
      id: item.languageId,
      languageName: item.languageName
    }));
    this.form.controls['language'].setValue(selectedItems);
  }

  /**
   * Action when the input is blurred (POSTAL ADDRESS)
   * @param event 
   * @returns 
   */
  blurInputPostal(event) {
    const addresPostalId = this.form.controls['addresPostalId'].value;
    const addressPostal = this.form.controls['addressPostal'].value;
    const postalCode = this.form.controls['postalCode'].value;
    const cityPostal = this.form.controls['cityPostal'].value;
    const provincePostal = this.form.controls['provincePostal'].value;

    if (addresPostalId != null) {
      this.doRequiredPostalAddressForm();
      return;
    }

    if (!addressPostal || !postalCode || !cityPostal || !provincePostal) {
      this.doRequiredPostalAddressForm();
      return;
    }

    this.notRequiredPostalAddressForm();
  }

  /**
   * Action when the input is blurred (FISCAL ADDRESS)
   * @param event 
   * @returns 
   */
  blurInputFiscal(event) {
    let addresFiscalId = this.form.controls['addresFiscalId'].value;
    let addressFiscal = this.form.controls['addressFiscal'].value;
    let fiscalCode = this.form.controls['fiscalCode'].value;
    let cityFiscal = this.form.controls['cityFiscal'].value;
    let provinceFiscal = this.form.controls['provinceFiscal'].value;

    if (addresFiscalId != null) {
      this.doRequiredFiscalAddressForm();
      return;
    }

    if (addressFiscal == '' || addressFiscal == null) {
      this.doRequiredFiscalAddressForm();
    }

    if (fiscalCode == '' || fiscalCode == null) {
      this.doRequiredFiscalAddressForm();
    }

    if (cityFiscal == '' || cityFiscal == null) {
      this.doRequiredFiscalAddressForm();
    }

    if (provinceFiscal == '' || provinceFiscal == null) {
      this.doRequiredFiscalAddressForm();
    }

    if (addressFiscal == '' || addressFiscal == null) {
      if (fiscalCode == '' || fiscalCode == null) {
        if (cityFiscal == '' || cityFiscal == null) {
          if (provinceFiscal == '' || provinceFiscal == null) {
            this.notRequiredFiscalAddressForm();
          }
        }
      }
    }
  }

  /**
   * Make required form inputs (POSTAL ADDRESS)
   */
  doRequiredPostalAddressForm() {
    this.form.controls['addressPostal'].setValidators([Validators.required]);
    this.form.controls['addressPostal'].updateValueAndValidity();
    this.form.controls['postalCode'].setValidators([Validators.required]);
    this.form.controls['postalCode'].updateValueAndValidity();
    this.form.controls['cityPostal'].setValidators([Validators.required]);
    this.form.controls['cityPostal'].updateValueAndValidity();
    this.form.controls['provincePostal'].setValidators([Validators.required]);
    this.form.controls['provincePostal'].updateValueAndValidity();
  }

  /**
   * Make required form inputs (FISCAL ADDRESS)
   */
  doRequiredFiscalAddressForm() {
    this.form.controls['addressFiscal'].setValidators([Validators.required]);
    this.form.controls['addressFiscal'].updateValueAndValidity();
    this.form.controls['fiscalCode'].setValidators([Validators.required]);
    this.form.controls['fiscalCode'].updateValueAndValidity();
    this.form.controls['cityFiscal'].setValidators([Validators.required]);
    this.form.controls['cityFiscal'].updateValueAndValidity();
    this.form.controls['provinceFiscal'].setValidators([Validators.required]);
    this.form.controls['provinceFiscal'].updateValueAndValidity();
  }

  /**
   * Make not required form inputs (POSTAL ADDRESS)
   */
  notRequiredPostalAddressForm() {
    this.form.controls['addressPostal'].clearValidators();
    this.form.controls['addressPostal'].updateValueAndValidity();
    this.form.controls['postalCode'].clearValidators();
    this.form.controls['postalCode'].updateValueAndValidity();
    this.form.controls['cityPostal'].clearValidators();
    this.form.controls['cityPostal'].updateValueAndValidity();
    this.form.controls['provincePostal'].clearValidators();
    this.form.controls['provincePostal'].updateValueAndValidity();
  }

  /**
   * Make not required form inputs (FISCAL ADDRESS)
   */
  notRequiredFiscalAddressForm() {
    this.form.controls['addressFiscal'].clearValidators();
    this.form.controls['addressFiscal'].updateValueAndValidity();
    this.form.controls['fiscalCode'].clearValidators();
    this.form.controls['fiscalCode'].updateValueAndValidity();
    this.form.controls['cityFiscal'].clearValidators();
    this.form.controls['cityFiscal'].updateValueAndValidity();
    this.form.controls['provinceFiscal'].clearValidators();
    this.form.controls['provinceFiscal'].updateValueAndValidity();
  }

  get f() {
    return this.form.controls;
  }

  /**
   * File selected
   * @param event 
   */
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.controls['avatarName'].setValue(file.name);
      this.form.patchValue({ avatar: file })
    }
  }

  /**
   * Form submit
   * @returns 
   */
  submitForm() {
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    const formData = new FormData();
    const controls = this.form.controls;

    formData.append('file', controls['avatar'].value);
    formData.append('name', controls['name'].value);
    formData.append('lastName', controls['lastName'].value);
    formData.append('email', controls['email'].value);
    formData.append('professionalInformation.id', controls['idSlogan'].value);
    formData.append('professionalInformation.title', controls['slogan'].value);
    formData.append('personType.id', controls['personType'].value);
    formData.append('professionalInformation.description', controls['professionalDescription'].value);

    formData.append('documents[0].id', controls['documentId'].value);
    formData.append('documents[0].documentNumber', controls['nifcif'].value);
    formData.append('accountNumber', controls['iban'].value);

    formData.append('contactphones[0].id', controls['phoneId'].value);
    formData.append('contactphones[0].telephone', controls['phoneContact'].value);

    formData.append('contactEmails[0].id', controls['emailId'].value);
    formData.append('contactEmails[0].email', controls['emailContact'].value);

    const addresFiscalId = controls['addresFiscalId'].value;
    const addressFiscal = controls['addressFiscal'].value;
    const complementAddressFiscal = controls['complementAddressFiscal'].value;
    const fiscalCode = controls['fiscalCode'].value;
    const provinceFiscal = controls['provinceFiscal'].value;
    const cityFiscal = controls['cityFiscal'].value;

    if (addresFiscalId != null) {
      formData.append('addressRests[1].id', addresFiscalId);
    }
    if (addressFiscal != null) {
      formData.append('addressRests[1].addressType.type', 'FISCAL');
      formData.append('addressRests[1].address', addressFiscal);
    }
    if (complementAddressFiscal != null) {
      formData.append('addressRests[1].complementAddress', complementAddressFiscal);
    }
    if (fiscalCode != null) {
      formData.append('addressRests[1].zipCode', fiscalCode);
    }
    if (provinceFiscal != null) {
      formData.append('addressRests[1].province', provinceFiscal);
    }
    if (cityFiscal != null) {
      formData.append('addressRests[1].city', cityFiscal);
    }

    const addresPostalId = controls['addresPostalId'].value;
    const addressPostal = controls['addressPostal'].value;
    const complementAddressPostal = controls['complementAddressPostal'].value;
    const postalCode = controls['postalCode'].value;
    const provincePostal = controls['provincePostal'].value;
    const cityPostal = controls['cityPostal'].value;

    if (addresPostalId != null) {
      formData.append('addressRests[0].id', addresPostalId);
    }
    if (addressPostal != null) {
      formData.append('addressRests[0].addressType.type', 'POSTAL');
      formData.append('addressRests[0].address', addressPostal);
    }
    if (complementAddressPostal != null) {
      formData.append('addressRests[0].complementAddress', complementAddressPostal);
    }
    if (postalCode != null) {
      formData.append('addressRests[0].zipCode', postalCode);
    }
    if (provincePostal != null) {
      formData.append('addressRests[0].province', provincePostal);
    }
    if (cityPostal != null) {
      formData.append('addressRests[0].city', cityPostal);
    }

    const languages = controls['language'].value;
    const profLanguages = this.buildLanguagesToUpdate(languages);

    profLanguages.forEach((item, index) => {
      formData.append(`professionalLanguages[${index}].languageId`, item['id']);
      formData.append(`professionalLanguages[${index}].isAssigned`, item['isAssigned']);
    });


    this._professionalService.updateProfessionalInfo(formData, this.professionalId).subscribe(res => {
      this.loading = false;
      if (res['code'] === '200') {
        this.isEditInfo = false;
        window.location.reload();
      }
    }, error => {
      this.loading = false;
    });
  }

  /**
   * Build languages active or inactive
   * @param languages 
   * @returns 
   */
  buildLanguagesToUpdate(languages: any[]): any[] {
    const planguages: any[] = this.professionalLanguages.map((secondItem) => {
      const matchingFirstItem = languages.find((firstItem) => firstItem.id === secondItem.id);
      return {
        ...secondItem,
        isAssigned: !!matchingFirstItem,
      };
    });
    return planguages;
  }

}
