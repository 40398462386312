import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-sepa',
  templateUrl: './modal-cancel-sepa.component.html',
  styleUrls: ['./modal-cancel-sepa.component.scss']
})
export class ModalCancelSepaComponent implements OnInit {

  operation: any;
  service: string;
  client: string;
  totalPaid: string;
  paycometReference: string;
  inProgress: boolean = false;
  transferDiv: boolean = false;
  transferMsg: string;

  @Output() submitClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ModalCancelSepaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.operation = this.data['operation'];
    this.service = this.data['operation']['operationOfferedServices'][0]['title'];
    this.client = this.data['operation']['clientDetails']['clientName'];
    this.totalPaid = this.data['bodyCancelSepa']['totalPaid'];
    this.paycometReference = this.data['bodyCancelSepa']['operationOrder'];
    this.inProgress = this.data['progress'];
    this.transferDiv = this.data['transferDiv'];
    this.transferMsg = this.data['transferMsg'];
  }

  dismiss() {
    this.inProgress = false;
    this.dialogRef.close();
  }

  submit() {
    this.inProgress = true;
    this.submitClicked.emit('submit');
  }

  goContinue() {
    this.submitClicked.emit('continue');
  }

  myf(d, f, m) {
    this.inProgress = d['progress'];
    this.transferDiv = f['transferDiv'];
    this.transferMsg = m['transferMsg'];
  }

}
