import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { Utilities } from 'src/app/shared/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private BASE_URL = environment.apiHosts;

  constructor(private _http: HttpClient) { }

    getOperations(page: number = 0, pageSize: number = 0, payload: any = {}): Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
        return this._http.post<any>(
          `${this.BASE_URL}/backoffice-server/api/v1/operations?page=${page}&size=${pageSize}&sort=createDate,desc`,
          payload,
           { headers: head },
        );
    }

    exportOperationToExcel( payload: any): Observable<any> {
        const params = {
            timeZone: Utilities.getTimeZone()
        }
        const head = new HttpHeaders(
            {	}
        );
        return this._http.post( `${environment.apiHosts}/backoffice-server/api/v1/operations/excel`,
        payload, { headers: head, params } );
    }

	getOperationStatus(): Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
		return this._http.get( `${environment.apiHosts}/backoffice-server/api/v1/operations/status`,
			{ headers: head } );
	}


    getSearchServices(  ): Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
        return this._http.get( `${environment.apiWebSearch}/search/v1/services?level=2&level=3`,
            { headers: head } );
    }

    getProvinces(): Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
        return this._http.get( `${environment.apiWebSearch}/search/v1/province`,
            { headers: head } );
    }


    //ChatMessages
    findAllMessagesByChatId(chatId: string) : Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
        return this._http.get( `${environment.apiHosts}/backoffice-server/api/v1/chat/${chatId}`,
            { headers: head } );
    }
    getTransactions(page: number = 0, pageSize: number = 0, operationId: number): Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
        return this._http.get<any>(
          `${this.BASE_URL}/backoffice-server/api/v1/journey-details/${operationId}/transactions?page=${page}&size=${pageSize}`,
           { headers: head },
        );
    }
    getOperationByRequestId(requestId: any): Observable<any> {
        const head = new HttpHeaders(
            {	}
        );
        return this._http.post<any>(
          `${this.BASE_URL}/backoffice-server/api/v1/operation/${requestId}`,
           { headers: head }
        );
    }

    getWorkData(requestId: number): Promise<any> {
        const head = new HttpHeaders(
            {}
        );
        return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/journey-details/${requestId}/work`,
            { headers: head }).toPromise();
    }


}
