<div>
    <h2>
        <p>
            {{'modals.message-dialog.session_expired' | translate}}
        </p>
    </h2>
</div>

<div>
    <div class="grid">
      <button type="submit" class="grid__button" (click)="submit()"> {{'modals.message-dialog.buttons.button_ok' | translate}} </button>
    </div>
  </div>