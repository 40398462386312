<div style="display:inline !important;">
  <app-lm-filters-paycomet
          [loading]="loading"
          [loadExcel]="loadExcel"
          (filtersApply)="getFiltersToPaymentsOperation($event)"
          (filterstoExcel)="exportToExcel($event)"
  ></app-lm-filters-paycomet>
</div>

<div class="content">
  <div class="example-container">
    <mat-table  [dataSource]="data">

      <ng-container matColumnDef="operacionInterna" sticky>
        <th mat-header-cell *matHeaderCellDef> {{'lm-transfers.paycomet-operations.cells.interna' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="changeStatus(element.operationID)" [checked]="element.paycometIsInternal"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.id' | translate}}</th>
        <nav>
          <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/work-details', element.requestID]"><strong>Op: {{element.operationID}}</strong><br /></a><strong>Sol:
              {{element.requestID}}</strong>
          </td>
        </nav>
      </ng-container>

      <ng-container matColumnDef="operationStatus" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="status_name status_{{ element.operationStatusName | lowercase}}">{{element.operationStatusName}} </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startTime" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.appointment_date' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.startTime | date:'fullDate'}},{{element.startTime | date:'shortTime'}} </td>
      </ng-container>

      <ng-container matColumnDef="endTime" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.final_date' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.endTime | date:'fullDate'}},{{element.endTime | date:'shortTime'}} </td>
      </ng-container>

      <ng-container matColumnDef="totalCost" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.total_cost' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.totalCost}} {{element.currency}}</td>
      </ng-container>

      <ng-container matColumnDef="totalPaid" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.total_paid' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.totalPaid}} {{element.currency}}</td>
      </ng-container>

      <ng-container matColumnDef="emailClient" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.client' | translate}}</th>
        <td mat-cell *matCellDef="let element"><div class="client_email">{{element.emailClient}}</div></td>
      </ng-container>

      <ng-container matColumnDef="emailProfesional" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.professional' | translate}}</th>
        <td mat-cell *matCellDef="let element"><div class="professional_email">{{element.emailProfessional}}</div></td>
      </ng-container>

      <ng-container matColumnDef="offeredService">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.services' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.offeredServiceOperationPaycomet ; first as isFirst">
          <span *ngIf="isFirst">
              <div><span class="service_code">{{p?.code}}</span> : <span class="service_name">{{p?.title}}</span></div>
              <div class="service_price">{{'lm-transfers.paycomet-operations.services.price' | translate}} {{p?.price}}  {{p?.currency}} ({{'lm-transfers.paycomet-operations.services.margin' | translate}} {{p['profitMargin']}} %)</div>
              <div class="service_tax">{{p?.taxDetails?.taxRate}}  {{p?.taxDetails?.taxType}}</div>
              <div class="service_date">{{'lm-transfers.paycomet-operations.services.added_on' | translate}} {{p?.createDate}}</div>
          </span>
          </div>
          <div *ngIf="element.offeredServiceOperationPaycomet?.length > 1">
            <button mat-stroked-button (click)="showMore()">{{stringShowMore}}</button>
            <div  *ngIf="buttonShowMore">
              <div *ngFor="let p of element.offeredServiceOperationPaycomet | slice:1; let i = index;" class="upperline">
                <div><span class="service_code">{{p['code']}}</span> : <span class="service_name">{{p['title']}}</span></div>
                <div class="service_price">{{'lm-transfers.paycomet-operations.services.price' | translate}} {{p['price']}}  {{p['currency']}} ({{'lm-transfers.paycomet-operations.services.margin' | translate}} {{p['profitMargin']}} %)</div>
                <div class="service_tax">{{p['taxDetails']['taxRate']}}  {{p['taxDetails']['taxType']}}</div>
                <div class="service_date">{{'lm-transfers.paycomet-operations.services.added_on' | translate}} {{p['createDate']}}</div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="productOffered">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.products' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.productoOperationPaycomet ; first as isFirst">
          <span *ngIf="isFirst">
              <div><span class="service_name">{{p?.productDescription}}</span></div>
              <div class="service_price">{{'lm-transfers.paycomet-operations.products.price' | translate}} {{p?.price}}  {{p?.currency}}</div>
              <div class="service_tax">{{p?.taxDetails?.taxRate}}  {{p?.taxDetails?.taxType}}</div>
              <div class="service_date">{{'lm-transfers.paycomet-operations.products.added_on' | translate}} {{p?.createDate}}</div>
          </span>
          </div>
          <div *ngIf="element.productoOperationPaycomet?.length > 1">
            <button mat-stroked-button (click)="showMore()">{{stringShowMore}}</button>
            <div  *ngIf="buttonShowMore">
              <div *ngFor="let p of element.productoOperationPaycomet | slice:1; let i = index;" class="upperline">
                <div><span class="service_name">{{p['productDescription']}}</span></div>
                <div class="service_price">{{'lm-transfers.paycomet-operations.products.price' | translate}} {{p['price']}}  {{p['currency']}}</div>
                <div class="service_tax">{{p['taxDetails']['taxRate']}}  {{p['taxDetails']['taxType']}}</div>
                <div class="service_date">{{'lm-transfers.paycomet-operations.products.added_on' | translate}} {{p['createDate']}}</div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paycometId" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.id' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.paycometID}} </td>
      </ng-container>

      <ng-container matColumnDef="paycometTimeStamp" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.transaction_date' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{getDateWithTimeZone(element.paycometTimestamp)}} </td>
      </ng-container>

      <ng-container matColumnDef="paycomentReference" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.reference' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.paycometReference}} </td>
      </ng-container>

      <ng-container matColumnDef="paycometOperationName" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.operation_name' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.paycometOperationName}} </td>
      </ng-container>

      <ng-container matColumnDef="paycometAmmountDisplay" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.value' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.payccometAmountDisplay}} </td>
      </ng-container>

      <ng-container matColumnDef="paycometCommission" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.commission' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.paycometComission}} </td>
      </ng-container>

      <ng-container matColumnDef="paycometConcept" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.concept' | translate}}<br>{{'lm-transfers.paycomet-operations.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.paycometConcept}} </td>
      </ng-container>

      <ng-container matColumnDef="campaigns" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.campaign' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.listOperationCampaigns.length > 0">
            <div *ngFor="let p of element.listOperationCampaigns">
              <p>{{p?.campaignCode}}</p>
            </div>
          </div>
          <div *ngIf="element.listOperationCampaigns.length <= 0">
            <p>{{'lm-transfers.paycomet-operations.campaign.web' | translate}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="storeName" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.shop' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.storeRest">{{element.storeRest?.name}} ({{element.storeRest?.leroyId}})</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="comercialId" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.paycomet-operations.cells.comercial_id' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div>{{element.commercialId}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="fill">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
    <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="getPaginatorData($event)">
    </mat-paginator>
  </div>
</div>
