import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {TransferSepaModel} from "src/app/shared/models/shared.models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MaintenanceService {
  constructor(private _http: HttpClient) {}

  resetPassword(user: string, pass: string) {
    const body = {
      username: user,
      password: pass,
    };
    const head = new HttpHeaders({ });
    return this._http.put<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/users/reset-password`,
      body,
      { headers: head }
    );
  }

  createNewUser(user: string, pass: string) {
    const body = {
      username: user,
      password: pass,
    };
    const head = new HttpHeaders({ });
    return this._http.post<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/users/create-backoffice-user`,
      body,
      { headers: head }
    );
  }

  banProfesional(email: string) {
    const head = new HttpHeaders({ });
    return this._http.put<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/professionals/ban?professionalEmail=${email}`,
      null,
      { headers: head },
    );
  }
  blockProfesional(email: string) {
    const head = new HttpHeaders({ });
    return this._http.put<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/professionals/block?professionalEmail=${email}`,
      null,
      { headers: head },
    );
  }
  banProfesionalV2(email: string) {
    const head = new HttpHeaders({ });
    return this._http.put<any>(
      `${environment.apiHosts}/backoffice-server/api/v2/professionals/ban?professionalEmail=${email}`,
      null,
      { headers: head },
    );
  }

  unbanProfesional(email: string, password: string,  status: string) {
    const head = new HttpHeaders({ });
    return this._http.put<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/professionals/unban?professionalEmail=${email}&professionalPassword=${password}&newStatus=${status}`,
      null,
      { headers: head },
    );
  }

  unbanProfesionalV2(email: string, status: string) {
    const head = new HttpHeaders({ });
    return this._http.put<any>(
      `${environment.apiHosts}/backoffice-server/api/v2/professionals/unban?professionalEmail=${email}&newStatus=${status}`,
      null,
      { headers: head },
    );
  }


  transferSepa(payload: TransferSepaModel): Observable<any>{
    const head = new HttpHeaders({ });
    return this._http.post<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/sepaOperationPayment`,
      payload,
      { headers: head }
    );
  } 
  
  transferPayBy(payload: any = {}): Observable<any>{
  
    const head = new HttpHeaders({  });
    return this._http.post<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/payByPayment`,
      payload,
      { headers: head }
    );
  }

  findOperation(operationId: number): Observable<any> {
    const head = new HttpHeaders(
			{ }
		);
		return this._http.get( `${environment.apiHosts}/backoffice-server/api/v1/operations/${operationId}`,
			{ headers: head } );
  }

  cancelSepa(payload: any = {}): Observable<any>{
  
    const head = new HttpHeaders({  });
    return this._http.post<any>(
      `${environment.apiHosts}/backoffice-server/api/v1/cancelSepaOperation`,
      payload,
      { headers: head }
    );
  }

}
