<div [style.display]="(!transferDiv) ? 'block' : 'none'">
  <div class="divCenter">
    <h2>
      <p>
        {{'modals.modal-payby.title' | translate}}
      </p>
    </h2>
    <p>{{'modals.modal-payby.subTitle' | translate}}</p>
  </div>
  
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-payby.labels.import' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{importe}} EUR </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-payby.labels.operation' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{operation.id}}</label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-payby.labels.services' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{offeredService}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-payby.labels.client' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{client}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-payby.labels.ref_paycomet' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{reference}} </label>
  </div>
  
  <hr>
  
  <div class="meter">
    <span style="width:80%;"><span class="progress" [style.display]="inProgress===true ? 'block' : 'none'"></span></span>
  </div>

  <p class="greenText" [style.display]="(transferMsg === '') ? 'none' : 'block'">{{transferMsg}}</p>
  
  <div>
    <div class="grid">
      <button type="submit" class="grid__button_back" (click)="dismiss()" [disabled]="inProgress">{{'modals.modal-payby.buttons.cancel' | translate}}</button>
      <button type="submit" class="grid__button" (click)="submit()" [disabled]="inProgress">{{'modals.modal-payby.buttons.continu' | translate}}</button>
    </div>
  </div>
</div>

<div [style.display]="(transferDiv) ? 'block' : 'none'">
  <div class="divCenter">
    <h1>{{'modals.modal-payby.message_success' | translate}}</h1>
    <br><br>
    <button type="button" (click)="goContinue()" class="button_continue">{{'modals.modal-payby.buttons.continu' | translate}}</button>
  </div>
</div>