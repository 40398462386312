import { Component, OnInit} from '@angular/core';
import { TransferService } from '../services/transfers.service';
import { first } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ChatsDialogComponent } from '../sections/chats-dialog/chats-dialog.component';
import { ImagesDialogComponent } from '../sections/images-dialog/images-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-lm-transfers',
	templateUrl: './lm-transfers.component.html',
	styleUrls: ['../../utils/mat-table.scss']
})
export class LmTransfersComponent implements OnInit {
	indice: number;
	operaciones: boolean;
	solicitudes: boolean;
	showFiltersReq: boolean = false;
	showFilterOp: boolean = false;
	resetFiltered: boolean = true;
	showAllProducts: boolean = false;
	transferType: string;
	contFilterTime: number;
	loading: boolean;
	loadExcel: boolean = false;
	indiceSol: number;
	buttonShowMore: boolean;
	stringShowMore: string;
	arrayShowOfferedServices = [];
	isFiltered: boolean;
	filterPagination;
	datag;
	dataSolic;
	dataFiltered;
	datos;
	datosSolic;
	dataProfessional;
	dataExcel;
	displayedColumns: string[] = [ 'id', 'operationStatus', 'operationOfferedServices', 'clientDetails', 'startTime', 'endTime', 'professionalDetails',  'totalCost',
		 'totalPaid', 'operationAddress',  'products', 'images', 'unit', 'rejectionMessage', 'fill', 'actions' ];

	displayedSolColumns: string[] = [ 'id','requestStatus', 'offeredService', 'clientDetails',
		'createDate', 'operationDate',  'endDate',
		'professionalDetails', 'requestAddress','fill', 'actionss' ];

	displayedProfessionalColums: string[] = [ 'additionalInfo',];

	constructor(
		private _transfer: TransferService,
		public dialog: MatDialog
	) {
		this.indiceSol = 0;
		this.indice = 0;
		this.operaciones = true;
		this.solicitudes = true;
		this.loading = false;
		this.buttonShowMore = false;
		this.stringShowMore = 'Ver todos';
		this.isFiltered = false;
	}

	ngOnInit() {
		this.indiceSol = 0;
		this.datosSolic = {};
		this.indice = 0;
		this.datos = {};
		this.showFiltersReq = false;
		this.showFilterOp = false;
		this.contFilterTime = 0;
		this.resetFilters();
		if (localStorage.getItem('type') == '1') {
			localStorage.removeItem('type');
			this.getOperations();
		} else if (localStorage.getItem('type') == '2') {
			localStorage.removeItem('type');
			this.getRequest();
		}
	}

	showMore() {
		this.buttonShowMore ? this.stringShowMore = 'Ver todos' : this.stringShowMore = 'Ocultar todos';
		this.buttonShowMore = !this.buttonShowMore;
	}

	toggleProducts(){
		this.showAllProducts = !this.showAllProducts
	}

	getOperations() {
		this.showFiltersReq = false;
		this.showFilterOp = true;
		this.transferType ='op';
		this.loading = true;
		this.solicitudes = false;
		this.operaciones = true;
		this.datosSolic = {};
		this.dataProfessional = undefined;

		if(this.resetFiltered){
			this.isFiltered = false;
		}

		if(this.isFiltered){
			this.loading = false;
			this.datag = this.dataFiltered;
		}
		else{
		this._transfer.getOperations( this.indice ).pipe( first() ).subscribe(
			res => {
				if ( this.datag === undefined ) {
					this.loading = false;
					this.datag = res.data.content;
				} else {
					this.datag = [...this.datag, ...res.data.content];
					this.loading = false;
				}
				this.indice++;
			},
			err => {
				localStorage.removeItem( 'token' );
			}
			);
		}
	}

	paginationToFiltersAplly(){
		this.getFiltersToShow(this.filterPagination);
		this.contFilterTime++;
	}
	
	resetFilters(){
		this.resetFiltered = true;
	}

	getFiltersToShow(filtersApply) {
		this.filterPagination = filtersApply;
		this.resetFiltered = false;
		this.transferType = filtersApply.transferType
		if(this.transferType === 'op'){
			this.solicitudes = false;
			this.operaciones = true;
			this.loading = true;
			this._transfer.getOperationsFilters(filtersApply,this.contFilterTime).subscribe(
				async response => {
					if (response.data.totalElements === 0) {
						alert("No se han encontrado resultados, se van a cargar todas las operaciones");
						this.isFiltered = false;
						this.contFilterTime = 0;
						this.getOperations();
					}else{
						this.dataFiltered = await response.data.content;
						this.isFiltered = true;
						// this.contFilterTime++;
						this.getOperations();
					}
				},
				err => {
					alert("No se han encontrado resultados, se van a cargar todas las operaciones");
					this.isFiltered = false;
					this.contFilterTime = 0;
					this.getOperations();
				}
			);
		}else{
			this.solicitudes = true;
			this.operaciones = false;
			this.loading = true;
			this._transfer.getRequestFilters(filtersApply,this.contFilterTime).subscribe(
				async response => {
					if(response.data.totalElements === 0){
						alert("No se han encontrado resultados, se van a cargar todas las solicitudes");
						this.isFiltered = false;
						this.contFilterTime = 0;
						this.getRequest();
					}else{
						this.dataFiltered = await response.data.content;
						this.isFiltered = true;
						// this.contFilterTime++;
						this.getRequest();
					}
				},
				err => {
					alert("No se han encontrado resultados, se van a cargar todas las solicitudes");
					this.isFiltered = false;
					this.contFilterTime = 0;
					this.getRequest();
				}
			);
		}
	}

	getRequest() {
		this.showFiltersReq = true;
		this.showFilterOp = false;
		this.transferType = 're';
		this.loading = true;
		this.operaciones = false;
		this.solicitudes = true;
		this.datos = {};
		this.dataProfessional = undefined;

		if(this.resetFiltered){
			this.isFiltered = false;
		}

		if(this.isFiltered){
			this.loading = false;
			this.dataSolic = this.dataFiltered;
		}
		else{
			this._transfer.getRequest( this.indiceSol ).pipe( first() ).subscribe(
				res => {
					if ( this.dataSolic === undefined ) {
						this.loading = false;
						this.dataSolic = res.data.content;
					} else {
						this.dataSolic = [...this.dataSolic, ...res.data.content];
						this.loading = false;
					}
					this.indiceSol++;
				},
				err => {
					localStorage.removeItem( 'token' );
				}
			);
		}
	}

	showAdditionalData( professionalId: number ) {
		this.indice = 0;
		this.indiceSol = 0;
		this.operaciones = true;
		this.solicitudes = true;
		this.datag = undefined;
		this.dataSolic = undefined;
		this._transfer.getAdditionalProfessionalData( professionalId ).pipe( first() ).subscribe(
			res => {
				this.dataProfessional = [ res.data ];
			},
			error => {
				localStorage.removeItem( 'token' );
			}
		);
	}

	exportToExcel(filterstoExcel){
		this.loadExcel = true;
		if(this.transferType === 'op'){
			this._transfer.exportOperationToExcel(filterstoExcel).subscribe(
				async res => {
					const blob = new Blob([Utilities.base64toBlob(res.excel, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
					FileSaver.saveAs(blob, ('ReportBackoffice_'.concat(Utilities.getDateActual().concat('.xls'))));
					this.loadExcel = false;
				},
				error => {
					localStorage.removeItem( 'token' );
				}
			);
		}
		else{
			this._transfer.exportRequestToExcel(filterstoExcel).subscribe(
				async res => {
					const blob = new Blob([Utilities.base64toBlob(res.excel, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
					FileSaver.saveAs(blob, ('ReportBackoffice_'.concat(Utilities.getDateActual().concat('.xls'))));
					this.loadExcel = false;
				},
				error => {
					localStorage.removeItem( 'token' );
				}
			);
		}
	}

	//Chats
	openDialog(chatId: string, professional: string, clientDetails: string): void {
		if(professional != null ) {
			if(clientDetails != null) {
				const dialogRef = this.dialog.open(ChatsDialogComponent, {
				  width: '1200px',
				  height: 'auto',
				  maxHeight: '600px',
				  data: {
					chatId: chatId, 
					professional: professional['email'], 
					client: clientDetails['email']
				  }
				});
			
				dialogRef.afterClosed().subscribe(result => {
				  console.debug('The dialog was closed');
				});
			} else {
				alert('Cliente no encontrado');
			}

		} else {
			alert('Profesional no encontrado');
		}
	  }
	//Images
	openImagesDialog(url: string, type: string): void {
			if(url != null) {
				const dialogRef = this.dialog.open(ImagesDialogComponent, {
				  width: '80%',
				  height: '80%',
				  maxHeight: '80%',
				  data: {
					url: url,
					type : type
				  }
				});

				dialogRef.afterClosed().subscribe(result => {

				});
			}
	  }
}
