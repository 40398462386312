import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OperationService } from '../../services/operation.service';
import { Service, StatusTransfer, Provinces, Campaigns, Stores, OfferedServiceType } from '../../models/lm-transfers.model';
import { Utilities } from '../../../../shared/utilities/utilities';
import { TransferService } from '../../services/transfers.service';
import { Observable, Subscription } from 'rxjs';
import { CountryPhone, InterfaceCountries } from '../../../../shared/utilities/interface-countries';
import { map, startWith } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-lm-filters-operations',
  templateUrl: './lm-filters-operations.component.html',
  styleUrls: ['../../../utils/form-search.scss', './lm-filters-operations.component.scss', '../../../utils/dropdown.scss']
})
export class LmFiltersOperationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  form: FormGroup;
  isUserLeroy: boolean;
  typeId: string;
  transferType: string = 'op';
  services: Service[] = [];
  statusFilters: StatusTransfer[] = [];
  campaigns: Campaigns[] = [];
  stores: Stores[] = [];
  serviceSelected: number = null;
  provinces: Provinces[] = [];
  dropdownSettingsServices = {};
  dropdownSettingsStates = {};
  dropdownSettingCampaigns = {};
  dropdownSettingStores = {};
  dropdownOfferedServiceTypes = {};
  options: InterfaceCountries[] = [];
  filteredOptions: Observable<InterfaceCountries[]>;
  countrySelected: InterfaceCountries;
  defaultCountry: string = '+34';
  offeredServiceTypes: OfferedServiceType[];
  countryPhones: CountryPhone[];
  phoneMaxLenght: number = 9;

  @Input() loading: boolean = false;
  @Input() loadExcel: boolean;
  @Output() filtersApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterstoExcel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _operationServices: OperationService,
    private _transferService: TransferService,
    private _translateService: TranslateService,
    private renderer: Renderer2
  ) {
    this._translationSubscription = this._translateService
      .get('lm-transfers.sections.lm-filters-operations.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
        this.loadDropdownService();
        this.loadDrodownState();
        this.loadDropdownCampaigns();
        this.loadDropdownStores();
        this.loadOfferedServiceTypes();
        this.loadDropdownOfferedServiceTypes();
      });
  }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserHogami();
    this.loadServices(() => { });
    this.loadProvinces();
    this.loadOperationStatus();
    this.loadCampaigns();
    this.loadStores();
    this.createForm();

    this.loadCountries();
    this.startAutocomplete();
  }

  createForm() {
    this.form = new FormGroup({
      dateSinceStarttime: new FormControl(''),
      dateUntilStarttime: new FormControl(''),
      operationDateStartTime: new FormControl(''),
      operationDateEndTime: new FormControl(''),
      dateSinceEndtime: new FormControl(''),
      dateUntilEndtime: new FormControl(''),
      profEmail: new FormControl(''),
      clientEmail: new FormControl(''),
      serviceSelected: new FormControl(''),
      provinceSelected: new FormControl(''),
      stateSelected: new FormControl(''),
      idType: new FormControl(''),
      clientPhone: new FormControl(),
      clientName: new FormControl(''),
      campaignSelected: new FormControl(''),
      storeSelected: new FormControl(''),
      commercialId: new FormControl(''),
      countryCode: new FormControl(''),
      couponCode: new FormControl(''),
      offeredServiceTypeSelected: new FormControl('')
    });
  }

  submitForm() {
    this.sendFilters();
  }

  resetFilters() {
    this.form.reset();
  }

  sendFilters() {
    const infoFilters = {
      transferType: this.transferType,
      // operation date
      operationDateStartTime: (this.form.controls['operationDateStartTime'].value == null || this.form.controls['operationDateStartTime'].value == "") ? null : Utilities.getDate(this.form.controls['operationDateStartTime'].value),
      operationDateEndTime: (this.form.controls['operationDateEndTime'].value == null || this.form.controls['operationDateEndTime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['operationDateEndTime'].value),
      // start time
      startDateStartTime: (this.form.controls['dateSinceStarttime'].value == null || this.form.controls['dateSinceStarttime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceStarttime'].value),
      endDateStartTime: (this.form.controls['dateUntilStarttime'].value == null || this.form.controls['dateUntilStarttime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilStarttime'].value),
      // end time
      startDateEndTime: (this.form.controls['dateSinceEndtime'].value == null || this.form.controls['dateSinceEndtime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceEndtime'].value),
      endDateEndTime: (this.form.controls['dateUntilEndtime'].value == null || this.form.controls['dateUntilEndtime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilEndtime'].value),

      professional: (this.form.controls['profEmail'].value == null || this.form.controls['profEmail'].value == "") ? null : this.form.controls['profEmail'].value.trim(),
      client: (this.form.controls['clientEmail'].value == null || this.form.controls['clientEmail'].value == "") ? null : this.form.controls['clientEmail'].value.trim(),
      services: (this.form.controls['serviceSelected'].value == null || this.form.controls['serviceSelected'].value == "") ? [] : this.proccessServices(this.form.controls['serviceSelected'].value),
      province: (this.form.controls['provinceSelected'].value !== null && (Number(this.form.controls['provinceSelected'].value) || !this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : "0"),
      concatenatedProvince: (this.form.controls['provinceSelected'].value !== null && (this.form.controls['provinceSelected'].value != 0 && this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : null),
      jobsStatus: (this.form.controls['stateSelected'].value == null || this.form.controls['stateSelected'].value == "") ? [] : this.proccessStates(this.form.controls['stateSelected'].value),
      id: (this.form.controls['idType'].value == null || this.form.controls['idType'].value == "") ? 0 : this.form.controls['idType'].value,
      phoneClient: (this.form.controls['clientPhone'].value == null || this.form.controls['clientPhone'].value == "") ? null : this.form.controls['clientPhone'].value,
      nameAndLastNameClient: (this.form.controls['clientName'].value == null || this.form.controls['clientName'].value == "") ? null : this.form.controls['clientName'].value.trim(),

      campaigns: (this.form.controls['campaignSelected'].value == null || this.form.controls['campaignSelected'].value == "") ? [] : this.proccessCampaigns(this.form.controls['campaignSelected'].value),
      stores: (this.form.controls['storeSelected'].value == null || this.form.controls['storeSelected'].value == "") ? [] : this.proccessStores(this.form.controls['storeSelected'].value),
      commercialId: (this.form.controls['commercialId'].value == null || this.form.controls['commercialId'].value == "") ? null : this.form.controls['commercialId'].value,
      countryCode: (this.form.controls['countryCode'].value == null || this.form.controls['countryCode'].value == "") ? null : this.form.controls['countryCode'].value.trim(),
      couponCode: (this.form.controls['couponCode'].value == null || this.form.controls['couponCode'].value == "") ? null : this.form.controls['couponCode'].value.trim(),
      offeredServiceTypeIds: (this.form.controls['offeredServiceTypeSelected'].value == null || this.form.controls['offeredServiceTypeSelected'].value == "") ? [] : this.proccessOfferedServiceType(this.form.controls['offeredServiceTypeSelected'].value),
    }
    this.filtersApply.emit(infoFilters);
  }

  exportToExcel() {
    const infoFilters = {
      transferType: this.transferType,
      // operation date
      operationDateStartTime: (this.form.controls['operationDateStartTime'].value == null || this.form.controls['operationDateStartTime'].value == "") ? null : Utilities.getDate(this.form.controls['operationDateStartTime'].value),
      operationDateEndTime: (this.form.controls['operationDateEndTime'].value == null || this.form.controls['operationDateEndTime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['operationDateEndTime'].value),
      // start time
      startDateStartTime: (this.form.controls['dateSinceStarttime'].value == null || this.form.controls['dateSinceStarttime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceStarttime'].value),
      endDateStartTime: (this.form.controls['dateUntilStarttime'].value == null || this.form.controls['dateUntilStarttime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilStarttime'].value),
      // end time
      startDateEndTime: (this.form.controls['dateSinceEndtime'].value == null || this.form.controls['dateSinceEndtime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceEndtime'].value),
      endDateEndTime: (this.form.controls['dateUntilEndtime'].value == null || this.form.controls['dateUntilEndtime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilEndtime'].value),

      professional: (this.form.controls['profEmail'].value == null || this.form.controls['profEmail'].value == "") ? null : this.form.controls['profEmail'].value.trim(),
      client: (this.form.controls['clientEmail'].value == null || this.form.controls['clientEmail'].value == "") ? null : this.form.controls['clientEmail'].value.trim(),
      services: (this.form.controls['serviceSelected'].value == null || this.form.controls['serviceSelected'].value == "") ? [] : this.proccessServices(this.form.controls['serviceSelected'].value),
      province: (this.form.controls['provinceSelected'].value !== null && (Number(this.form.controls['provinceSelected'].value) || !this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : "0"),
      concatenatedProvince: (this.form.controls['provinceSelected'].value !== null && (this.form.controls['provinceSelected'].value != 0 && this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : null),
      jobsStatus: (this.form.controls['stateSelected'].value == null || this.form.controls['stateSelected'].value == "") ? [] : this.proccessStates(this.form.controls['stateSelected'].value),
      id: (this.form.controls['idType'].value == null || this.form.controls['idType'].value == "") ? 0 : this.form.controls['idType'].value,
      phoneClient: (this.form.controls['clientPhone'].value == null || this.form.controls['clientPhone'].value == "") ? null : this.form.controls['clientPhone'].value,
      nameAndLastNameClient: (this.form.controls['clientName'].value == null || this.form.controls['clientName'].value == "") ? null : this.form.controls['clientName'].value.trim(),

      campaigns: (this.form.controls['campaignSelected'].value == null || this.form.controls['campaignSelected'].value == "") ? [] : this.proccessCampaigns(this.form.controls['campaignSelected'].value),
      stores: (this.form.controls['storeSelected'].value == null || this.form.controls['storeSelected'].value == "") ? [] : this.proccessStores(this.form.controls['storeSelected'].value),
      commercialId: (this.form.controls['commercialId'].value == null || this.form.controls['commercialId'].value == "") ? null : this.form.controls['commercialId'].value,
      countryCode: (this.form.controls['countryCode'].value == null || this.form.controls['countryCode'].value == "") ? null : this.form.controls['countryCode'].value.trim(),
      couponCode: (this.form.controls['couponCode'].value == null || this.form.controls['couponCode'].value == "") ? null : this.form.controls['couponCode'].value.trim(),
      offeredServiceTypeIds: (this.form.controls['offeredServiceTypeSelected'].value == null || this.form.controls['offeredServiceTypeSelected'].value == "") ? [] : this.proccessOfferedServiceType(this.form.controls['offeredServiceTypeSelected'].value),
    };
    this.loadExcel = false;
    this.filterstoExcel.emit(infoFilters);
  }

  /**
   * Retrieve Countries
   */
  loadCountries(): void {
    this._transferService.getCountries().subscribe(res => {
      this.countryPhones = res.data;
      this.countryPhones.forEach(item => {
        let option: InterfaceCountries = {
          name: item.countryRest.countryName,
          shortName: '',
          countryCode: item.countryCode,
          flag: item.countryRest.countryIconKey,
          minNumberDigits: item.countryMinNumberDigits,
          maxNumberDigits: item.countryMaxNumberDigits
        }
        this.options.push(option);
      });
      this.form.controls['countryCode'].setValue(this.defaultCountry);
      this.countrySelected = this.findCountryByCode(this.defaultCountry);
    })
  }

  /**
   * Retrieve Provinces
   */
  loadProvinces() {
    this._operationServices.getProvinces().subscribe(
      res => {
        this.provinces = this.mergeZipCodes(res.data);
      }
    )
  }

  /**
   * Retrieve Services
   * @param callback  
   */
  loadServices(callback) {
    this._operationServices.getSearchServices().subscribe(
      async res => {
        this.services = await res.data;
        this.services.sort((a, b) => a.description.localeCompare(b.description));
        this.services.forEach(element => {
          let fullDescrition = element.description + ' - ' + element.code;
          element.fullDescrition = fullDescrition;
        });
        if (callback) { callback(); }
      }
    );
  }

  /**
   * Retrieve Operation Status
   */
  loadOperationStatus() {
    this._operationServices.getOperationStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    );
  }

  /**
   * Retrieve Campaigns
   */
  loadCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data;
      this.campaigns.push({
        id: 0,
        descriptionCampaign: '',
        codeCampaign: 'WEB'
      });
      this.campaigns.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
    });
  }

  /**
   * Retrieve Stores
   */
  loadStores() {
    this._transferService.getAllStores().subscribe(res => {
      this.stores = res.data;
      this.stores.forEach(element => {
        let fullName = element.leroyId + ' - ' + element.name;
        element.fullName = fullName;
      });
      this.stores.sort((a, b) => a.leroyId - b.leroyId);
    });
  }

  /**
   * Retrieve OfferedServiceTypes
   */
  loadOfferedServiceTypes(): void {
    this._transferService.getServiceTypes().subscribe(res => {
      this.offeredServiceTypes = res.data;
    });
  }

  mergeZipCodes(d = []) {
    let arr = []
    d.map(item => {
      const index = arr.findIndex(a => a.name === item.name)
      if (index === -1) {
        arr.push({ name: item.name, zipCode: "" + item.zipCode })
      } else {
        arr[index] = { name: item.name, zipCode: arr[index].zipCode + "," + item.zipCode }
      }
    })
    return arr;
  }

  /**
   * This function validate string in input phone
   * @param e 
   */
  onChangePhoneClent(e: any) {
    const key = e.key;
    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  }

  /**
   * This function processes the array of services to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessServices(arrayServices: any) {
    let finalService = [];
    arrayServices.forEach(element => {
      finalService.push(element.id)
    });
    return finalService;
  }

  /**
   * This function processes the array of states to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessStates(arrayStates: any) {
    let finalStates = [];
    arrayStates.forEach(element => {
      finalStates.push(element.id)
    });
    return finalStates;
  }

  /**
   * This function processes the array of campaigns to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessCampaigns(arrayCampaign: any) {
    let finalCampaigns = [];
    arrayCampaign.forEach(element => {
      finalCampaigns.push(element.id);
    });
    return finalCampaigns;
  }

  /**
   * This function processes the array of stores to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessStores(arrayStores: any) {
    let finalStores = [];
    arrayStores.forEach(element => {
      finalStores.push(element.leroyId);
    });
    return finalStores;
  }

  /**
   * This function processes the array of offered service types to construct an array of integers.
   * @param arrayTypes 
   */
  proccessOfferedServiceType(arrayTypes: any) {
    let finalTypes = [];
    arrayTypes.forEach(element => {
      finalTypes.push(element.id);
    });
    return finalTypes;
  }

  /**
   * This function loads the configuration of the service dropdown.
   */
  loadDropdownService() {
    this.dropdownSettingsServices = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullDescrition',
      selectAllText: this._messages['select_all'],
      unSelectAllText: this._messages['deselect_all'],
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  /**
   * This function loads the configuration of the states dropdown.
   */
  loadDrodownState() {
    this.dropdownSettingsStates = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this._messages['select_all'],
      unSelectAllText: this._messages['deselect_all'],
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  loadDropdownCampaigns() {
    this.dropdownSettingCampaigns = {
      singleSelection: false,
      idField: 'id',
      textField: 'codeCampaign',
      selectAllText: this._messages['select_all'],
      unSelectAllText: this._messages['deselect_all'],
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  loadDropdownStores() {
    this.dropdownSettingStores = {
      singleSelection: false,
      idField: 'leroyId',
      textField: 'fullName',
      selectAllText: this._messages['select_all'],
      unSelectAllText: this._messages['deselect_all'],
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  loadDropdownOfferedServiceTypes(): void {
    this.dropdownOfferedServiceTypes = {
      singleSelection: false,
      idField: 'id',
      textField: 'description',
      selectAllText: this._messages['select_all'],
      unSelectAllText: this._messages['deselect_all'],
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  /**
   * Start Autocomplete
   */
  startAutocomplete() {
    this.filteredOptions = this.form.controls['countryCode'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  /**
   * Display in input Autocomplete
   * @param country 
   * @returns 
   */
  displayFn(country: InterfaceCountries): any {
    return country;
  }

  /**
   * Find item by value
   * @param name 
   * @returns 
   */
  private _filter(name: string): InterfaceCountries[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(function (option) {
      if (option.name.toLowerCase().includes(filterValue) || option.countryCode.includes(filterValue)) {
        return option;
      }
    });
  }

  /**
   * Selected country
   * @param item 
   */
  getOption(item) {
    this.countrySelected = this.findCountryByCode(item);
    this.phoneMaxLenght = this.countrySelected.maxNumberDigits;
    this.renderer.selectRootElement('#clientPhone').focus();
  }

  /**
   * Find country by code
   * @param countryCode 
   * @returns 
   */
  findCountryByCode(countryCode: string): InterfaceCountries {
    return this.options.find(item => item['countryCode'] === countryCode);
  }

  /**
   * Blur Inpu Country
   * @param event 
   */
  blurCountry(event) {
    let value = event.target.value;
    let country;
    if (!this.autocomplete.isOpen) {
      country = this.findCountryByCode(value);
      if (country) {
        this.countrySelected = country;
      }
      this.form.controls['countryCode'].setValue(this.countrySelected.countryCode);
    }
  }

  /**
   * Click in input autocomplete
   */
  clickEvent() {
    this.startAutocomplete();
  }

}
