import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LmTransferPaybyRoutingModule } from './lm-transfer-payby-routing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LmTransferPaybyComponent } from './component/lm-transfer-payby.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
	declarations: [
		LmTransferPaybyComponent
	],
	imports: [
		LmTransferPaybyRoutingModule,
		FormsModule,
		CommonModule,
		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatButtonModule,
		MatDialogModule,
		MatCardModule,
		MatIconModule,
		TranslateModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule
	]
})

export class LmTransferPaybyModule { }
