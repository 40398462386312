import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LmLoginComponent } from './component/lm-login.component';

const routes: Routes = [
    {
        path: '',
        component: LmLoginComponent
    }
];

@NgModule( {
    imports: [ RouterModule.forChild( routes ) ],
    exports: [ RouterModule ]
} )

export class LmLoginRoutingModule {}
