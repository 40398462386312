import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { Utilities } from 'src/app/shared/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private BASE_URL = environment.apiHosts;

  constructor(private _http: HttpClient) { }

  getOperations(page: number = 0, pageSize: number = 0, payload: any = {}): Observable<any> {
    const head = new HttpHeaders({ });
    return this._http.post<any>(
      `${this.BASE_URL}/backoffice-server/api/v1/billing/operation?page=${page}&size=${pageSize}`,
      payload,
      { headers: head },
    );
  }

  getExcelFile(payload: any = {}): Observable<any>{
    const head = new HttpHeaders({ });
    const params = {
      timeZone: Utilities.getTimeZone()
    }
    return this._http.post<any>(
      `${this.BASE_URL}/backoffice-server/api/v1/billing/excel-billing`,
      payload,
      { headers: head, params }
    );
  }

  sendExcelFile(payload:any = {}): Observable<any>{
    const head = new HttpHeaders({ });
    const params = {
      timeZone: Utilities.getTimeZone()
    }
    return this._http.post<any>(
      `${this.BASE_URL}/backoffice-server/api/v1/billing/send-excel-email`,
      payload,
      { headers: head, params }
    );
  }
}
