<mat-card class="login-card">
    <mat-card-header>
        <div></div>
        <mat-card-title><img src="assets/img/Hogami_logo_color.svg"  class="login_logo" alt="{{'lm-login.images.login_logo_alt' | translate}}"></mat-card-title>
        <mat-card-subtitle>{{'lm-login.title' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="form">
    <mat-card-content>
        <div>
        <mat-form-field class="full-width">
            <mat-label>{{'lm-login.placeholders.username' | translate}}</mat-label>
            <input matInput placeholder="Email" formControlName="username" name="username" placeholder="{{'lm-login.placeholders.username' | translate}}" type="text"/>
            <mat-error *ngIf="isFieldInvalid('username')">
                {{'lm-login.messages.enter_your_username' | translate}}
            </mat-error>
        </mat-form-field>
        </div>
        <div>
        <mat-form-field class="full-width">
            <mat-label>{{'lm-login.placeholders.password' | translate}}</mat-label>
            <input  matInput formControlName="password" name="password" placeholder="{{'lm-login.placeholders.password' | translate}}" type="password"/>
            <mat-error *ngIf="isFieldInvalid('password')">
                {{'lm-login.messages.enter_the_password' | translate}}
            </mat-error>
        </mat-form-field>
        </div>
        <mat-error *ngIf="errorLogin" class="alert alert-danger">{{'lm-login.messages.error' | translate}}</mat-error>
    </mat-card-content>
    <mat-card-actions class="mdc-card">
        <button mat-raised-button [disabled]="loading" color="primary" type="submit"><mat-icon>login</mat-icon> {{'lm-login.button_enter' | translate}}</button>
    </mat-card-actions>

    </form>
</mat-card>






