<div *ngIf="totalRating > 0">
  <div class="title">
    <h2>
      <p>
        {{'lm-professional.lm-rating.title_feedback_from_real_customers_v1' | translate}}
      </p>
    </h2>
  </div>

  <div *ngIf="hasRatings" class="meter">
    <span style="width:80%;"><span class="progress" [style.display]="!averageComplete===true ? 'block' : 'none'"></span></span>
  </div>

  <div class="grid" *ngIf="averageComplete">
    <div type="submit" class="grid__right">

      <div class="grid__avatar">
        <div class="grid__avatar">
          <img alt="{{'lm-professional.lm-rating.image_alt' | translate}}" class="grid__avatar__profimg"
            src="{{avatar}}">
        </div>
        <div class="grid__avatar_prof">
          <p>{{ professionaName }} {{ professionalLastName }}</p>
        </div>
      </div>
      <div class="grid__avatar">
        <p>{{ totalRating }} {{'lm-professional.lm-rating.title_feedback_from_real_customers_v2' | translate}}</p>
      </div>

      <div class="grid__container_start" *ngIf="arrayRating.length>0">
        <figure class="grid__fig" *ngFor="let item of arrayRating">
          <img src="{{item.img}}" alt="" class="grid__item">
        </figure>
        <button type="button" (click)="openClose()" class="grid__dropdown"
          [ngClass]="{'grid__dropdown--open': !open, 'grid__dropdown--close':open}">
        </button>
      </div>
      <div class="grid__container_start__resume" *ngIf="open">
        <div class="grid__container_start__resume__stars">
          <div class="grid__container_start__resume__text" >
            <p class="lm-opinion-module-item__text">{{ arrayOpinion[0].title | uppercase }}</p>
            <div class='grid__container_start__resume__star'>
              <figure class="grid__container_start__resume__star_fig" *ngFor="let item of arrayRatingPuntualLeft">
                <img src="{{item.img}}" alt="" class="grid__container_start__resume__star__img">
              </figure>
            </div>
          </div>
          <div class="grid__container_start__resume__text" >
            <p class="lm-opinion-module-item__text">{{ arrayOpinion[1].title | uppercase }}</p>
            <div class='grid__container_start__resume__star'>
              <figure class="grid__container_start__resume__star_fig" *ngFor="let item of arrayRatingTreatmentLeft">
                <img src="{{item.img}}" alt="" class="grid__container_start__resume__star__img">
              </figure>
            </div>
          </div>
          <div class="grid__container_start__resume__text" >
            <p class="lm-opinion-module-item__text">{{ arrayOpinion[2].title | uppercase }}</p>
            <div class='grid__container_start__resume__star'>
              <figure class="grid__container_start__resume__star_fig" *ngFor="let item of arrayRatingCleanLeft">
                <img src="{{item.img}}" alt="" class="grid__container_start__resume__star__img">
              </figure>
            </div>
          </div>
          <div class="grid__container_start__resume__text" >
            <p class="lm-opinion-module-item__text">{{ arrayOpinion[3].title | uppercase }}</p>
            <div class='grid__container_start__resume__star'>
              <figure class="grid__container_start__resume__star_fig" *ngFor="let item of arrayRatingQualityLeft">
                <img src="{{item.img}}" alt="" class="grid__container_start__resume__star__img">
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div type="submit" class="grid__left">
      <!-- Item 1 -->
      <div style="margin-bottom: 20px;" *ngIf="arrayOpinionRight[0]">
        <p class="grid__container_detail__title_date" >{{ arrayOpinionRight[0].date }}</p>
        <p class="grid__container_detail__title_prof">{{ arrayOpinionRight[0].title }}</p>

        <div class="grid__container_start" *ngIf="arrayOpinionRight[0].ratingStars">
          <figure class="grid__fig" *ngFor="let stars of arrayOpinionRight[0].ratingStars">
            <img src="{{ stars.img }}" alt="" class="grid__iteml">
          </figure>
          <button type="button" (click)="openCloseOpinionOne()" class="grid__dropdown"
            [ngClass]="{'grid__dropdown--open': !mainOpinionOne, 'grid__dropdown--close':mainOpinionOne}">
          </button>
        </div>

        <div class="grid__container_start__resume" *ngIf="mainOpinionOne" >
          <div class="grid__container_start__resume__stars">
            
            <!-- Punctual -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[0].arrayOpinion[0].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[0][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[0][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[0][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[0][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[0][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Behavior -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[0].arrayOpinion[1].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[1][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[1][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[1][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[1][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[1][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Clean -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[0].arrayOpinion[2].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[2][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[2][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[2][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[2][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[2][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Satisfied -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[0].arrayOpinion[3].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[3][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[3][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[3][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[3][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[0].stars[3][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

          </div>
        </div>

        <p class="grid__container_detail__opinion">{{ arrayOpinionRight[0].opinion }}</p>

      </div>

      <!-- Item 2 -->
      <div style="margin-bottom: 20px;" *ngIf="arrayOpinionRight[1]">
        <p class="grid__container_detail__title_date" >{{ arrayOpinionRight[1].date }}</p>
        <p class="grid__container_detail__title_prof">{{ arrayOpinionRight[1].title }}</p>

        <div class="grid__container_start" *ngIf="arrayOpinionRight[1].ratingStars">
          <figure class="grid__fig" *ngFor="let stars of arrayOpinionRight[1].ratingStars">
            <img src="{{ stars.img }}" alt="" class="grid__iteml">
          </figure>
          <button type="button" (click)="openCloseOpinionTwo()" class="grid__dropdown"
            [ngClass]="{'grid__dropdown--open': !mainOpinionTwo, 'grid__dropdown--close':mainOpinionTwo}">
          </button>
        </div>

        <div class="grid__container_start__resume" *ngIf="mainOpinionTwo" >
          <div class="grid__container_start__resume__stars">
            
            <!-- Punctual -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[1].arrayOpinion[0].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[0][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[0][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[0][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[0][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[0][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Behavior -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[1].arrayOpinion[1].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[1][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[1][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[1][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[1][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[1][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Clean -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[1].arrayOpinion[2].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[2][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[2][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[2][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[2][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[2][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Satisfied -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[1].arrayOpinion[3].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[3][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[3][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[3][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[3][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[1].stars[3][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

          </div>
        </div>

        <p class="grid__container_detail__opinion">{{ arrayOpinionRight[1].opinion }}</p>

      </div>

      <!-- Item 3 -->
      <div style="margin-bottom: 20px;" *ngIf="arrayOpinionRight[2]">
        <p class="grid__container_detail__title_date" >{{ arrayOpinionRight[2].date }}</p>
        <p class="grid__container_detail__title_prof">{{ arrayOpinionRight[2].title }}</p>

        <div class="grid__container_start" *ngIf="arrayOpinionRight[2].ratingStars">
          <figure class="grid__fig" *ngFor="let stars of arrayOpinionRight[2].ratingStars">
            <img src="{{ stars.img }}" alt="" class="grid__iteml">
          </figure>
          <button type="button" (click)="openCloseOpinionThree()" class="grid__dropdown"
            [ngClass]="{'grid__dropdown--open': !mainOpinionThree, 'grid__dropdown--close':mainOpinionThree}">
          </button>
        </div>

        <div class="grid__container_start__resume" *ngIf="mainOpinionThree" >
          <div class="grid__container_start__resume__stars">
            
            <!-- Punctual -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[2].arrayOpinion[0].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[0][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[0][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[0][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[0][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[0][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Behavior -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[2].arrayOpinion[1].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[1][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[1][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[1][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[1][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[1][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Clean -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[2].arrayOpinion[2].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[2][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[2][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[2][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[2][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[2][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Satisfied -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[2].arrayOpinion[3].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[3][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[3][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[3][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[3][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[3][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

          </div>
        </div>

        <p class="grid__container_detail__opinion">{{ arrayOpinionRight[2].opinion }}</p>

      </div>

      <!-- Item 4 -->
      <div style="margin-bottom: 20px;" *ngIf="arrayOpinionRight[3]">
        <p class="grid__container_detail__title_date" >{{ arrayOpinionRight[3].date }}</p>
        <p class="grid__container_detail__title_prof">{{ arrayOpinionRight[3].title }}</p>

        <div class="grid__container_start" *ngIf="arrayOpinionRight[3].ratingStars">
          <figure class="grid__fig" *ngFor="let stars of arrayOpinionRight[3].ratingStars">
            <img src="{{ stars.img }}" alt="" class="grid__iteml">
          </figure>
          <button type="button" (click)="openCloseOpinionFour()" class="grid__dropdown"
            [ngClass]="{'grid__dropdown--open': !mainOpinionFour, 'grid__dropdown--close':mainOpinionFour}">
          </button>
        </div>

        <div class="grid__container_start__resume" *ngIf="mainOpinionFour" >
          <div class="grid__container_start__resume__stars">
            
            <!-- Punctual -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[3].arrayOpinion[0].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[0][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[0][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[0][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[0][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[0][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Behavior -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[3].arrayOpinion[1].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[1][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[1][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[1][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[1][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[1][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Clean -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[3].arrayOpinion[2].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[2][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[2][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[2][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[2][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[2][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Satisfied -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[3].arrayOpinion[3].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[3][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[3][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[3][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[3][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[3].stars[3][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

          </div>
        </div>

        <p class="grid__container_detail__opinion">{{ arrayOpinionRight[3].opinion }}</p>

      </div>

      <!-- Item 5 -->
      <div style="margin-bottom: 20px;" *ngIf="arrayOpinionRight[4]">
        <p class="grid__container_detail__title_date" >{{ arrayOpinionRight[4].date }}</p>
        <p class="grid__container_detail__title_prof">{{ arrayOpinionRight[4].title }}</p>

        <div class="grid__container_start" *ngIf="arrayOpinionRight[4].ratingStars">
          <figure class="grid__fig" *ngFor="let stars of arrayOpinionRight[4].ratingStars">
            <img src="{{ stars.img }}" alt="" class="grid__iteml">
          </figure>
          <button type="button" (click)="openCloseOpinionFive()" class="grid__dropdown"
            [ngClass]="{'grid__dropdown--open': !mainOpinionFive, 'grid__dropdown--close':mainOpinionFive}">
          </button>
        </div>

        <div class="grid__container_start__resume" *ngIf="mainOpinionFive" >
          <div class="grid__container_start__resume__stars">
            
            <!-- Punctual -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[4].arrayOpinion[0].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[0][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[0][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[0][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[0][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[2].stars[0][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Behavior -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[4].arrayOpinion[1].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[1][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[1][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[1][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[1][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[1][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Clean -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[4].arrayOpinion[2].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[2][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[2][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[2][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[2][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[2][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

            <!-- Satisfied -->
            <div class="grid__container_start__resume__text" >
              <p class="lm-opinion-module-item__text">{{ arrayOpinionRight[4].arrayOpinion[3].title | uppercase }}</p>
              <div class='grid__container_start__resume__star'>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[3][0].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[3][1].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[3][2].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[3][3].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
                <figure class="grid__container_start__resume__star_fig">
                  <img src="{{arrayOpinionRight[4].stars[3][4].img}}" alt="" class="grid__container_start__resume__star__img">
                </figure>
              </div>
            </div>

          </div>
        </div>

        <p class="grid__container_detail__opinion">{{ arrayOpinionRight[4].opinion }}</p>

      </div>

      <div class="buttons">
        <button class="buttons__button_ant" *ngIf="currentPage>=1" (click)="showLess()">{{'lm-professional.lm-rating.buttons.previous' | translate}}</button>
        <button class="buttons__button" *ngIf="currentPage < totalPages-1" (click)="showMore()">{{'lm-professional.lm-rating.buttons.next' | translate}}</button>
      </div>

    </div>
  </div>
</div>
<div class="title" *ngIf="!hasRatings && !dataIsLodading">
  {{'lm-professional.lm-rating.error_message_no_opinion' | translate}} {{ professionaName }} {{ professionalLastName }}
</div>