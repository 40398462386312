<div class="content">
<mat-card class="small-card"   *ngIf="isUserLeroy">
  <mat-card-header>
    <div></div>
    <mat-card-title>{{'lm-create-user.title' | translate}}</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <form (ngSubmit)="createUser(form.value)" [formGroup]="form" class="form" >
    <mat-card-content>
      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'lm-create-user.placeholders.email' | translate}}</mat-label>
          <input matInput formControlName="email" name="email" placeholder="{{'lm-create-user.placeholders.email' | translate}}" type="text"/>
        </mat-form-field>
        <div *ngIf="submitted && f.email.errors" class="redTextLeft">
          <div *ngIf="f.email.errors.required">{{'lm-create-user.messages.email_required' | translate}}</div>
          <div *ngIf="f.email.errors.email">{{'lm-create-user.messages.email_no_valid' | translate}}</div>
        </div>
        <mat-form-field class="full-width">
          <mat-label>{{'lm-create-user.placeholders.password' | translate}}</mat-label>
          <input matInput formControlName="password" name="password" placeholder="{{'lm-create-user.placeholders.password' | translate}}" type="password"/>
        </mat-form-field>
        <div *ngIf="submitted && f.password.errors" class="redTextLeft">
          <div *ngIf="f.password.errors.required">{{'lm-create-user.messages.password_required' | translate}}</div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" color="primary" [disabled]="isDisabled" class="button_submit"><mat-icon>person_add_alt</mat-icon> {{'lm-create-user.buttons.create_user' | translate}}</button>
      <p *ngIf="isError"class="redText">{{ message }}</p>
      <p *ngIf="!isError"class="greenText">{{ message }}</p>
    </mat-card-actions>
  </form>
</mat-card>

  <mat-card class="small-card" *ngIf="!isUserLeroy">
    <mat-card-header>
      <div></div>
      <mat-card-title>{{'lm-create-user.title' | translate}}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="divLeroy" >
        <div class="divCenter">
          <h2>{{'lm-create-user.message_error_no_authorized_perform_action' | translate}}</h2>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button type="button" (click)="goBack()" class="button_back"> {{'lm-create-user.buttons.accept' | translate}} </button>
    </mat-card-actions>
  </mat-card>
</div>