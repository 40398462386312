<div class="content">
  <mat-card class="card">
    <mat-card-header>
      <div mat-card-avatar class="professional_image"><img mat-card-image src="{{data.avatar}}" alt=""></div>
      <mat-card-title>{{data.name}} {{data.lastName}}</mat-card-title>
      <mat-card-subtitle>{{data.email}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="hasRating" >{{'lm-professional.professional-details.title.part1' | translate}} {{rating?.averageGeneralRating}}/5 {{'lm-professional.professional-details.title.part2' | translate}} {{rating?.totalAverage}} {{'lm-professional.professional-details.title.part3' | translate}} </mat-card-subtitle>
      <mat-card-subtitle *ngIf="currentStatus">
        <div>
          <div class="column">
            {{'lm-professional.professional-details.title.part4' | translate}} 
          </div>
          <div class="column2">
            &nbsp; <label class="textStatus"> {{currentStatus.statusDescription}} </label><mat-icon aria-hidden="false" class="edit" *ngIf="isUserLeroy"  (click)="statusHistory()" >edit</mat-icon>
          </div>
        </div>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <mat-tab-group animationDuration="0ms" #tabGroup>
        <mat-tab label="{{'lm-professional.professional-details.tab_group.basic_data' | translate}}">
          <ng-template matTabContent>
            <app-lm-professional-basic-data (editPaycometDocumentsEvent)="openProfessionalDocuments($event)">
            </app-lm-professional-basic-data>
          </ng-template>
  
        </mat-tab>
        <mat-tab label="{{'lm-professional.professional-details.tab_group.request' | translate}}">
          <ng-template matTabContent>
            <app-lm-professional-requests [filtersData]="filtersData">
            </app-lm-professional-requests>
          </ng-template>
  
        </mat-tab>
        <mat-tab label="{{'lm-professional.professional-details.tab_group.operations' | translate}}">
          <ng-template matTabContent>
            <app-lm-professional-operations [filtersData]="filtersData">
            </app-lm-professional-operations>
          </ng-template>
  
  
        </mat-tab>
        <mat-tab label="{{'lm-professional.professional-details.tab_group.ratings' | translate}}">
          <ng-template matTabContent>
            <app-lm-rating [data]="ratingData"> </app-lm-rating>
          </ng-template>
  
  
        </mat-tab>
        <mat-tab label="{{'lm-professional.professional-details.tab_group.services' | translate}}">
          <ng-template matTabContent>
            <app-lm-professional-services [filtersData]="filtersData">
            </app-lm-professional-services>
          </ng-template>
  
  
        </mat-tab>
        <mat-tab label="{{'lm-professional.professional-details.tab_group.status_timeline' | translate}}" *ngIf="currentStatus">
          <ng-template matTabContent>
            <app-lm-professional-status (refreshProfData)="getProfessionalById(professionalId)" [filtersData]="filtersData">
            </app-lm-professional-status>
          </ng-template>
  
  
        </mat-tab>

        <mat-tab label="{{'lm-professional.professional-details.tab_group.paycomet_documents' | translate}}" *ngIf="editPaycometDocuments">
          <ng-template matTabContent>
            <app-lm-professional-documents [(editPaycometDocuments)]="editPaycometDocuments" (editPaycometDocumentsEvent)="closeProfessionalDocuments($event)">
            </app-lm-professional-documents>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>