import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';

@Component({
  selector: 'app-lm-chat',
  templateUrl: './lm-chat.component.html',
  styleUrls: ['./lm-chat.component.scss']
})
export class LmChatComponent implements OnChanges, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  chats: any;
  chatId: string;
  professional: string;
  client: string;
  loadData: boolean;
  @Input() data: any;
  displayedColumns: string[] = ['message'];

  constructor(
    private _transfer: TransferService, private _translateService: TranslateService) {
      this._translationSubscription = this._translateService
      .get('lm-journey-details.sections.lm-chat.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
     }
    ngOnDestroy(): void {
      this._translationSubscription.unsubscribe();
    }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData = true;
    this.chatId = this.data['chatId'];
    this.professional = this.data['professional'];
    this.client = this.data['client'];
    this.showChats();
  }


  showChats() {
    if (this.data && this.data.chatId) {
      this._transfer.findAllMessagesByChatId(this.data['chatId']).subscribe(res => {
        if (res['data']['messages']) {
          this.chats = res['data']['messages'].filter(message => message.type != "CONFIRM_WORK");
        }
        this.loadData = false;
      }, error => {
        alert(this._messages['conversation_not_found']);
      });
    }
  }

  refreshChat() {
    this.loadData = true;
    this.chats = [];
    this.showChats();
  }


}
