<div class="nodeItems">
    <div class="divItemName">
        <label [ngClass]="{'editing': isEditable}">{{node.levelInfoRest.levelLabel}}</label>
        <mat-progress-bar *ngIf="isUpdating" mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
    </div>
    <div *ngIf="node?.costRest && !isCostEmpty(node?.costRest)" class="divItem">
        <mat-form-field style="text-align: center">
            <input matInput type="text" class="noeditable"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_first_price' | translate}}"
                [readonly]="true" [ngModel]="getFirstPrice" name="baseCost_{{node?.costRest?.id}}" />
        </mat-form-field>
        <div class="redTextLeft" *ngIf="errorBaseCost">
            <div>{{'lm-professional.lm-professional-services-hierarchy.incorrect_base_price' | translate}}</div>
        </div>
    </div>
    <div *ngIf="node?.costRest && !isCostEmpty(node?.costRest)" class="divItem">
        <mat-form-field style="text-align: center">
            <input matInput type="text" class="noeditable"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_aditionnal_price' | translate}}"
                [readonly]="true" [ngModel]="getAditionalPrice" name="baseCost_{{node?.costRest?.id}}" />
        </mat-form-field>
        <div class="redTextLeft" *ngIf="errorBaseCost">
            <div>{{'lm-professional.lm-professional-services-hierarchy.incorrect_base_price' | translate}}</div>
        </div>
    </div>
    <div *ngIf="node?.costRest && !isCostEmpty(node?.costRest)" class="divItem">
        <mat-form-field *ngIf="!isEditable" style="text-align: center">
            <input matInput type="text"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_base_price' | translate}}"
                value="{{node?.costRest?.baseCost}}" [readonly]="!isEditable" [ngModel]="getBaseCostWithSymbol"
                name="baseCost_{{node?.costRest?.id}}" />
        </mat-form-field>
        <mat-form-field *ngIf="isEditable" style="text-align: center">
            <input matInput type="number"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_base_price' | translate}}"
                value="{{node?.costRest?.baseCost}}" [readonly]="!isEditable" [(ngModel)]="node.costRest.baseCost"
                name="baseCost_{{node?.costRest?.id}}" (blur)="onBlurBaseCost($event)" />
            <mat-icon matSuffix class="icon-input">euro</mat-icon>
        </mat-form-field>
        <div class="redTextLeft" *ngIf="errorBaseCost">
            <div>{{'lm-professional.lm-professional-services-hierarchy.incorrect_base_price' | translate}}</div>
        </div>
    </div>
    <div *ngIf="node?.costRest && !isCostEmpty(node?.costRest)" class="divItem">
        <mat-form-field *ngIf="!isEditable" style="text-align: center">
            <input matInput type="text"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_displacement_fee' | translate}}"
                value="{{node?.costRest?.displacementFee}}" [readonly]="!isEditable || !displacementFeeBk"
                [ngModel]="getDisplacementWithSymbol" name="displacementFee_{{node?.costRest?.id}}" />
        </mat-form-field>
        <mat-form-field *ngIf="isEditable" style="text-align: center">
            <input matInput type="number"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_displacement_fee' | translate}}"
                value="{{node?.costRest?.displacementFee}}" [readonly]="!isEditable || !displacementFeeBk"
                [(ngModel)]="node.costRest.displacementFee" name="displacementFee_{{node?.costRest?.id}}"
                (blur)="onBlurDisplacementFee($event)" />
            <mat-icon matSuffix class="icon-input">euro</mat-icon>
        </mat-form-field>
        <div class="redTextLeft" *ngIf="errorDisplacementFee">
            <div>{{'lm-professional.lm-professional-services-hierarchy.incorrect_displacement_fee' | translate}}</div>
        </div>
    </div>
    <div *ngIf="node?.costRest && !isCostEmpty(node?.costRest)" class="divItemTax">
        <mat-form-field *ngIf="!isEditable" style="text-align: center">
            <input matInput type="text"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_tax' | translate}}"
                value="{{node?.costRest?.tax}}" [readonly]="!isEditable" [ngModel]="getTaxWithPercent"
                name="tax_{{node?.costRest?.id}}"/>
        </mat-form-field>
        <mat-form-field *ngIf="isEditable" style="text-align: center">
            <input matInput type="number"
                placeholder="{{'lm-professional.lm-professional-services-hierarchy.placeholder_tax' | translate}}"
                value="{{node?.costRest?.tax}}" [readonly]="!isEditable" [(ngModel)]="node.costRest.tax"
                name="tax_{{node?.costRest?.id}}" (blur)="onBlurTax($event)" />
            <mat-icon matSuffix class="icon-input">percent</mat-icon>
        </mat-form-field>
        <div class="redTextLeft" *ngIf="errorTax">
            <div>{{'lm-professional.lm-professional-services-hierarchy.incorrect_tax' | translate}}</div>
        </div>
    </div>
    <div *ngIf="!isCostEmpty(node?.costRest)" class="divItemButtom">
        <button mat-raised-button type="button" [disabled]="disableEdit" (click)="enableEdit(node?.costRest)"
            *ngIf="!isEditable"
            title="{{'lm-professional.lm-professional-services-hierarchy.title_button_edit' | translate}}"
            color="default" class="divItemButtom__buttons">
            <mat-icon class="material-icons">edit</mat-icon>
        </button>
        <button mat-raised-button type="button" (click)="updateCost(node?.costRest, node?.levelInfoRest.levelId)"
            *ngIf="isEditable" [disabled]="errorBaseCost || errorDisplacementFee || errorTax"
            title="{{'lm-professional.lm-professional-services-hierarchy.title_button_save' | translate}}"
            color="primary" class="divItemButtom__buttons">
            <mat-icon class="material-icons">save</mat-icon>
        </button>
        <button mat-raised-button type="button" (click)="cancelEdit(node?.costRest)" *ngIf="isEditable"
            title="{{'lm-professional.lm-professional-services-hierarchy.title_button_cancel' | translate}}"
            color="warn" class="divItemButtom__buttons">
            <mat-icon class="material-icons">cancel</mat-icon>
        </button>
    </div>
</div>