import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { LmJourneyBasicDataComponent } from "./sections/lm-journey-basic-data/lm-journey-basic-data.component";
import { LmTransfersRoutingModule } from "../lm-transfers/lm-transfers-routing.component";
import { LmJourneyDetailsComponent } from "./component/lm-journey-details.component";
import { LmJourneyDetailsRoutingModule } from "./lm-journey-details-routing.module";
import { LmJourneyTransactionsComponent } from "./sections/lm-journey-transactions/lm-journey-transactions.component";
import { LmJourneyTimelineComponent } from "./sections/lm-journey-timeline/lm-journey-timeline.component";
import { LmJourneyWorkComponent } from "./sections/lm-journey-work/lm-journey-work.component";
import { LmChatComponent } from './sections/lm-chat/lm-chat.component';
import { ImagesDialogComponent } from "../lm-transfers/sections/images-dialog/images-dialog.component";
import { LmUpdateRatingComponent } from './sections/lm-update-rating/lm-update-rating.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
	declarations: [
		LmJourneyDetailsComponent,
		LmJourneyBasicDataComponent,
		LmJourneyTimelineComponent,
		LmJourneyTransactionsComponent,
		LmJourneyWorkComponent,
		LmChatComponent,
		LmUpdateRatingComponent
		
	],
	imports: [
		LmTransfersRoutingModule,
		CommonModule,
		MatTableModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatToolbarModule,
		MatDialogModule,
		MatInputModule,
		MatDatepickerModule,
		MatIconModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatButtonModule,
		FormsModule,
		ReactiveFormsModule,
		MatPaginatorModule,
		MatCheckboxModule,
		MatRadioModule,
		MatMenuModule,
		MatSelectModule,
		MatOptionModule,
		MatCardModule,
		MatExpansionModule,
		NgMultiSelectDropDownModule,
		MatTabsModule,
		LmJourneyDetailsRoutingModule,
		TranslateModule,
		MatSnackBarModule
	],
	entryComponents: [ImagesDialogComponent],
	providers: [
		{
			provide: MatDialogRef,
			useValue: {}
		},
		MatDatepickerModule
	]
})

export class LmJourneyDetailsModule { }
