<div class="basic-data-content">
  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-client.lm-client-basic-data.titles.client_data' | translate}}</h4>
    </div>
    <div class="card-content">
      <div>
        <strong>{{'lm-client.lm-client-basic-data.client_info.labels.name' | translate}}</strong>&nbsp; {{clientInformation.name}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.client_info.labels.lastname' | translate}}</strong>&nbsp; {{clientInformation.lastName}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.client_info.labels.email' | translate}}</strong>&nbsp; {{clientInformation.email}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.client_info.labels.documentType' | translate}}</strong>&nbsp; {{clientInformation.clientDocumentType.documentType}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.client_info.labels.documentNumber' | translate}}</strong>&nbsp; {{clientInformation.documentNumber}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.client_info.labels.phones' | translate}}</strong>&nbsp; 
        <div *ngFor="let item of clientInformation.phones">
          {{item.countryCode}} {{item.number}}
        </div>
      </div>
    </div>
  </div>

  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-client.lm-client-basic-data.titles.addresses' | translate}}</h4>
    </div>
    <div class="card-content">
      <div *ngFor="let item of clientInformation.addresses">
        <div *ngIf="item.addressTypeRest && item.addressTypeRest.name === 'BILL'" style="display: flow-root;">
          <strong>{{'lm-client.lm-client-basic-data.addresses.labels.address_billing' | translate}}</strong>&nbsp;
          {{item.address}} {{item.complementAddress}} {{item.city}} {{item.province}} ES
        </div>
        <div *ngIf="item.addressTypeRest && item.addressTypeRest.name === 'RESD'" style="display: flow-root;">
          <strong>{{'lm-client.lm-client-basic-data.addresses.labels.address_resd' | translate}}</strong>&nbsp;
          {{item.address}} {{item.complementAddress}} {{item.city}} {{item.province}} ES
        </div>
        <div *ngIf="item.addressTypeRest && item.addressTypeRest.name === 'OPER'" style="display: flow-root;">
          <strong>{{'lm-client.lm-client-basic-data.addresses.labels.address_oper' | translate}}</strong>&nbsp;
          {{item.address}} {{item.complementAddress}} {{item.city}} {{item.province}} ES
        </div>
      </div>
    </div>
  </div>

  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-client.lm-client-basic-data.titles.billing_information' | translate}}</h4>
    </div>
    <div class="card-content" *ngIf="clientInformation.billingData">
      <div>
        <strong>{{'lm-client.lm-client-basic-data.billing_data.labels.name' | translate}}</strong>&nbsp; {{clientInformation.billingData.name}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.billing_data.labels.lastname' | translate}}</strong>&nbsp; {{clientInformation.billingData.lastName}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.billing_data.labels.clientType' | translate}}</strong>&nbsp; 
        <label *ngIf="clientInformation.billingData.clientType.name === 'PART'"> Particular </label>
        <label *ngIf="clientInformation.billingData.clientType.name === 'FREE'"> Freelance </label>
        <label *ngIf="clientInformation.billingData.clientType.name === 'COMP'"> Company </label>
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.billing_data.labels.documentType' | translate}}</strong>&nbsp; {{clientInformation.billingData.clientDocumentType.documentType}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.billing_data.labels.document' | translate}}</strong>&nbsp; {{clientInformation.billingData.documentNumber}}
      </div>
      <div>
        <strong>{{'lm-client.lm-client-basic-data.billing_data.labels.phone' | translate}}</strong>&nbsp; {{clientInformation.billingData.countryCode}} {{clientInformation.billingData.phone}}
      </div>
    </div>
  </div>

  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-client.lm-client-basic-data.titles.options' | translate}}</h4>
    </div>
    <div class="card-content">
      <mat-checkbox [checked]="clientInformation.acceptTerms?.acceptTermsAndConditions"
        [disabled]="true">{{'lm-client.lm-client-basic-data.options.labels.accept_terms_conditions' |
        translate}}</mat-checkbox><br>
      <mat-checkbox [checked]="clientInformation.acceptTerms?.acceptPrivacyPolicy"
        [disabled]="true">{{'lm-client.lm-client-basic-data.options.labels.accept_privacy_policy' |
        translate}}
      </mat-checkbox><br>
      <mat-checkbox [checked]="clientInformation.acceptTerms?.acceptCommunications"
        [disabled]="true">{{'lm-client.lm-client-basic-data.options.labels.accept_communication' |
        translate}}
      </mat-checkbox>
    </div>
  </div>
</div>

<div class="footer-data">
  <div class="section">
  </div>
  <div class="section">
  </div>
  <div class="section">
    <div *ngIf="!isEditInfo">
      <button mat-raised-button (click)="openUpdateClientData()">
        <mat-icon>edit</mat-icon> Editar
      </button>
    </div>
  </div>
</div>