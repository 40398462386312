import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	form: FormGroup;
    router: Router;

	constructor(
	    public _router: Router, private _translateService: TranslateService) {
	
		this.router = _router;
	}

	ngOnInit() {
		this._translateService.setDefaultLang(environment.defaultLocale);
		this._translateService.use(environment.defaultLocale);
		this.form = new FormGroup({
			access_token: new FormControl(null)
		});
	}
}
