<div class="content">
<mat-card class="small-card"   *ngIf="isUserLeroy">
  <mat-card-header>
    <div></div>
    <mat-card-title>{{'lm-ban-professional.title' | translate}}</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <form (ngSubmit)="banProfesional(form.value)" [formGroup]="form" class="form">
    <mat-card-content>
      <div>
        <mat-form-field class="full-width">
          <input matInput formControlName="email" name="email" placeholder="{{'lm-ban-professional.placeholder_email' | translate}}" type="text"/>

        </mat-form-field>
        <div *ngIf="submitted && f.email.errors" class="redTextLeft">
          <div *ngIf="f.email.errors.required">{{'lm-ban-professional.messages.required_email' | translate}}</div>
          <div *ngIf="f.email.errors.email">{{'lm-ban-professional.messages.no_valid_format_email' | translate}}</div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" color="primary" [disabled]="isDisabled"><mat-icon>person_off</mat-icon> {{'lm-ban-professional.button_ban' | translate}}</button>
      <p *ngIf="isError"class="redText">{{ message }}</p>
      <p *ngIf="!isError"class="greenText">{{ message }}</p>
    </mat-card-actions>
  </form>
</mat-card>

  <mat-card class="small-card" *ngIf="!isUserLeroy">
    <mat-card-header>
      <div></div>
      <mat-card-title>{{'lm-ban-professional.title' | translate}}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="divLeroy" >
        <div class="divCenter">
          <h2>{{'lm-ban-professional.messages.message_error_no_authorized_perform_action' | translate}}</h2>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button type="button" (click)="goBack()" class="button_back"> {{'lm-ban-professional.button_accepter' | translate}} </button>
    </mat-card-actions>
  </mat-card>
</div>