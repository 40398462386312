import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ProfessionalService } from '../../../services/professional.service';
import { ProfessionalLevel, UpdateLevelCost, Cost } from '../../../models/lm-professional.model';;
import { RoundUtilPipe } from '../../../../../shared/pipes/round-util.pipe';

@Component({
    selector: 'app-lm-tree-data-hierarchy',
    templateUrl: './lm-tree-data-hierarchy.component.html',
    styleUrls: ['./lm-tree-data-hierarchy.component.scss', '../lm-professional-services-hierarchy.component.scss']
})
export class LmTreeDataHierarchyComponent implements OnInit, OnDestroy {

    @Input() node: any;
    @Output() updateCostEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    isEditable: boolean;
    isUpdating: boolean;

    @Input() disableEdit: boolean;
    @Output() disableEditChange = new EventEmitter<boolean>();

    updateLevelCost: UpdateLevelCost;
    baseCostBk: number;
    displacementFeeBk: number;
    taxBk: number;

    errorBaseCost: boolean;
    errorDisplacementFee: boolean;
    errorTax: boolean;

    regex = /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/;

    constructor(private _professionalService: ProfessionalService,
        private _roundUtil : RoundUtilPipe) { }

    ngOnInit() {
    }

    ngOnDestroy(): void {
    }

    hasChild = (_: number, node: ProfessionalLevel) => !!node.levelRests && node.levelRests.length > 0;

    get getFirstPrice(){ return this.calculateFirstPrice(this.node['costRest']) }

    get getAditionalPrice(){ return this.calculateAditionalPrice(this.node['costRest']) }
    
    get getBaseCostWithSymbol() {return this.node['costRest']['baseCost'] + ' €';}
    
    get getDisplacementWithSymbol() {return this.node['costRest']['displacementFee'] + ' €';}

    get getTaxWithPercent() { return this.node['costRest']['tax'] + ' %'; }
    
    isCostEmpty(cost: Cost): boolean {
        let empty = false;
        if (cost == undefined) {
            empty = true;
            return empty;
        }
        if (!Object.keys(cost).length) {
            empty = true;
        }
        return empty;
    }

    enableEdit(cost: Cost) {
        this.isEditable = true;
        this.disableEditChange.emit(true);
        this.disableEdit = false;
        this.saveBkCost(cost);
    }

    saveBkCost(cost: Cost): void {
        const bkBaseCost = cost.baseCost ? cost.baseCost : null;
        const bkDisplacementFee = cost.displacementFee ? cost.displacementFee : null;
        const bkTax = cost.tax ? cost.tax : null;
        this.baseCostBk = bkBaseCost;
        this.displacementFeeBk = bkDisplacementFee;
        this.taxBk = bkTax;
    }

    updateCost(cost: Cost, professionalServiceId: any) {
        this.isUpdating = true;
        this.updateLevelCost = {
            idProfessionalServiceLevel: professionalServiceId,
            baseCost: cost.baseCost,
            currency: cost.currency,
            displacementFee: cost.displacementFee,
            tax: cost.tax
        }
        this._professionalService.updateProfessionalHierarchyPrice(cost.id, this.updateLevelCost).subscribe(res => {
            this.updateCostEvent.emit(true);
            this.disableEditChange.emit(false);
            this.isEditable = false;
            this.isUpdating = false;
        }, error => {
            this.updateCostEvent.emit(false);
            this.disableEditChange.emit(false);
            this.isEditable = false
            this.isUpdating = false;
        });
    }

    cancelEdit(cost: any) {
        this.isEditable = false;
        cost.baseCost = this.baseCostBk;
        cost.displacementFee = this.displacementFeeBk;
        cost.tax = this.taxBk;
        this.disableEditChange.emit(false);
        this.errorBaseCost = false;
        this.errorDisplacementFee = false;
        this.errorTax = false;
    }

    //Validation

    onBlurBaseCost(event) {
        this.errorBaseCost = true;
        const value = event.target.value
        if (this.regex.test(value)) {
            this.errorBaseCost = false;
        }
    }

    onBlurDisplacementFee(event) {
        if (this.displacementFeeBk == null) {
            this.errorDisplacementFee = false;
            return;
        }
        this.errorDisplacementFee = true;
        const value = event.target.value
        if (this.regex.test(value)) {
            this.errorDisplacementFee = false;
        }
    }

    onBlurTax(event) {
        this.errorTax = true;
        const value = event.target.value
        if (this.regex.test(value)) {
            this.errorTax = false;
        }
    }

    calculateFirstPrice(cost: Cost): string {
        let firstPrice = 0;
        firstPrice = cost.baseCost + (cost.baseCost * cost.tax) / 100;
        return this._roundUtil.transform(firstPrice, 2) + ' €';
    }

    calculateAditionalPrice(cost: Cost): string {
        let price = 0;
        let secondPrice = 0;
        let aditionalPrice = 0;
        price = cost.baseCost + (cost.displacementFee ? cost.displacementFee : 0);
        secondPrice = (price * cost.tax / 100) + price;
        aditionalPrice = secondPrice - ((cost.baseCost * cost.tax / 100) + cost.baseCost);
        return +aditionalPrice.toFixed(2) + ' €';
    }

}