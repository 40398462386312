import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Utilities } from '../../../shared/utilities/utilities';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-lm-login',
	templateUrl: './lm-login.component.html',
	styleUrls: ['./lm-login.component.scss']
})
export class LmLoginComponent implements OnInit {
	form: FormGroup;
	private formSubmitAttempt: boolean;
	isEnvironmentProd: boolean;
	loading = false;
	errorLogin: boolean = false;
	returnUrl: string;

	constructor(
        private fb: FormBuilder,
        private _auth: AuthenticationService,
        private _localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private _router: Router
	) {
        // redirect to home if already logged in
        if (this._auth.isLoggedIn()) {
            this._router.navigate(['/home']);
        }
	}

	ngOnInit() {
	    this.loading = false;
	    this.form = this.fb.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
        });

         // get return url from route parameters or default to '/'
         this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

		if (environment.production){
			this.isEnvironmentProd = true;
		}
		else{
			this.isEnvironmentProd = false;
		}
	}

    isFieldInvalid(field: string) {
        return (
          (!this.form.get(field).valid && this.form.get(field).touched) ||
          (this.form.get(field).untouched && this.formSubmitAttempt)
        );
    }

    onSubmit() {
        if (this.form.valid) {
          this.login(this.form.value);
        }
        this.formSubmitAttempt = true;
    }

	login( user ) {
	    this.loading = true;
		this._auth.login( user.username, Utilities.encodeBase64( user.password ) )
		.pipe( first() ).subscribe(
			result => {
			    this._localStorageService.set( 'token', result.data.access_token );
				localStorage.setItem( 'token', result.data.access_token );
				this._auth.setSessionExpire('false');
				this._auth.token.next( result.data.access_token );
				this._router.navigate( [this.returnUrl] );
			},
			error => {
				this.errorLogin = true;
				this.loading = false;
				console.debug('error', error)
			}
		);
	}

}
