import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LmTransfersRoutingModule } from './lm-transfers-routing.component';

@NgModule({
	imports: [
		CommonModule,
		LmTransfersRoutingModule
	]
})

export class LmTransferModule { }
