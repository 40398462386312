<div class="content">
  <mat-expansion-panel  [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'lm-transfers.sections.lm-filters-billing.title' | translate}}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
  <form action="" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="filters_form_group">
    <label style="cursor: default">{{'lm-transfers.sections.lm-filters-billing.appointment_date' | translate}} </label>
    <div class="filter-dates">
      <mat-form-field>
        <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.date_from' | translate}}</mat-label>
        <input  matInput  [matDatepicker]="picker1" placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.date_from' | translate}}" formControlName="startTimeStart" name="startTimeStart" />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.date_to' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker2" placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.date_to' | translate}}" formControlName="startTimeEnd" name="startTimeEnd" />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <label style="cursor: default">{{'lm-transfers.sections.lm-filters-billing.end_date' | translate}} </label>
    <div class="filter-dates">
      <mat-form-field>
        <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.date_from' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker3"  placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.date_from' | translate}}" formControlName="endTimeStart" name="endTimeStart" />
        <mat-datepicker-toggle matSuffix  [for]="picker3" ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.date_to' | translate}}</mat-label>
        <input matInput  [matDatepicker]="picker4" placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.date_to' | translate}}" formControlName="endTimeEnd"  name="endTimeEnd"/>
        <mat-datepicker-toggle matSuffix [for]="picker4" ></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    </div>
    <div class="filters_form_group">
      <br>
      <div class="filter-operation">
        <mat-form-field>
          <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.operation_id' | translate}}</mat-label>
          <input matInput placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.operation_id' | translate}}" (keypress)="onChangeOperactionId($event)" type="text" formControlName="operationId" name="operationId">
        </mat-form-field>
      </div>
      <div class="radio-buttons-container">
        <mat-radio-group formControlName="operationType">
          <mat-radio-button value="facturables"    labelPosition="before" #facturables     (click)="operationTypeChange(facturables)"    >{{'lm-transfers.sections.lm-filters-billing.billable_operations' | translate}}</mat-radio-button>
          <mat-radio-button value="no_facturables" labelPosition="before" #no_facturables  (click)="operationTypeChange(no_facturables)" >   {{'lm-transfers.sections.lm-filters-billing.operation_no_billable' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="radio-buttons-container">
        <mat-radio-group formControlName="operationSubType">
          <mat-radio-button value="interna" [disabled]="!f.operationType" labelPosition="before" >{{'lm-transfers.sections.lm-filters-billing.operation_interna' | translate}}</mat-radio-button>
          <mat-radio-button value="externa" [disabled]="!f.operationType" labelPosition="before" >   {{'lm-transfers.sections.lm-filters-billing.operation_externa' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="filters_form_group">
      <br>
      <div>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.campaign' | translate}}" [data]="campaigns"
            formControlName="campaignSelected" name="campaignSelected" [settings]="dropdownSettingCampaigns">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <br>
      <div>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.stores' | translate}}" [data]="stores"
            formControlName="storeSelected" name="storeSelected" [settings]="dropdownSettingStores">
          </ng-multiselect-dropdown>
        </div>
        <div>
          <mat-form-field class="input-max">
            <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.commercial_id_ldap' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.commercial_id_ldap' | translate}}" type="text" formControlName="commercialId" name="commercialId" maxlength="10" (keypress)="onChangePhoneClent($event)">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="filters_form_group">
      <br>
      <div class="filter-operation">
        <mat-form-field>
          <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.coupon' | translate}}</mat-label>
          <input matInput placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.coupon' | translate}}" type="text" formControlName="couponCode" name="couponCode">
        </mat-form-field>
      </div>
    </div>

    <div class="button-row">
      <button  mat-raised-button  [disabled]="loadExcel || loading"  color="primary" type="submit" class="button_submit"><mat-icon>search</mat-icon> {{'lm-transfers.sections.lm-filters-billing.buttons.search' | translate}}</button>
      <button  mat-raised-button  [disabled]="loadExcel || loading"  type="reset" (click)="resetFilters()">{{'lm-transfers.sections.lm-filters-billing.buttons.clean' | translate}}</button>
        <button [disabled]="loadExcel || loading" mat-raised-button (click)="exportToExcel()"  style="float: right"><mat-icon>file_open</mat-icon> {{'lm-transfers.sections.lm-filters-billing.buttons.export_excel' | translate}}</button>
        <button [disabled]="loadExcel || loading" mat-raised-button (click)="sendExcel()"   style="float: right"><mat-icon>outbox</mat-icon>{{'lm-transfers.sections.lm-filters-billing.buttons.generate_file_and_send' | translate}}</button>
    </div>
  </form>
  </mat-expansion-panel>
  <mat-progress-bar *ngIf="loadExcel || loading" mode="indeterminate" value="40"></mat-progress-bar>
</div>