import { Subscription } from 'rxjs';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-lm-professional-status-dialog',
    templateUrl: './professional-status-dialog.component.html',
    styleUrls: ['./professional-status-dialog.component.scss', '../../../utils/modal.scss']
})
export class ProfessionalStatusDialogComponent implements OnInit {
    @Output() submitClicked = new EventEmitter<any>();
    _translateSubscription: Subscription;

    statusToChange: string;
    statusOperations: { description: string, allowed: boolean }[] = [];
    inProgress: boolean = false;
    hasResponse: boolean = false;
    msgResponse: string;
    typeError: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ProfessionalStatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any

    ) { }

    ngOnInit() {
        this.statusToChange = this.data['value']['statusDescription'];
        this.statusOperations = this.data['value']['statusOperations'];
    }

    submit() {
        this.inProgress = true;
        this.submitClicked.emit('submit');
    }

    dismiss() {
        this.dialogRef.close();
    }

    okSubmit() {
        this.submitClicked.emit('okSubmit');
    }

    myf(response, msgResponse, typeError) {
        this.inProgress = false;
        this.hasResponse = response['hasResponse'];
        this.msgResponse = msgResponse['msgResponse'];
        this.typeError = typeError['typeError'];
    }
}