<div class="confirm_dialog">
    <h2>
        <p>
            {{ data.value}}
        </p>
    </h2>
</div>

<div>
    <div class="grid">
      <button type="submit" class="grid__button_back" (click)="dismiss()">{{'modals.confirm-dialog.buttons.cancel' | translate}}</button>
      <button type="submit" class="grid__button" (click)="submit()">{{'modals.confirm-dialog.buttons.continu' | translate}}</button>
    </div>
  </div>