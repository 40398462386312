import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-message-dialog-component',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})

export class MessageDialogComponent implements OnInit {

    // @Output() submitClicked = new EventEmitter<any>();

    constructor(
        private router: Router) { }

    ngOnInit() {
    }

    submit() {
        localStorage.removeItem('token');
        localStorage.removeItem('sessionExpired');
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
}