import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RequestService } from '../../lm-transfers/services/request.service';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-lm-journey-details',
  templateUrl: './lm-journey-details.component.html',
  styleUrls: ['./lm-journey-details.component.scss']
})
export class LmJourneyDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('tabGroup') tab: MatTabGroup;

  _translationSubscription: Subscription;

  editRatingOperation: boolean;
  tabUpdateRatingOperation = 4;

  _status: { [key: string]: string };
  loading: boolean = false;
  data: any = {}
  id: any;
  operationId: any;
  rating: any;

  constructor(private _requestService: RequestService, private _route: ActivatedRoute, private _translateService: TranslateService) {
    this._translationSubscription = this._translateService
      .get('lm-journey-details.status')
      .subscribe((res: { [key: string]: string }) => {
        this._status = res;
      });
  }

  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.id = this._route.snapshot.paramMap.get('requestId');
    this.getMainInformation(this.id);
  }

  getMainInformation(id: number) {
    this._requestService.getMainInformation(id).then(res => {
      if (res.data) {
        this.data = res.data;
        this.operationId = res.data.operationId;
      }
    }).catch(err => {
      console.error(err);
    })
  }

  openEditRating($event): void {
    if ($event != null) {
      this.rating = $event;
      this.editRatingOperation = true;
      this.tab.selectedIndex = this.tabUpdateRatingOperation;
    }
  }

  closeEditRating($event): void {
    if ($event == null) {
      this.editRatingOperation = false;
      this.tab.selectedIndex = 0;
    }
  }

}
