<div class="content">
<mat-expansion-panel  [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{'lm-transfers.sections.lm-filters-request.title' | translate}}
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>
  </mat-expansion-panel-header>
    <form action="" [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="filters_form_group">
        <label style="cursor: default;">{{'lm-transfers.sections.lm-filters-request.date_creation' | translate}} </label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.date_from' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.date_from' | translate}}" formControlName="dateSinceStarttime" name="dateSinceStarttime">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker2" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.date_to' | translate}}" formControlName="dateUntilStarttime" name="dateUntilStarttime">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <label style="cursor: default;">{{'lm-transfers.sections.lm-filters-request.end_date' | translate}} </label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.date_from' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker3" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.date_from' | translate}}" formControlName="dateSinceEndtime" name="dateSinceEndtime">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker4" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.date_to' | translate}}" formControlName="dateUntilEndtime" name="dateUntilEndtime">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </mat-form-field>
        </div>

        <label style="cursor: default;">{{'lm-transfers.sections.lm-filters-request.appointment_date' | translate}}</label>
        <div class="filter-dates">
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.date_from' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker5" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.date_from' | translate}}" formControlName="operationDateStartTime" name="operationDateStartTime">
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.date_to' | translate}}</mat-label>
            <input matInput [matDatepicker]="picker6" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.date_to' | translate}}" formControlName="operationDateEndTime" name="operationDateEndTime">
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="filters_form_group">
        <br>
        <div>
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.request_id' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.request_id' | translate}}" type="text" formControlName="idType" name="idType">
          </mat-form-field>
        </div>
        <br>
        <div>
          <mat-form-field >
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.email_prof' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.email_prof' | translate}}" type="email" formControlName="profEmail" name="profEmail">
          </mat-form-field>
        </div>
        <br>
        <div>
          <mat-form-field >
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.email_client' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.email_client' | translate}}" type="email" formControlName="clientEmail" name="clientEmail">
          </mat-form-field>
        </div>
      </div>
      
      <div class="filters_form_group">
        <br>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.services' | translate}}" [data]="services"
            formControlName="serviceSelected" name="serviceSelected" [settings]="dropdownSettingsServices">
          </ng-multiselect-dropdown>
        </div>
        <br>
        <div>
          <mat-form-field class="mat-province">
            <mat-label>{{'lm-transfers.sections.lm-filters-request.province' | translate}}</mat-label>
            <mat-select formControlName="provinceSelected" name="provinceSelected">
              <mat-option value="">{{'lm-transfers.sections.lm-filters-request.all' | translate}}</mat-option>
              <mat-option *ngFor="let province of provinces" [value]="province.zipCode">{{province.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.states' | translate}}" [data]="statusFilters"
            formControlName="stateSelected" name="stateSelected" [settings]="dropdownSettingsStates">
          </ng-multiselect-dropdown>
        </div>
        <br>
        <div class="dropdown-div">
          <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.service_type' | translate}}" [data]="offeredServiceTypes"
            formControlName="offeredServiceTypeSelected" name="offeredServiceTypeSelected" [settings]="dropdownOfferedServiceTypes">
          </ng-multiselect-dropdown>
        </div>
      </div>
      
      <div class="filters_form_group">
        <br>
        <div class="phone_group">
          <span *ngIf="countrySelected" class="flag-icon flag-icon-{{countrySelected.flag}}"></span>
          <mat-form-field class="phone_group--field-country">
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.country' | translate}}</mat-label>
          <input
            type="text"
            matInput
            formControlName="countryCode"
            [matAutocomplete]="auto"
            size="2"
            maxlength="5"
            name="countryCode"
            placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.country' | translate}}"
            (click)="clickEvent()"
            (blur)="blurCountry($event)"
          >
          </mat-form-field>
          <mat-autocomplete #auto="matAutocomplete" [panelWidth]="'auto'" [displayWith]="displayFn" (optionSelected)='getOption($event.option.value)'>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.countryCode">
              <span class="flag-icon flag-icon-{{option.flag}}" style="margin-right: 5px;"></span>
              {{option.name}} ({{option.countryCode}})
              </mat-option>
          </mat-autocomplete>
          
          <mat-form-field>
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.tel_client' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.tel_client' | translate}}" type="text" formControlName="clientPhone" name="clientPhone" id="clientPhone" maxlength="{{phoneMaxLenght}}" (keypress)="onChangePhoneClent($event)">
          </mat-form-field>
        </div>
        <br>
        <div>
          <mat-form-field class="field-client-name">
            <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.first_last_name_client' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.first_last_name_client' | translate}}" type="text" formControlName="clientName" name="clientName" maxlength="50">
          </mat-form-field>
        </div>
        <br>
        <div>
          <mat-form-field class="field-client-name">
            <mat-label>{{'lm-transfers.sections.lm-filters-billing.placeholders.coupon' | translate}}</mat-label>
            <input matInput placeholder="{{'lm-transfers.sections.lm-filters-billing.placeholders.coupon' | translate}}" type="text" formControlName="couponCode" name="couponCode">
          </mat-form-field>
        </div>
      </div>

      <div class="filters_form_group">
        <br>
        <div>
          <div class="dropdown-div">
            <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.campaign' | translate}}" [data]="campaigns"
              formControlName="campaignSelected" name="campaignSelected" [settings]="dropdownSettingCampaigns">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <br>
        <div>
          <div class="dropdown-div">
            <ng-multiselect-dropdown class="lm-input" placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.stores' | translate}}" [data]="stores"
              formControlName="storeSelected" name="storeSelected" [settings]="dropdownSettingStores">
            </ng-multiselect-dropdown>
          </div>
          <br>
          <div>
            <mat-form-field class="input-max">
              <mat-label>{{'lm-transfers.sections.lm-filters-request.placeholders.commercial_id_ldap' | translate}}</mat-label>
              <input matInput placeholder="{{'lm-transfers.sections.lm-filters-request.placeholders.commercial_id_ldap' | translate}}" type="text" formControlName="commercialId" name="commercialId" maxlength="10" (keypress)="onChangePhoneClent($event)">
            </mat-form-field>
          </div>
        </div>
      </div>
      
      <div class="button-row">
        <button mat-raised-button [disabled]="loading || loadExcel" color="primary" type="submit" class="button_submit"><mat-icon>search</mat-icon> {{'lm-transfers.sections.lm-filters-request.buttons.search' | translate}}</button>
        <button mat-raised-button [disabled]="loading || loadExcel" type="reset" (click)="resetFilters()">{{'lm-transfers.sections.lm-filters-request.buttons.clean' | translate}}</button>
        <button mat-raised-button [disabled]="loading || loadExcel" (click)="exportToExcel()" class="float_right"><mat-icon>file_open</mat-icon> {{'lm-transfers.sections.lm-filters-request.buttons.export_excel' | translate}}</button>
      </div>
    </form>
</mat-expansion-panel>
<mat-progress-bar *ngIf="loading || loadExcel" mode="indeterminate" value="140"></mat-progress-bar>
</div>