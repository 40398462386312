<div class="content-wrapper" style="overflow-y: hidden; overflow-x: hidden;">
  <div class="work-data-wrapper">
    <div class="basic-data-content">
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4>{{'lm-journey-details.sections.lm-journey-work.cells.services_products' | translate}}</h4>
        </div>
        <div class="card-content">
          <table style="width: 100%;">
            <tr>
              <td>
                <strong>{{'lm-journey-details.sections.lm-journey-work.services_products.main_service' | translate}} </strong>{{data.mainService?.title}}<br />
                <mat-card-subtitle>({{data.mainService?.createDate | date: "dd/MM/yyyy hh:mm"}})</mat-card-subtitle>

              </td>
              <td>
                <div class="currency">{{data.mainService?.price}}</div>
              </td>
            </tr>
            <tr *ngFor="let service of services" >
              <td>
                <strong>{{'lm-journey-details.sections.lm-journey-work.services_products.extra' | translate}} </strong>{{service.title}}<br />
                <mat-card-subtitle>({{service.createDate | date: "dd/MM/yyyy hh:mm"}})</mat-card-subtitle>
              </td>
              <td>
                <div class="currency">{{getLocalNumber(service.price)}}</div>
              </td>
            </tr>
            <tr *ngFor="let product of data.extraProducts">
              <td>
                <strong>{{'lm-journey-details.sections.lm-journey-work.services_products.extra' | translate}} </strong>{{product.productDescription}}<br />
                <mat-card-subtitle>({{product.createDate | date: "dd/MM/yyyy hh:mm"}})</mat-card-subtitle>

              </td>
              <td>
                <div class="currency">{{getLocalNumber(product.price)}}</div>

              </td>
            </tr>
          </table>
        </div>



      </div>

    </div>

    <div class="basic-data-content">
      <div class="basic-data-content__card">

        <div *ngIf="data.questions || (data.mainService && data.mainService.description)">
          <div class="card-title">
            <h4>{{'lm-journey-details.sections.lm-journey-work.cells.questions' | translate}}</h4>
          </div>
          <div class="lm-professional-details__services">
            <div class="lm-professional-details__service">
              <div class="service-title">
                <div class="service-title-container">
                  <mat-accordion>
                    <ng-container *ngIf="data.questions">
                      <mat-expansion-panel *ngFor="let item of questions.questions" elevation="0">
                        <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
                          <mat-panel-title>
                            {{item.title}}
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul *ngFor="let answer of item.answer">
                          <li>{{answer}}</li>
                        </ul>
                      </mat-expansion-panel>
                    </ng-container>
                    <mat-expansion-panel elevation="0">
                      <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
                        <mat-panel-title>
                          {{'lm-journey-details.sections.lm-journey-work.cells.moreInformation' | translate}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ul>
                        <li *ngIf="!data.mainService || !data.mainService.description">No se ha añadido descripción para este trabajo.</li>
                        <li *ngIf="data.mainService && data.mainService.description">{{data.mainService?.description}}</li>

                      </ul>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data.images && data.images.length>0" class="basic-data-content">
      <div class="basic-data-content__card">
        <div class="card-title">
          <h4>{{'lm-journey-details.sections.lm-journey-work.cells.images' | translate}}</h4>
        </div>


        <div *ngIf="data.images" class="card-content">
          <div><strong>{{'lm-journey-details.sections.lm-journey-work.images.before' | translate}} </strong></div><br>

          <div class="inline-image" *ngFor="let image of imagesBefore">

            <div><img mat-card-sm-image src="{{image.url}}" alt="" (click)="openImagesDialog(image.url, image.type)"></div>

          </div>

          <div><strong>{{'lm-journey-details.sections.lm-journey-work.images.afterwards' | translate}} </strong></div><br>
          <div class="inline-image" *ngFor="let image of imagesAfter">

            <div><img mat-card-sm-image src="{{image.url}}" alt="" (click)="openImagesDialog(image.url, image.type)"></div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="chat-wrapper">

    <div class="basic-data-content__card">

      <div class="card-title">
        <h4>{{'lm-journey-details.sections.lm-journey-work.cells.chat' | translate}}</h4>
      </div>
      <div class="card-content">
        <app-lm-chat [data]="chatData">
        </app-lm-chat>

      </div>
    </div>
  </div>
</div>