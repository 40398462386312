<div class="content">
  <mat-card class="card" *ngIf="clientInformation">
    <mat-card-header>
      <mat-card-title>{{clientInformation.name}} {{clientInformation.lastName}}</mat-card-title>
      <mat-card-subtitle>{{clientInformation.email}}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="currentStatus">
        {{'lm-client.client-details.status' | translate}} 
        <label class="textStatus"> {{currentStatus}} </label>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-tab-group animationDuration="0ms" #tabGroup>
        <mat-tab label="{{'lm-client.client-details.tab_group.basic_data' | translate}}">
          <ng-template matTabContent>
            <app-lm-client-basic-data [clientInformation]="clientInformation"
            (editClientDataEvent)="openClientData($event)">
            </app-lm-client-basic-data>
          </ng-template>
  
        </mat-tab>

        <mat-tab  *ngIf="editClientData">
          <ng-template mat-tab-label>
            <label>{{'lm-client.client-details.tab_group.update_basic_data' | translate}}</label>
            <button mat-icon-button (click)="closeClientData(null)">
              <mat-icon>close</mat-icon>
            </button>
          </ng-template>
          <ng-template matTabContent>
            <app-lm-update-client-data [clientInformation]="clientInformation"
             [(editClientData)]="editClientData"
             (editClientDataEvent)="closeClientData($event)">
            </app-lm-update-client-data>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{'lm-client.client-details.tab_group.requets' | translate}}">
          <ng-template matTabContent>
            <app-lm-client-requests [filtersData]="filtersData">
            </app-lm-client-requests>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{'lm-client.client-details.tab_group.operations' | translate}}">
          <ng-template matTabContent>
            <app-lm-client-operations [filtersData]="filtersData">
            </app-lm-client-operations>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>