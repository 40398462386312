import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Utilities } from "../../../../app/shared/utilities/utilities";
import { MaintenanceService } from "../../../core/services/mant.service";
import { get as _get } from "lodash";
import { AuthenticationService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-lm-unban-professional',
  templateUrl: './lm-unban-professional.component.html',
  styleUrls: ['../../utils/dropdown.scss', './lm-unban-professional.component.scss']
})
export class LmUnbanProfessionalComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  form: FormGroup;
  message: string;
  isError: boolean = false;
  isDisabled: boolean = false;
  submitted: boolean = false;
  isUserLeroy: boolean;

  constructor(private _mant: MaintenanceService, 
    private _router: Router, 
    private ruta: ActivatedRoute,  
    private _translateService: TranslateService,
    private _auth: AuthenticationService) {
    this._translationSubscription = this._translateService
      .get('lm-unban-professional.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
   }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
    });

    this.isUserLeroy = Utilities.isUserHogami();
    this.getEmailProfessional();
    
    this._auth.isNewTokenExpired();
  }

  get f() { return this.form.controls; }

  async getEmailProfessional() {
    const email = await this.ruta.params['value'];
    this.form.controls['email'].setValue(email.email);
  }

  unbanProfesional(data) {
    this.submitted = true
    if (this.form.invalid) {
      return;
    }
    this.isDisabled = true;
    this.message = "";
    this._mant.unbanProfesionalV2(data.email, (data.status? data.status: null))
      .subscribe(
        () => {
         this._mant.unbanProfesional(data.email,Utilities.encodeBase64(data.password), (data.status? data.status: null))

            .subscribe(
              () => {
                this.message = this._messages['professioanl_successfully_debanned']
                this.isError = false;
                this.isDisabled = false;
              },
              (error) => {
                this.isDisabled = false;
                this.isError = true;
                if ((error.error.code === "SIDS-404-1") || (error.error.code === "SPRO-404-2")) {
                  this.message = this._messages['professional_not_found'];
                }
                else {
                  this.message = this._messages['error_while_performing_action'];
                }
              }
            );
          this.message = this._messages['professioanl_successfully_debanned']
          this.isError = false;
          this.isDisabled = false;
        },
        (error) => {
          this.isDisabled = false;
          this.isError = true;
          if (error.error.code === "SPRO-400-19") {
            this.message = this._messages['professional_already_unbanned'];
          } else if (error.error.code === "SPRO-404-2") {
            this.message = this._messages['professional_not_found'];
          } else {
            this.message = this._messages['error_while_performing_action'];
          }
        }
      );
  }

  goBack() {
    this._router.navigate(["transfers/professionals"]);
  }

}
