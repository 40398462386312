import { Subscription } from 'rxjs';
import { ProfessionalService } from './../../services/professional.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IFilters, IFiltersData, TimeLineHistory } from '../../models/lm-professional.model';

@Component({
  selector: 'app-lm-professional-status',
  templateUrl: './lm-professional-status.component.html',
  styleUrls: ['./lm-professional-status.component.scss', '../../../../pages/utils/mat-table.scss']
})

export class LmProfessionalStatusComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  filters: IFilters = {};
  filterType: string = "professionalStatusHistories";
  page: number = 0;
  totalElements: number = 0;
  loading: boolean = false;
  pageSize: number = 5;
  displayedColumns: string[] = ['initialStatus', 'finalStatus', 'startDate', 'endDate', 'createdBy', 'comments'];
  timeLineHistories: TimeLineHistory[];
  @Input() filtersData: IFiltersData;
  @Output() refreshProfData = new EventEmitter();

  constructor(
    private _translateService: TranslateService,
    private _professionalService: ProfessionalService
  ) {
    this._translationSubscription = this._translateService
      .get('lm-professional.lm-professional-status.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.retrieveProfessionalStatusHistories();
  }

  retrieveProfessionalStatusHistories() {
    this.loading = true;
    this.refreshProfData.emit();
    this.timeLineHistories = []
    this.totalElements = 0;
    this._professionalService.getProfessionalStatusHistories(this.filtersData['professionalId'], this.page, this.pageSize).subscribe(res => {
      this.timeLineHistories = res['data']['content'];
      this.totalElements = res['data']['totalElements'];
      this.loading = false;
      if(this.totalElements === 0) {
        alert(this._messages['no_result_found']);
        this.loading = false;
        this.timeLineHistories = [];
      }
    },
    err => {
      console.error(err);
      alert(this._messages['no_result_found']);
      this.loading = false;
      this.timeLineHistories = []
    });
  }

  getFiltersToStatus(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.retrieveProfessionalStatusHistories();
  }
}