import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'offeredServiceTypeMapper'
})
export class OfferedServiceTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value) {
      case 1:
        return "Configurador N2";
      case 2:
        return "Configurador";
      case 3:
        return "Manitas";
      case 4:
        return "Hibridos";
      default:
        return value;
    }
  }

}
