<div class="content">
<mat-card class="small-card"  *ngIf="isUserLeroy">
  <mat-card-header>
    <mat-card-title>{{'lm-transfer-payby.title' | translate}}</mat-card-title>
    <mat-card-subtitle>{{'lm-transfer-payby.subTitle' | translate}}</mat-card-subtitle>
  </mat-card-header>
  <form (ngSubmit)="transferPayBy()" [formGroup]="form" class="form" style="background: #fff;">
  <mat-card-content>
    <mat-form-field class="full-width">
      <mat-label>{{'lm-transfer-payby.placeholders.operations' | translate}}</mat-label>
      <input matInput formControlName="operacion" name="operacion" placeholder="{{'lm-transfer-payby.placeholders.operations' | translate}}" type="text"
       (input)="changeValue($event,operacion)" (blur)="onBlurOperation($event,operacion)" [value]="operacion.value"/>
    </mat-form-field>
    <div *ngIf="submitted && f.operacion.errors" class="redTextLeft">
      <div *ngIf="f.operacion.errors.required">{{'lm-transfer-payby.messages_error.operation_field_required' | translate}}</div>
    </div>

    <mat-form-field class="full-width">
      <mat-label>{{'lm-transfer-payby.placeholders.amount' | translate}}</mat-label>
      <input matInput formControlName="importe" name="importe" placeholder="{{'lm-transfer-payby.placeholders.amount' | translate}}" type="text"
        (input)="changeValue($event,importe)" (blur)="onBlur($event,importe)" [value]="importe.value" onPaste="return false"  maxlength="10"/>
    </mat-form-field>
    <div *ngIf="submitted && f.importe.errors" class="redTextLeft">
      <div *ngIf="f.importe.errors.required">{{'lm-transfer-payby.messages_error.amount_field_required' | translate}}</div>
      <div *ngIf="f.importe.errors.pattern">{{'lm-transfer-payby.messages_error.max_amount' | translate}}</div>
    </div>

    <mat-form-field class="full-width">
      <mat-label>{{'lm-transfer-payby.placeholders.paycomet_reference' | translate}}</mat-label>
      <input matInput formControlName="referenciaPaycomet" id="referenciaPaycomet" name="referenciaPaycomet" placeholder="{{'lm-transfer-payby.placeholders.paycomet_reference' | translate}}" type="text"
        (input)="changeValueReference($event,referenciaPaycomet)" [value]="referenciaPaycomet.value" maxlength="12"/>
    </mat-form-field>
    <div *ngIf="submitted && f.referenciaPaycomet.errors" class="redTextLeft">
      <div *ngIf="f.referenciaPaycomet.errors.required">{{'lm-transfer-payby.messages_error.paycomet_reference_required' | translate}}</div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div>
      <button mat-raised-button type="submit" color="primary"  [disabled]="isDisabled" class="button_submit"><mat-icon>payments</mat-icon> {{'lm-transfer-payby.button_submit' | translate}}</button>
    </div>
    <div *ngIf="isError" class="redText">{{ message }}</div>
  </mat-card-actions>
  </form>
</mat-card>


<mat-card class="small-card" *ngIf="!isUserLeroy">
  <div class="divPermissionDenied">
    <mat-card-header>
      <div></div>
      <mat-card-title>{{'lm-transfer-payby.title' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'lm-transfer-payby.subTitle' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="divLeroy" >
        <div class="divCenter">
          <h2>{{'lm-transfer-payby.message_error_no_authorized_perform_action' | translate}}</h2>
          </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="button" (click)="goBack()" class="redText" color="primary"> {{'lm-transfer-payby.button_accepter' | translate}} </button>
    </mat-card-actions>
  </div>
</mat-card>
</div>